import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Button,
  Container,
  Stack,
} from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PlaceIcon from "@mui/icons-material/Place";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import EvntImg from "../../../assets/Robo.jpg";
import { useParams } from "react-router-dom";
import { getToken } from "../../../utils/AuthUtils";
import axios from "axios";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();

  // Function to add ordinal suffix to a number
  const getOrdinal = (num) => {
    if (num > 3 && num < 21) return "th"; // Handles 11th to 20th
    switch (num % 10) {
      case 1: return "st";
      case 2: return "nd";
      case 3: return "rd";
      default: return "th";
    }
  };

  return `${day}${getOrdinal(day)} ${month} ${year}`;
};

const formatTime = (dateString) => {
  const date = new Date(dateString);
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const period = hours >= 12 ? "PM" : "AM";
  const adjustedHours = hours % 12 || 12;

  return `${adjustedHours}:${minutes} ${period}`;
};

const EventDetail = () => {
  const {id} = useParams();
  const [loading, setLoading] = useState(true);
  const [event, setEvent] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const token = getToken();
        if (token) {
          const eventResponse = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/event/eventByid/${id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          // Assuming teacherResponse.data.data contains the count of teachers
          console.log("Events-------", eventResponse.data.data); // Check the structure

          setEvent(eventResponse.data.data);
          
          setLoading(false); // Stop loading when data is fetched
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false); // Stop loading on error
      }
    };

    fetchEvents();
  }, [id]);

  
  return (
    <Container maxWidth="100%">
      {/* Header Section */}
      <Box
        p={1}
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "#e0e0e0cf",
          marginY: { xs: 2, sm: 2 },
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: "#333",
            textAlign: { xs: "center", sm: "left" },
          }}
        >
          Upcoming Events
        </Typography>
        {/* <Button
          variant="contained"
          sx={{
            backgroundColor: "#e0e0e0",
            "&:hover": { backgroundColor: "#d43b2c" },
            marginTop: { xs: 2, sm: 0 },
          }}
        >
          Register Now
        </Button> */}
      </Box>

      {/* Main Content Section */}
      <Grid container spacing={4}>
        {/* Event Details Card */}
        <Grid item xs={12}>
          <Card
            sx={{
              borderRadius: 2,
              overflow: "hidden",
              backgroundColor: "#ebebeb",
              boxShadow: "none",
            }}
          >
            <CardMedia
              component="img"
              height="300"
              image={event.event_image}
              alt="Event Banner"
            />
            <CardContent>
              {/* Event Description */}
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  color: "#333",
                  marginBottom: 2,
                }}
              >
                {event.event_title}
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: "#666", marginBottom: 3 }}
              >
                {event.event_description}
              </Typography>

              {/* Horizontal Event Details */}
              <Stack direction="row" spacing={4} alignItems="center">
                {/* Date */}
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <EventIcon sx={{ color: "#666", marginRight: 1 }} />
                  <Typography variant="body2" sx={{ color: "#666" }}>
                    {/* 25th November 2024 */}
                   {formatDate(event?.start_time)}
                  </Typography>
                </Box>

                {/* Time */}
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <AccessTimeIcon sx={{ color: "#666", marginRight: 1 }} />
                  <Typography variant="body2" sx={{ color: "#666" }}>
                    {/* 10:00 AM - 3:00 PM */}
                    {formatTime(event.start_time)} - {formatTime(event.end_time)}
                  </Typography>
                </Box>

                {/* Venue */}
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <PlaceIcon sx={{ color: "#666", marginRight: 1 }} />
                  <Typography variant="body2" sx={{ color: "#666" }}>
                    {event.location}
                  </Typography>
                </Box>

                {/* Fee */}
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <AttachMoneyIcon sx={{ color: "#666", marginRight: 1 }} />
                  <Typography variant="body2" sx={{ color: "#666" }}>
                    Free
                  </Typography>
                </Box>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        {/* <Grid item xs={12}>
          <Card
            sx={{
              borderRadius: 2,
              overflow: "hidden",
              backgroundColor: "#ebebeb",
              boxShadow: "none",
            }}
          >
            <CardMedia
              component="img"
              height="300"
              image={EvntImg}
              alt="Event Banner"
            />
            <CardContent>
            
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  color: "#333",
                  marginBottom: 2,
                }}
              >
                Event Description
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: "#666", marginBottom: 3 }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                vehicula, lorem id eleifend gravida, est lacus tincidunt eros,
                non scelerisque ligula turpis nec mi. Integer tincidunt
                efficitur urna, non lacinia orci pharetra vel.
              </Typography>

              <Stack direction="row" spacing={4} alignItems="center">

                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <EventIcon sx={{ color: "#666", marginRight: 1 }} />
                  <Typography variant="body2" sx={{ color: "#666" }}>
                    25th November 2024
                  </Typography>
                </Box>


                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <AccessTimeIcon sx={{ color: "#666", marginRight: 1 }} />
                  <Typography variant="body2" sx={{ color: "#666" }}>
                    10:00 AM - 3:00 PM
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <PlaceIcon sx={{ color: "#666", marginRight: 1 }} />
                  <Typography variant="body2" sx={{ color: "#666" }}>
                    City Conference Hall, Downtown
                  </Typography>
                </Box>

     
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <AttachMoneyIcon sx={{ color: "#666", marginRight: 1 }} />
                  <Typography variant="body2" sx={{ color: "#666" }}>
                    Free
                  </Typography>
                </Box>
              </Stack>
            </CardContent>
          </Card>
        </Grid> */}
      </Grid>
    </Container>
  );
};

export default EventDetail;
