import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
  Container,
  Box,
} from "@mui/material";
import axios from "axios";
import { getToken } from "../../../utils/AuthUtils";
 
const DepartmentList = () => {
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
 
  useEffect(() => {
    fetchDepartments();
  }, []);
 
  // Fetch Departments
  const fetchDepartments = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/department/getdepartments`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      console.log(response.data); // Log the data to check its structure
      if (Array.isArray(response.data.data)) {
        setDepartments(response.data.data);
      } else {
        console.error("Expected an array but received:", response.data);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching departments", error);
      setError(error);
      setLoading(false);
    }
  };
 
  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">Error loading data: {error.message}</Typography>;
 
  return (
    <Container maxWidth="md">
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4">
          Departments
        </Typography>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>No.</TableCell>
              <TableCell>Department Name</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Subcategory</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {departments.map((department, index) => (
              <TableRow key={department._id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{department.department_name}</TableCell>
                <TableCell>{department.department_category_id?.department_category_name}</TableCell>
                <TableCell>{department.department_sub_category_id?.department_sub_category_name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};
 
export default DepartmentList;