import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Snackbar,
  Alert,
  CircularProgress,
  Box,
  TextField,
  TablePagination,
  Button,
  IconButton,
  Switch,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
  InputAdornment,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MailIcon from "@mui/icons-material/Mail";
import PhoneIcon from "@mui/icons-material/Phone";
import SchoolIcon from "@mui/icons-material/School";
import Rating from "@mui/material/Rating";
import LockRoundedIcon from "@mui/icons-material/LockRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";

import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import axios from "axios";
import { getToken } from "../../../utils/AuthUtils";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import SearchIcon from "@mui/icons-material/Search";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const StudentPage = () => {
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [sortTerm, setSortTerm] = useState("grade-ascending"); // default sorting value
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [totalItems, setTotalItems] = useState(0); // Initialize totalItems as number
  const [selectedGrade, setSelectedGrade] = useState({
    grade: "",
    gradeDivision: "",
  });
  const [grades, setGrades] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [canCreate, setCanCreate] = useState(false);  
const [canView, setCanView] = useState(false);  
const [canUpdate, setCanUpdate] = useState(false);  
const [canDelete, setCanDelete] = useState(false);  


useEffect(() => {
  const fetchPermissions = async () => {
    const token = localStorage.getItem('token_key');
    
    if (token) {
      try {
        const roleResponse = await axios.get(
          `${process.env.REACT_APP_ADMIN_BACKEND}/api/rolespermission/permissions/15`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        
        const rolePermissions = roleResponse.data.data;
        
        // Check role permissions or set the role
        if (rolePermissions ) {
          // navigate("/unauthorized");
          // console.log("You are not authorized to create a school",rolePermissions);
          setCanCreate(rolePermissions.create);
          setCanView(rolePermissions.view);
          setCanUpdate(rolePermissions.update);
          setCanDelete(rolePermissions.delete);
        }
      } catch (error) {
        console.error("Error fetching role permissions:", error);
        // navigate("/unauthorized"); // Redirect in case of error
      }
    }
  };

  fetchPermissions();
}, []);

  useEffect(() => {
    fetchStudents();
  }, [page, rowsPerPage, searchTerm]);

  const fetchStudents = async () => {
    // setLoading(true);
    try {
      const token = getToken();
      if (!token) {
        setError("Please login to access students.");
        return;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/student?page=${
          page + 1
        }&limit=${rowsPerPage}&search=${searchTerm}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const data = response.data;
      if (data?.data) {
        setStudents(data.data);
        console.log(data.data);

        setTotalItems(data.pagination.totalItems); // Correctly set totalItems
      } else {
        setError("No students found");
      }
    } catch (err) {
      setError("Failed to fetch students");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchgradebySchool = async () => {
      try {
        const token = getToken();
        if (!token) {
          console.log("Access Denied");
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/student/grades/schoolData`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const gradeData = response.data.data;
        setGrades(gradeData);
        console.log("gradeData: ", response.data.data);
      } catch (error) {
        console.error("Error fetching grades:", error);
      }
    };
    fetchgradebySchool();
  }, []);

  useEffect(() => {
    const fetchstudentBygradedivision = async () => {
      // if (selectedGrade.grade === "") {
      //   // If "Select All" is chosen, fetch all students
      //   fetchStudents();
      //   return;
      // }
      // if (selectedGrade.grade === "all") {
      //   fetchStudents();
      //   return;
      // }
      if (selectedGrade.grade === "") {
        // If "Select All" is chosen, fetch all students
        fetchStudents();
        return;
      }
      if (!selectedGrade.gradeDivision) return;
      try {
        const token = getToken();
        if (!token) {
          console.log("Access Denied");
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/student/grades/${
            selectedGrade.gradeDivision
          }?page=${page + 1}&limit=${rowsPerPage}&search=${searchTerm}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const students = response.data.data;
        setStudents(students);
        console.log("students: ", response.data.data);
      } catch (error) {
        console.error("Error fetching students:", error);
      }
    };
    fetchstudentBygradedivision();
  }, [selectedGrade.gradeDivision]);

  const handleOpenDeleteDialog = (id) => {
    setDeleteId(id);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteId(null);
    setOpenDeleteDialog(false);
  };

  const deleteStudent = async () => {
    try {
      const token = getToken();
      if (!token) {
        setError("Unauthorized access.");
        return;
      }

      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND}/api/student/${deleteId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 200) {
        setSnackbarMessage("Student deleted successfully");
        setOpenSnackbar(true);
        fetchStudents();
        handleCloseDeleteDialog();
      }
    } catch (err) {
      setError("Failed to delete student");
    }
  };

  const handleStatusToggle = async (id, currentStatus) => {
    try {
      const token = getToken();
      if (!token) {
        setError("Unauthorized access.");
        return;
      }

      const newStatus = !currentStatus;

      await axios.put(
        `${process.env.REACT_APP_BACKEND}/api/student/${id}/status_student`,
        { status: newStatus },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setSnackbarMessage("Student status updated successfully");
      setOpenSnackbar(true);
      fetchStudents();
    } catch (err) {
      setError("Failed to update student status");
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
    setError(null);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset page to 0 when search term changes
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Container maxWidth="100%">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ backgroundColor: "#e0e0e0" }}
        p={1}
        mb={2}
      >
        <Typography variant="h4">Student list</Typography>
        <TextField
          // label="Search"
          variant="outlined"
          size="small"
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
          sx={{
            width: "300px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#00000030",
              },
              "&:hover fieldset": {
                borderColor: "#00000070",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#00000040",
              },
            },
            "& .MuiInputLabel-root": {
              display: "flex",
              alignItems: "center",
              transition: "all 0.3s ease",
            },
            "& .MuiInputLabel-shrink": {
              display: "flex",
              alignItems: "center",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" gap={2}>
            <Autocomplete
              size="small"
              options={grades}
              getOptionLabel={(option) =>
                option.grade_id?.gradename
                  ? `${option.grade_id.gradename} ${option.division}`
                  : ""
              }
              value={
                grades.find((item) => item._id === selectedGrade.grade) || null
              }
              onChange={(e, newValue) => {
                if (newValue) {
                  const selectedGradeItem = grades.find(
                    (item) => item._id === newValue._id
                  );
                  const gradeDivision = `${
                    selectedGradeItem.grade_id?.gradename || ""
                  } ${selectedGradeItem.division || ""}`;
                  setSelectedGrade({
                    grade: newValue._id,
                    gradeDivision,
                  });
                } else {
                  setSelectedGrade({
                    grade: "",
                    gradeDivision: "",
                  });
                }
              }}
              renderInput={(params) => (
                <TextField {...params} label="Select Grade" />
              )}
              sx={{ width: 350 }}
            />

            {/* ///////////////////////////////////////////////////////// */}

            <Box flexGrow={1} />
            {canCreate === 1 && (

            <Button
              component={Link}
              to="/add-student"
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
            >
              Add Student
            </Button>
            )}
          </Box>
        </Grid>
        {canView === 1 ? (

        <Grid container spacing={3} padding={3}>
          {students.length > 0 ? (
            students.map((student) => {
              // Split grade_division into grade and division
              const [_, grade, division] = student.grade_division
                ? student.grade_division.split(" ")
                : ["", "N/A", "N/A"];

              return (
                <Grid item xs={12} sm={12} md={12} lg={4} key={student._id}>
                  <Card
                    sx={{
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      borderRadius: "12px",
                      // p:'2'
                    }}
                  >
                    {/* Top Section: Name, ID, Grade, and Division */}
                    <Box
                      sx={{
                        backgroundColor: "#e0dfda",
                        padding: 2,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          {/* Student Name */}
                          <Typography
                            variant="h5"
                            sx={{ fontWeight: "bold", color: "#000" }}
                          >
                            {student.name}
                          </Typography>
                        </Box>

                        {/* ID, Grade, Division */}
                        <Box sx={{ display: "flex", gap: 2, mt: 1 }}>
                          <Typography
                            sx={{
                              backgroundColor: "#a0947c",
                              padding: "4px 8px",
                              borderRadius: "8px",
                              fontSize: "12px",
                              color: "#fff",
                            }}
                          >
                            {`ID-${student.student_id}`}
                          </Typography>
                          <Typography
                            sx={{
                              backgroundColor: "#a0947c",
                              padding: "4px 8px",
                              borderRadius: "8px",
                              fontSize: "12px",
                              color: "#fff",
                            }}
                          >
                            {`Grade-${grade}`}
                          </Typography>
                          <Typography
                            sx={{
                              backgroundColor: "#a0947c",
                              padding: "4px 8px",
                              borderRadius: "8px",
                              fontSize: "12px",
                              color: "#fff",
                            }}
                          >
                            {`Division-${division}`}
                          </Typography>
                        </Box>
                      </Box>

                      {/* Edit and Delete Icons */}
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          // alignItems: "center",
                          gap: 1,
                        }}
                      >
            {canUpdate === 1 && (

                        <IconButton
                          component={Link}
                          size="small"
                          to={`/edit-student/${student._id}`}
                          // onClick={onEdit}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
            )}
            {canDelete === 1 && (
                        <IconButton
                          size="small"
                          onClick={() => handleOpenDeleteDialog(student._id)}
                          // onClick={onDelete}
                          color="#e0e0e0"
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
            )}
                      </Box>
                    </Box>

                    {/* Bottom Section: Profile Picture, Details, and Rating */}
                    <Box sx={{ backgroundColor: "#fff", padding: 2 }}>
                      {/* Profile Picture & Details */}
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          mt: 2,
                        }}
                      >
                        {/* Profile Picture */}
                        <Box sx={{ marginRight: "2px" }}>
                          <img
                            src={student.profile_url ? student.profile_url : "/person.jpg"}
                            alt={`${student.name}'s profile`}
                            style={{
                              width: "100px",
                              height: "100px",
                              borderRadius: "50%",
                              objectFit: "cover",
                              border: "1px solid #333",
                              marginRight: "15px",
                            }}
                          />
                        </Box>

                        {/* Details and Rating aligned vertically */}
                        <Box
                          flexGrow={1}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                          }}
                        >
                          {/* Details */}
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 1,
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              <MailIcon
                                sx={{
                                  fontSize: "22px",
                                  mr: 1,
                                  color: "#fff",
                                  backgroundColor: "#a0947c",
                                  padding: "4px",
                                  borderRadius: "10px",
                                }}
                              />
                              <Typography variant="body2">
                                {student.email}
                              </Typography>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              <PhoneIcon
                                sx={{
                                  fontSize: "22px",
                                  mr: 1,
                                  color: "#fff",
                                  backgroundColor: "#a0947c",
                                  padding: "4px",
                                  borderRadius: "10px",
                                }}
                              />
                              <Typography variant="body2">
                                {student.mobile}
                              </Typography>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              <PersonRoundedIcon
                                sx={{
                                  fontSize: "22px",
                                  mr: 1,
                                  color: "#fff",
                                  backgroundColor: "#a0947c",
                                  padding: "3px",
                                  borderRadius: "10px",
                                }}
                              />
                              <Typography variant="body2">
                                {student?.loginData?.username || "No password"}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              <LockRoundedIcon
                                sx={{
                                  fontSize: "22px",
                                  mr: 1,
                                  color: "#fff",
                                  backgroundColor: "#a0947c",
                                  padding: "3px",
                                  borderRadius: "10px",
                                }}
                              />
                              <Typography variant="body2">
                                {student?.loginData?.real_password ||
                                  "No password"}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              <SchoolIcon
                                sx={{
                                  fontSize: "22px",
                                  mr: 1,
                                  color: "#fff",
                                  backgroundColor: "#a0947c",
                                  padding: "3px",
                                  borderRadius: "10px",
                                }}
                              />
                              <Typography variant="body2">
                                {student.totalCourses || "0"}
                              </Typography>
                            </Box>
                          </Box>

                          {/* Created date */}
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            <CalendarMonthIcon
                              sx={{
                                fontSize: "22px",
                                mr: 1,
                                color: "#fff",
                                backgroundColor: "#a0947c",
                                padding: "3px",
                                borderRadius: "10px",
                              }}
                            />
                            <Typography variant="body2">
                            {new Date(student?.createdAt).toLocaleDateString() || "No date"}
                              </Typography>
                          </Box>
                          {/* Rating */}
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            <ThumbUpIcon
                              sx={{
                                fontSize: "22px",
                                mr: 1,
                                color: "#fff",
                                backgroundColor: "#a0947c",
                                padding: "3px",
                                borderRadius: "10px",
                              }}
                            />
                            <Rating
                              name="student-rating"
                              value={student.rating || 3}
                              precision={0.5}
                              readOnly
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Card>
                </Grid>
              );
            })
          ) : (
            <Grid item xs={12} sm={12} md={12}>
              <Typography
                align="center"
                sx={{ fontSize: "28px", color: "#0000004a", marginTop: "23vh" }}
              >
                No students Available
              </Typography>
            </Grid>
          )}
        </Grid>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ height: "200px", textAlign: "center" }}
            >
              <Typography variant="h6" color="textSecondary">
                No permission to view.
              </Typography>
            </Box>
          )}

        {totalItems > 100 && (
          <Grid item xs={12} sx={{ marginTop: 2 }}>
            <TablePagination
              component="div"
              count={totalItems}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[6, 12, 24]}
              labelRowsPerPage="Items per page"
              sx={{ display: "flex", justifyContent: "center" }}
            />
          </Grid>
        )}
      </Grid>

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this student?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={deleteStudent}
            color="primary"
            sx={{ backgroundColor: "#e0e0e0" }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default StudentPage;
