import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Button,
  Snackbar,
  Alert,
  TextField,
  Box,
  TablePagination,
  Switch,
  Grid,
  Link,
  Container,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
  InputAdornment,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useLocation, useNavigate } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { width } from "@mui/system";
import { getToken } from "../../../utils/AuthUtils";
import SearchIcon from "@mui/icons-material/Search";

const TeachersListPage = () => {
  const [teachers, setTeachers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const [serialNumbers, setSerialNumbers] = useState([]);
  const [totalItems, setTotalItems] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [teachCategory, setTeachCategory] = useState([]);
  const [selectedTeachCategory, setSelectedTeachCategory] = useState("");
  const [teacherSubCategory, setTeacherSubCategory] = useState([]);
  const [selectedTeachSubCategory, setSelectedTeachSubCategory] = useState("");

  // New state variable for status toggle
  const [statusToggle, setStatusToggle] = useState(false);

  const [canCreate, setCanCreate] = useState(false);  
const [canView, setCanView] = useState(false);  
const [canUpdate, setCanUpdate] = useState(false);  
const [canDelete, setCanDelete] = useState(false);  


useEffect(() => {
  const fetchPermissions = async () => {
    const token = localStorage.getItem('token_key');
    
    if (token) {
      try {
        const roleResponse = await axios.get(
          `${process.env.REACT_APP_ADMIN_BACKEND}/api/rolespermission/permissions/14`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        
        const rolePermissions = roleResponse.data.data;
        
        // Check role permissions or set the role
        if (rolePermissions ) {
          // navigate("/unauthorized");
          // console.log("You are not authorized to create a school",rolePermissions);
          setCanCreate(rolePermissions.create);
          setCanView(rolePermissions.view);
          setCanUpdate(rolePermissions.update);
          setCanDelete(rolePermissions.delete);
        }
      } catch (error) {
        console.error("Error fetching role permissions:", error);
        // navigate("/unauthorized"); // Redirect in case of error
      }
    }
  };

  fetchPermissions();
}, []);

  useEffect(() => {
    const message = sessionStorage.getItem("AddTeacherSuccessMessage");
    if (message) {
      setSnackbarMessage(message);
      setOpenSnackbar(true);
      sessionStorage.removeItem("AddTeacherSuccessMessage"); // Remove the message after showing
    }
    fetchTeachers();
  }, [page, rowsPerPage, searchTerm]);

  const fetchTeachers = async () => {
    try {
      const token = getToken();
      if (!token) {
        console.log("Unauthorized user");
        return;
      }
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/teacher?page=${
          page + 1
        }&limit=${rowsPerPage}&search=${searchTerm}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (data?.data) {
        const teachersWithSerial = data.data.map((teacher, index) => ({
          ...teacher,
          serial: page * rowsPerPage + index + 1,
          statusToggle: !teacher.status, // Add this line
        }));
        setTeachers(teachersWithSerial);
        setSerialNumbers(teachersWithSerial.map((t) => t.serial));
        setTotalItems(data.pagination.totalItems); // Set totalItems for pagination
      } else {
        setError("No teachers found");
      }
    } catch (err) {
      setError("Failed to fetch teachers");
    } finally {
      setLoading(false);
    }
  };

  // department category select list
  useEffect(() => {
    const fetchTeachCategory = async () => {
      // if (!selectedDeptCategory) return;
      try {
        const token = getToken();
        if (!token) {
          console.log("unauthorized user");
          return;
        }
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND}/api/teachercategory`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        if (response.ok) {
          setTeachCategory(data.data || []);
          console.log("teachers related department: ", data.data);
          // setNewDepartment.department_id = "";
        }
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    fetchTeachCategory();
  }, []);

  // department sub category select list
  useEffect(() => {
    console.log("teacher category: ", selectedTeachCategory);
    fetchTeachSubCategory();
  }, [selectedTeachCategory]);

  const fetchTeachSubCategory = async () => {
    if (selectedTeachCategory === "") {
      // If "Select All" is chosen, fetch all students
      fetchTeachers();
      return;
    }
    if (!selectedTeachCategory) return;
    try {
      const token = getToken();
      if (!token) {
        console.log("unauthorized user");
        return;
      }
      // select box value
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/teacher/sub/teach/${selectedTeachCategory._id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        setTeacherSubCategory(data.data || []);
        console.log("teachers related department: ", data.data);
        // setNewDepartment.department_id = "";
      }

      const response1 = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/teacher/teachercat/${
          selectedTeachCategory._id
        }?page=${page + 1}&limit=${rowsPerPage}&search=${searchTerm}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data1 = await response1.json();
      console.log(data1.data);

      if (data1?.data) {
        const assignProductWithSerial = data1.data.map((product, index) => ({
          ...product,
          serial: page * rowsPerPage + index + 1,
          statusToggle: !product.status, // Add this line
        }));
        setTeachers(assignProductWithSerial);
        setSerialNumbers(assignProductWithSerial.map((t) => t.serial));
        setTotalItems(data1.pagination.totalItems); // Set totalItems for pagination
      } else {
        setError("No assign departments found");
      }
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };

  useEffect(() => {
    console.log("teacher sub category: ", selectedTeachSubCategory);

    const fetchTeachbydeptsubcat = async () => {
      if(selectedTeachCategory===""){
        fetchTeachSubCategory();
        return;
      }
      else if (!selectedTeachSubCategory){
        return;
      }

      const token = getToken();
      if (!token) {
        console.log("unauthorized user");
        return;
      }
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND}/api/teacher/teachersubcat/${selectedTeachCategory._id}/${
            selectedTeachSubCategory._id
          }?page=${page + 1}&limit=${rowsPerPage}&search=${searchTerm}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        console.log(data.data);

        if (data?.data) {
          const assignProductWithSerial = data.data.map((product, index) => ({
            ...product,
            serial: page * rowsPerPage + index + 1,
            statusToggle: !product.status, // Add this line
          }));
          setTeachers(assignProductWithSerial);
          setSerialNumbers(assignProductWithSerial.map((t) => t.serial));
          setTotalItems(data.pagination.totalItems); // Set totalItems for pagination
        } else {
          setError("No assign departments found");
        }
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    fetchTeachbydeptsubcat();
  }, [selectedTeachSubCategory]);

  const handleOpenDeleteDialog = (id) => {
    setDeleteId(id);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteId(null);
    setOpenDeleteDialog(false);
  };

  const deleteTeacher = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/teacher/${deleteId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (response.status === 200) {
        setSnackbarMessage("Teacher deleted successfully");
        setOpenSnackbar(true);
        fetchTeachers();
        handleCloseDeleteDialog();
      }
    } catch (err) {
      setError("Failed to delete teacher");
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset to first page on search
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page on rows per page change
  };

  const handleStatusToggle = (id, currentStatus) => {
    const newStatus = !currentStatus;
    updateTeacherStatus(id, newStatus); // Update parent status in the backend
  };
  const updateTeacherStatus = async (id, status) => {
    try {
      await fetch(
        `${process.env.REACT_APP_BACKEND}/api/teacher/${id}/status_teacher`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify({ status }),
        }
      );
      setSnackbarMessage("Teacher status updated successfully");
      setOpenSnackbar(true);
      fetchTeachers(); // Refresh the table after status change
    } catch (err) {
      setError("Failed to update Teacher status");
    }
  };

  const moveToAddTeacher = () => {
    navigate("/add-teacher");
  };

  return (
    <Container maxWidth="100%">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ backgroundColor: "#e0e0e0" }}
        p={1}>
        <Typography variant="h4">Teachers</Typography>
        <TextField
          // label="Search"
          variant="outlined"
          size="small"
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
          sx={{
            width: "300px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#00000030",
              },
              "&:hover fieldset": {
                borderColor: "#00000070",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#00000040",
              },
            },
            "& .MuiInputLabel-root": {
              display: "flex",
              alignItems: "center",
              transition: "all 0.3s ease",
            },
            "& .MuiInputLabel-shrink": {
              display: "flex",
              alignItems: "center",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      {/* <Button variant="contained" color="primary" onClick={() => navigate('/teacher/add-teacher')}>
        Add Teacher
      </Button> */}
      <Grid item xs={12}>
        <Box
          display="flex"
          alignItems="center"
          padding={2}
          gap={1}
          sx={{ paddingRight: "0", paddingLeft: "0" }}>
            <Grid item xs={12} md={3}>
          <Autocomplete
          size="small"
            options={teachCategory}
            getOptionLabel={(option) =>
              option.teacher_name ? option.teacher_name : ""
            }
            value={
              teachCategory.find(
                (item) => item._id === selectedTeachCategory?._id
              ) || null
            }
            onChange={(e, newValue) => {
              if (newValue) {
                const selected = teachCategory.find(
                  (item) => item._id === newValue._id
                );

                setSelectedTeachCategory(selected?selected:"");
              } 
              else{
                fetchTeachers();
              }
            }}
            renderInput={(params) => (
              <TextField {...params} label="Select Category" />
            )}
            sx={{ width: 350 }}
          />
          </Grid>
          {selectedTeachCategory && (
            <Grid item xs={12} md={3}>
          <Autocomplete
          size="small"
            options={teacherSubCategory}
            disabled={!selectedTeachCategory}
            getOptionLabel={(option) =>
              option.teacher_name ? option.teacher_name : ""
            }
            value={
              teacherSubCategory.find(
                (item) => item._id === selectedTeachSubCategory?._id
              ) || null
            }
            onChange={(e, newValue) => {
              if (newValue) {
                const selected = teacherSubCategory.find(
                  (item) => item._id === newValue._id
                );

                setSelectedTeachSubCategory(selected?selected:"");
              } 
            }}
            renderInput={(params) => (
              <TextField {...params} label="Select Subcategory" />
            )}
            sx={{ width: 350 }}
          /></Grid>
        )}
          <Box flexGrow={1} /> {/* This will push the button to the right */}
  {canCreate === 1 && (

          <Button
            component={Link}
            // to="/school/assigned-course"
            onClick={moveToAddTeacher}
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            sx={{ backgroundColor: "#e0e0e0" }}>
            Create
          </Button>
  )}
        </Box>
      </Grid>
      <TableContainer component={Paper}>
  {canView === 1 ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>No</TableCell>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Subcategory</TableCell>
              <TableCell>Mobile</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Hire Date</TableCell>
              <TableCell>Created Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teachers.length > 0 ? (
              teachers.map((teacher) => (
                <TableRow key={teacher._id}>
                  <TableCell>{teacher.serial}</TableCell>
                  <TableCell>{teacher.teacher_id}</TableCell>
                  <TableCell>{teacher.name}</TableCell>
                  <TableCell>
                    {teacher.teacher_category
                      ? teacher.teacher_category.teacher_name
                      : "N/A"}
                  </TableCell>
                  <TableCell>
                    {teacher.teacher_sub_category
                      ? teacher.teacher_sub_category.teacher_name
                      : "N/A"}
                  </TableCell>
                  <TableCell>{teacher.mobile}</TableCell>
                  <TableCell>{teacher.email}</TableCell>
                  {/* <TableCell>{teacher.P_email}</TableCell> */}
                  <TableCell>
                    {new Date(teacher.hire_Date).toLocaleDateString()}
                  </TableCell>
                  <TableCell>
                    {new Date(teacher.createdAt).toLocaleDateString()}
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={teacher.status}
                      onChange={() =>
                        handleStatusToggle(teacher._id, teacher.status)
                      }
                      color="primary"
                    />
                  </TableCell>
                  <TableCell>
  {canUpdate === 1 && (
                    <IconButton
                      color="primary"
                      onClick={() => navigate(`/edit-teacher/${teacher._id}`)}>
                      <EditIcon />
                    </IconButton>
  )}
  {canDelete === 1 && (

                    <IconButton
                      color="secondary"
                      onClick={() => handleOpenDeleteDialog(teacher._id)}>
                      <DeleteIcon />
                    </IconButton>
  )}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  No teachers available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ height: "200px", textAlign: "center" }}
            >
              <Typography variant="h6" color="textSecondary">
                No permission to view.
              </Typography>
            </Box>
          )}
      </TableContainer>
      {totalItems > 10 && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding={2}>
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 25, 50, 100]}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Box>
      )}

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this teacher?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={deleteTeacher}
            color="primary"
            sx={{ backgroundColor: "#e0e0e0" }}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}>
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}>
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default TeachersListPage;
