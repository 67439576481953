import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import {
  Grid,
  Box,
  Typography,
  LinearProgress,
  Button,
  IconButton,
  Container,
  MenuItem,
  Select,
} from "@mui/material";
import Tooltip from '@mui/material/Tooltip';

import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import VisibilityIcon from "@mui/icons-material/Visibility"; // Eye icon
import { getToken } from "../../../utils/AuthUtils";

const GradeView = () => {
  const location = useLocation();
  const {grade_id, grade_name, grade_division_id,teacheridd } = location.state || {};
  const navigate = useNavigate();
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [userdata, setData] = useState(null);
  const [schoolId, setSchoolId] = useState(null);
  const [unitsData, setUnitsData] = useState([]);
  const [courseName, setCourseName] = useState("");
  const token = localStorage.getItem("token_key");
  const [regId, setRegId] = useState("");
  const [refresh, setRefresh] = useState(false);

  // Define status options
  const statusOptions = [
    { value: 0, label: "Not Started" },
    { value: 2, label: "In Progress" },
  ];

  useEffect(() => {
    const token = localStorage.getItem("token_key");
    if (!token) {
      navigate("/login");
    } else {
      try {
        const decoded = jwtDecode(token);
        setUser(decoded);
        const reg_id = decoded.reg_id;
        setRegId(reg_id);
        const schooll_id = decoded.teacher.school_id;
        setSchoolId(schooll_id);
     //   fetchteacherdata(reg_id);
      } catch (error) {
        console.error("Token decoding failed", error);
        navigate("/login");
      }
    }
  }, [navigate]);

  useEffect(() => {
  const fetchteacherdata = async (regId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/teacher/${regId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setData(response.data.data);


    } catch (error) {
      console.error("Error fetching teacher data", error);
    }
  };
  fetchteacherdata(regId);
}, [regId]);

  useEffect(() => {
    const fetchUnitsData = async () => {
      try {

    

        const unitsResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/units/${id}`,{
            headers:{
              Authorization: `Bearer ${getToken()}`
            }
          }
        );
        const units = unitsResponse.data;

        const combinedUnits = await Promise.all(
          units.map(async (unit) => {
            try {
         
              
              const unitHandlingResponse = await axios.get(
                `${process.env.REACT_APP_BACKEND}/api/units/handling/${unit._id}/${grade_id}/${grade_division_id}/${teacheridd}`,
                {
                  headers:{
                    Authorization: `Bearer ${getToken()}`
                  }
                }
              );
              const unitHandling = unitHandlingResponse.data;
              

              return {
                chapterName: unit.chapterName,
                unitid: unit._id,
                unit: unit.unit_name,
                progress: unitHandling.completed,
                isActive: true,
                isLocked: false,
                status: unitHandling.status || 0, // Default to 0 if no status
              };
            } catch (error) {
              return {
                chapterName: unit.chapterName,
                unitid: unit._id,
                unit: unit.unit_name,
                progress: 0,
                isActive: true,
                isLocked: false,
                status: 0,
              };
            }
          })
        );

        const groupedUnits = combinedUnits.reduce((acc, unit) => {
          if (!acc[unit.chapterName]) {
            acc[unit.chapterName] = {
              units: [],
              totalProgress: 0,
              unitCount: 0,
              averageProgress: 0,
            };
          }
          acc[unit.chapterName].units.push(unit);
          acc[unit.chapterName].totalProgress += unit.progress;
          acc[unit.chapterName].unitCount += 1;
          acc[unit.chapterName].averageProgress =
            acc[unit.chapterName].totalProgress /
            acc[unit.chapterName].unitCount;
          return acc;
        }, {});

        setUnitsData(groupedUnits);

        const courseResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/units/coursename/${id}`,{
            headers:{
              Authorization: `Bearer ${getToken()}`
            }
          }
        );
        setCourseName(courseResponse.data.courses_name);
      } catch (error) {
        console.error("Error fetching units data:", error);
      }
    };

    fetchUnitsData();
  }, [id]);
  
  // const handleRedirect = (unitId) => {   
  //   navigate(`/teacher/unit-interface/${unitId}`
  //   )
  // };



  const handleRedirect = (unitId,grade_namee,coursenamee,chapterindexx,chaptername,unitnamee) => {
    navigate(`/teacher/unit-interface/${unitId}`, {
      state: {unitId,grade_namee,coursenamee,chapterindexx,chaptername,unitnamee },
    });

  };
    
  const handleRedirecttoassessment = (unitId,grade_id,grade_namee,grade_division_id,coursenamee,chapterindexx,chaptername,unitnamee) => {
    navigate(`/teacher/assessment/${unitId}`, {
      state: {unitId,grade_id,grade_namee,grade_division_id,coursenamee,chapterindexx,chaptername,unitnamee },
    });

  };
const handleStatusChange = async (unitId, newStatus) => {
  try {
    let updateData = {};
    
    if (newStatus === 2) {
      // Ongoing
      updateData = {
        unit_id: unitId,
        teacher_id:user.reg_id,
         school_id:schoolId,
         grade_id: grade_id,
        grade_division_id: grade_division_id,
        status: 2,
        completed: 100,
      };
    } 
    else if (newStatus === 1) {
      // Completed
      updateData = {
        unit_id: unitId,
        teacher_id:user.reg_id,
        school_id:schoolId,
        grade_id: grade_id,
       grade_division_id: grade_division_id,
        status: 1,
        completed: 100,
      };
    }

    // Check if the unit exists first in UnitsHandling collection
    // const checkExistenceResponse = await axios.get(
    //   `${process.env.REACT_APP_BACKEND}/api/units/unitshandling/check/${unitId}`
    // );

    // const checkExistenceResponse = await axios.get(
    //   `${process.env.REACT_APP_BACKEND}/api/units/unitshandling/check/${unitId}/${grade_id}/${grade_division_id}`
    // );

 
    

    // if (checkExistenceResponse.data.exists) {
    //   // If exists, delete the old record
    //   console('ccccccccccccccccccccccccccccccccccccccccc',checkExistenceResponse.data);


    //   const deleteExistenceResponse = await axios.delete(
    //     `${process.env.REACT_APP_BACKEND}/api/units/unitshandling/delete/${unitId}`      );

    //   if (deleteExistenceResponse.status === 200) {
    //     console.log('Existing unit record deleted');
    //   } else {
    //     console.error('Failed to delete existing unit record');
    //   }
    // }

    // Save the new unit status
    const saveResponse = await axios.put(
      `${process.env.REACT_APP_BACKEND}/api/units/unitshandling/update`,
      updateData,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (saveResponse.status === 200) {
      console.log('Unit status updated successfully');

      // Update local state
      setUnitsData((prevUnits) => {
        const updatedUnits = { ...prevUnits };
        const chapterName = Object.keys(updatedUnits).find((chapter) =>
          updatedUnits[chapter].units.some((unit) => unit.unitid === unitId)
        );

        if (chapterName) {
          const unitIndex = updatedUnits[chapterName].units.findIndex(
            (unit) => unit.unitid === unitId
          );
          if (unitIndex !== -1) {
            updatedUnits[chapterName].units[unitIndex].status = newStatus;
          }
        }

        return updatedUnits;
      });
      setRefresh((prev) => !prev);
    } else {
      console.error('Failed to update unit status');
      // You can add a toast or notification here to inform the user.
    }
  } catch (error) {
    console.error('Error updating unit status:', error);
    // You can add a toast or notification here to inform the user.
  }
};

  // const calculateCourseProgress = () => {
  //   let totalProgress = 0;
  //   let totalUnits = 0;

  //   Object.values(unitsData).forEach((chapter) => {
  //     chapter.units.forEach((unit) => {
  //       totalProgress += unit.progress;
  //       totalUnits += 1;
  //     });
  //   });

  //   return totalUnits > 0 ? Math.round(totalProgress / totalUnits) : 0;
  // };

  // if (Object.keys(unitsData).length === 0) {
  //   return <div>No units available for this course.</div>;
  // }
  const getUnitBgColor = (progress, isLocked, status) => {
    if (status === 2) return "#ff8214"; // Ongoing unit
    if (isLocked) return "#e0e0e0";
    if (progress === 100) return "#f9f7f7";
    return "#e0e0e0";
  };

  const getTextColor = (isLocked) => {
    return isLocked ? "#757575" : "#000";
  };

  const calculateCourseProgress = () => {

    let totalProgress = 0;
    let totalUnits = 0;

    Object.values(unitsData).forEach((chapter) => {
      chapter.units.forEach((unit) => {
        totalProgress += unit.progress;
        totalUnits += 1;
      });
    });

    return totalUnits > 0 ? Math.round(totalProgress / totalUnits) : 0;
        
  };

  if (Object.keys(unitsData).length === 0) {
    return <div>No units available for this course.</div>;
  }

  
  return (
    <Container maxWidth="xl" sx={{ padding: "20px" }}>
      <Grid
        container
        spacing={1}
        alignItems="center"
        sx={{ marginBottom: "5px" }}
      >
        <Grid item xs={2}>
          <Button
            variant="contained"
            color="error"
            sx={{
              height: "62px",
              fontSize: "17px",
              color: "#fff !important ",
              marginBottom: "20px",
              width: "95%",
            }}
          >
            {grade_name && grade_division_id ? (
              <>
                {grade_name} {grade_division_id} 
              </>
            ) : (
              "Loading..."
            )}
          </Button>
        </Grid>
        <Grid item xs={10}>
          <Typography
            variant="h6"
            sx={{
              backgroundColor: "#e4443f",
              color: "#fff !important",
              padding: "10px",
              fontSize: "27px",
              borderRadius: "4px",
            }}
          >
            {courseName}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "left", marginTop: "5px" }}>
            <LinearProgress
              variant="determinate"
              value={calculateCourseProgress()}
              sx={{ height: "8px", backgroundColor: "#f0f0f0", flexGrow: 1 }}
            />
            <Typography sx={{ marginLeft: "10px", fontSize: "12px" }}>
              {calculateCourseProgress()}%
            </Typography>
          </Box>
        </Grid>
      </Grid>

      {Object.entries(unitsData).map(
        ([chapterName, chapterData], chapterIndex) => (
          <React.Fragment key={`chapter-${chapterIndex}`}>
            <Grid
              container
              spacing={2}
              alignItems="center"
              sx={{ marginBottom: "5px", marginTop: "10px" }}
            >
              <Grid item xs={2}>
                <Typography
                  sx={{
                    backgroundColor: "#ffeb99",
                    padding: "10px",
                    fontSize: "18px",
                    marginBottom: "22px",
                    borderRadius: "4px",
                  }}
                >
                  Chapter {chapterIndex + 1}
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Typography
                  sx={{
                    backgroundColor: "#ffeb99",
                    padding: "10px",
                    fontSize: "20px",
                    borderRadius: "4px",
                  }}
                >
                  {chapterName}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "5px",
                  }}
                >
                  <LinearProgress
                    variant="determinate"
                    value={chapterData.averageProgress}
                    sx={{
                      height: "5px",
                      backgroundColor: "#f0f0f0",
                      flexGrow: 1,
                    }}
                  />
                  <Typography sx={{ marginLeft: "10px", fontSize: "12px" }}>
                    {chapterData.averageProgress.toFixed(0)}%
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            {chapterData.units.map((unit, index) => (
              <Grid
                container
                spacing={2}
                alignItems="center"
                key={`unit-${index}`}
                sx={{ marginBottom: "5px" }}
              >
                {/* Unit Label */}
                <Grid item xs={2}>
                  <Typography
                    sx={{
                      backgroundColor: "#ffeb99",
                      padding: "10px",
                      fontSize: "18px",
                      borderRadius: "4px",
                    }}
                  >
                    Unit {index + 1}
                  </Typography>
                </Grid>

                {/* Unit Name */}
                <Grid item xs={7}>
                  <Typography  sx={{
                  backgroundColor: getUnitBgColor(unit.progress, unit.isLocked, unit.status),
                  color: getTextColor(unit.isLocked),
                  padding: "10px",
                  borderRadius: "4px",
                  fontSize: "16px",
                }}
                >{unit.unit}</Typography>
                 
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "5px",
                    }}
                  >
                    {/* <LinearProgress
                      variant="determinate"
                      value={unit.progress}
                      sx={{ height: "5px", backgroundColor: "#f0f0f0", flexGrow: 1 }}
                    />
                    <Typography sx={{ marginLeft: "10px", fontSize: "12px" }}>
                      {unit.progress}%
                    </Typography> */}
                  </Box>
                </Grid>
                  <Grid item xs={0.5}>
                    <IconButton
                      onClick={() => handleRedirect(unit.unitid,grade_name,courseName,chapterIndex + 1,chapterName,unit.unit)}
                     
                      sx={{
                        backgroundColor: unit.isLocked ? "#e0e0e0" : "#f44336",
                        cursor: unit.isLocked ? "not-allowed" : "pointer",
                        borderRadius: "4px",
                        color: "#fff !important",
                      }}
                      disabled={unit.isLocked}
                    >
                      {!unit.isLocked ? <PlayArrowIcon /> : null}
                    </IconButton>
                  </Grid>
                {/* Select Status */}
                <Grid item xs={2}>
                    <Select
                        value={unit.status}
                        onChange={(e) => handleStatusChange(unit.unitid, e.target.value)}
                        variant="outlined"
                        sx={{ height: "40px",width:"100%" }}
>
                        {statusOptions.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                   {option.label}
                              </MenuItem>
                        ))}

                          {/* Conditional MenuItem if unit.status is not 0 */}
                         {unit.status !== 0 && (
                        <MenuItem key={2} value={1}>
                                Completed
                        </MenuItem>
                        )}
                    </Select>

                </Grid>
                  <Grid item xs={0.5}>
                  <Tooltip title="Assessment View" arrow>
                    <IconButton
                      onClick={() => handleRedirecttoassessment(unit.unitid,grade_id,grade_name,grade_division_id,courseName,chapterIndex + 1,chapterName,unit.unit)}
                     
                      sx={{
                        backgroundColor: "#33c7ff",                        
                        borderRadius: "4px",
                        color: "#fff !important",
                      }}                     
                    >
                       <VisibilityIcon />
                    </IconButton>
                    </Tooltip>
                  </Grid>
                
              </Grid>
            ))}
          </React.Fragment>
        )
      )}
    </Container>
  );
};

export default GradeView;
