import React, { useState, useEffect } from "react";
import { Button, Box, Grid, Typography, LinearProgress } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

function Grade() {
  const [view, setView] = useState("ongoing");
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const navigate = useNavigate();
  const [teacheridd, setTeacherid] = useState("");

  const [canCreate, setCanCreate] = useState(false);  
const [canView, setCanView] = useState(false);  
const [canUpdate, setCanUpdate] = useState(false);  
const [canDelete, setCanDelete] = useState(false);  


useEffect(() => {
  const fetchPermissions = async () => {
    const token = localStorage.getItem('token_key');
    
    if (token) {
      try {
        const roleResponse = await axios.get(
          `${process.env.REACT_APP_ADMIN_BACKEND}/api/rolespermission/permissions/19`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        
        const rolePermissions = roleResponse.data.data;
        
        // Check role permissions or set the role
        if (rolePermissions ) {
          // navigate("/unauthorized");
          // console.log("You are not authorized to create a school",rolePermissions);
          setCanCreate(rolePermissions.create);
          setCanView(rolePermissions.view);
          setCanUpdate(rolePermissions.update);
          setCanDelete(rolePermissions.delete);
        }
      } catch (error) {
        console.error("Error fetching role permissions:", error);
        // navigate("/unauthorized"); // Redirect in case of error
      }
    }
  };

  fetchPermissions();
}, []);

  useEffect(() => {
    const fetchCourses = async () => {
      const token = localStorage.getItem("token_key");

      if (!token) {
        console.error("Token not found");
        return;
      }

      try {
        const decoded = jwtDecode(token);
        const id = decoded.reg_id;

        setTeacherid(id);

        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/assign_course/get_course/${id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        console.log("Fetched courses:", response.data.assignedCourses); // Log the fetched courses
        setCourses(response.data.assignedCourses);
        setLoading(false); // Set loading to false once data is fetched
      } catch (error) {
        console.error("Failed to fetch courses", error);
        setLoading(false); // Set loading to false even on error
      }
    };

    fetchCourses();
  }, []);

  if (loading) {
    return <Typography>Loading courses...</Typography>; // Show loading message
  }

  // const groupedCourses = courses.reduce((acc, course) => {
  //   const { grade_id, course_id } = course;

  //   // Safeguard for missing fields
  //   const gradeId = grade_id._id || "Unknown Grade";
  //   const courseTitle = course_id.courses_name || "Unknown Course";

  //   // Initialize the grade entry if not present
  //   if (!acc[gradeId]) {
  //     acc[gradeId] = {
  //       grade_name: grade_id.gradename || "Unknown Grade", // Safeguard for missing gradename
  //       courses: [],
  //       uniqueCourseIds: new Set(), // Set to track unique course ids
  //     };
  //   }

  //   // Add the course only if it's not a duplicate
  //   if (!acc[gradeId].uniqueCourseIds.has(course_id._id)) {
  //     acc[gradeId].courses.push({
  //       courseidd: course_id._id,
  //       title: courseTitle,
  //       progress: course.progress || 0, // Default progress to 0 if not provided
  //     });
  //     acc[gradeId].uniqueCourseIds.add(course_id._id); // Track the course ID
  //   }

  //   return acc;
  // }, {});

  // Optionally, remove the Set from the final result
  // Object.keys(groupedCourses).forEach((gradeId) => {
  //   delete groupedCourses[gradeId].uniqueCourseIds;
  // });

  const groupedCourses = courses.reduce((acc, course) => {
    const {
      grade_id,
      gradename,
      course_id,
      courses_name,
      progress,
      grade_division_id,
    } = course; // Updated to match object structure

    if (!acc[grade_id._id]) {
      acc[grade_id._id] = {
        grade_name: grade_id.gradename || "Unknown Grade", // Safeguard for missing gradename
        courses: [],
      };
    }

    acc[grade_id._id].courses.push({
      courseidd: course_id._id,
      grade_division_id: grade_division_id,
      title: course_id.courses_name || "Unknown Course", // Safeguard for missing courses_name
      progress: progress || 0, // Default progress to 0 if not provided
    });

    return acc;
  }, {});

  console.log("groupedCourses", groupedCourses);

  // // Handle redirection when arrow icon is clicked
  // const handleRedirect = (courseId) => {
  //   // navigate(`/gradeview/${courseId}`); // Redirect to specific course view
  //   navigate(`/teacher/ongoing_courses_chapter/${courseId}`); // Use navigate instead of history.push
  // };

  // Handle redirection with additional data
  const handleRedirect = (unitId, grade_id, grade_name, grade_division_id) => {
    navigate(`/teacher/ongoing_courses_chapter/${unitId}`, {
      state: { grade_id, grade_name, grade_division_id, teacheridd },
    });
  };

  return (
    <Box p={2} sx={{ width: "100%" }}>
      <Box
        p={1}
        mb={2}
        sx={{
          backgroundColor: "#e0e0e0cf",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4">Courses</Typography>
        {/* <TextField
          // label="Search"
          variant="outlined"
          size="small"
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
          sx={{
            width: "300px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#00000030",
              },
              "&:hover fieldset": {
                borderColor: "#00000070",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#00000040",
              },
            },
            "& .MuiInputLabel-root": {
              display: "flex",
              alignItems: "center",
              transition: "all 0.3s ease",
            },
            "& .MuiInputLabel-shrink": {
              display: "flex",
              alignItems: "center",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        /> */}
      </Box>
      {/* Buttons */}
      {/* <Box display="flex" justifyContent="start" mb={3}>
        <Button
          variant="contained"
          onClick={() => setView("ongoing")}
          startIcon={<ArrowForwardIcon />}
          style={{
            marginRight: "10px",
            backgroundColor: view === "ongoing" ? "black" : "orange",
            color: view === "ongoing" ? "white" : "black",
            borderRadius: "5px",
            fontWeight: "bold",
            fontSize: "15px",
          }}
        >
          Ongoing Courses
        </Button>
        <Button
          variant="contained"
          onClick={() => setView("completed")}
          startIcon={<ArrowForwardIcon />}
          style={{
            backgroundColor: view === "completed" ? "black" : "orange",
            color: view === "completed" ? "white" : "black",
            borderRadius: "5px",
            fontWeight: "bold",
            fontSize: "15px",
          }}
        >
          Completed Courses
        </Button>
      </Box> */}

      {/* Grouped Courses List */}
      {canView === 1 ? (
        Object.entries(groupedCourses).map(([gradeId, { grade_name, courses }]) => (
          <Box key={gradeId} mb={3}>
            <Typography variant="h6" sx={{ fontWeight: "bold", color: "black" }}>
              {grade_name}
            </Typography>
            <Grid container spacing={0}>
              {courses.map((course, index) => (
                <Grid item xs={12} key={index}>
                  <Box display="flex" alignItems="center" mb={1}>
                    <Box
                      bgcolor="black"
                      color="white"
                      p={1}
                      width={40}
                      textAlign="center"
                      mr={2}
                      style={{
                        borderRadius: "5px",
                        fontWeight: "bold",
                        fontSize: "20px",
                      }}>
                      {course.grade_division_id}
                    </Box>
                    <Box
                      bgcolor="#e4443f"
                      color="white"
                      p={1}
                      textAlign="center"
                      flexGrow={1}
                      mr={2}
                      style={{
                        borderRadius: "5px",
                        fontWeight: "bold",
                        fontSize: "19px",
                      }}>
                      {course.title}
                    </Box>
                    {/* Clickable Icon for Redirection */}
                    <Box ml={2}>
                      <Box
                        bgcolor="#e4443f"
                        p={1}
                        borderRadius="0%"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        // onClick={() => handleRedirect(course.courseidd)}
                        // style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleRedirect(
                            course.courseidd,
                            gradeId,
                            grade_name,
                            course.grade_division_id
                          )
                        } // Pass unitid, grade_name, and grade_division_id
                        sx={{ cursor: "pointer" }}>
                        <ArrowForwardIcon sx={{ color: "#fff" }} />
                      </Box>
                    </Box>
                  </Box>
                  {/* Progress Bar */}
                  <LinearProgress
                    variant="determinate"
                    value={course.progress}
                    style={{ height: "5px", borderRadius: "0px" }}
                  />
                  <Typography
                    mt={1}
                    textAlign="right"
                    style={{ fontWeight: "bold", fontSize: "15px" }}>
                    {course.progress}%
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Box>
        ))
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ height: "200px", textAlign: "center" }}
        >
          <Typography variant="h6" color="textSecondary">
            No permission to view.
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default Grade;
