import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  TextField,
  CircularProgress,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { getToken } from '../../../utils/AuthUtils';
 
const CourseEditPage = () => {
  const [course, setCourse] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
 
  const { id } = useParams();
  const navigate = useNavigate();
 
  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/courses/${id}`,
          {
            headers: {
              Authorization: `Bearer ${getToken()}`
            }
          }
        );
        setCourse(response.data.data);
      } catch (err) {
        setError('Error fetching course data');
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };
 
    const fetchDepartments = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/department`,
          {
            headers:{
              Authorization: `Bearer ${getToken()}`
            }
          }
        );
        setDepartments(response.data.data);
      } catch (err) {
        setError('Error fetching departments');
        console.error(err);
      }
    };
 
    fetchCourse();
    fetchDepartments();
  }, [id]);
 
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCourse(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND}/api/courses/${id}`, course,
        {
          headers:
          {
            Authorization: `Bearer ${getToken()}`
          }
        }
      );
      navigate('/courses_list');
    } catch (err) {
      setError('Error updating course');
      console.error(err);
    } finally {
      setIsSubmitting(false);
    }
  };
 
  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" padding={2}>
        <CircularProgress />
      </Box>
    );
  }
 
  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" padding={2}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }
 
  return (
    <Box padding={3}>
      <Typography variant="h4">
        Edit Course
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Course Name"
          name="courses_name"
          variant="outlined"
          fullWidth
          margin="normal"
          value={course?.courses_name || ''}
          onChange={handleChange}
        />
        <TextField
          label="Details"
          name="course_details"
          variant="outlined"
          fullWidth
          margin="normal"
          value={course?.course_details || ''}
          onChange={handleChange}
        />
        <TextField
          label="Thumbnail URL"
          name="course_thumbnail"
          variant="outlined"
          fullWidth
          margin="normal"
          value={course?.course_thumbnail || ''}
          onChange={handleChange}
        />
        <TextField
          label="Image URL"
          name="course_image"
          variant="outlined"
          fullWidth
          margin="normal"
          value={course?.course_image || ''}
          onChange={handleChange}
        />
        <TextField
          label="Number of Chapters"
          name="no_of_chapters"
          variant="outlined"
          fullWidth
          margin="normal"
          type="number"
          value={course?.no_of_chapters || ''}
          onChange={handleChange}
        />
        <TextField
          select
          label="Department"
          name="department_id"
          variant="outlined"
          fullWidth
          margin="normal"
          value={course?.department_id || ''}
          onChange={handleChange}
          SelectProps={{
            native: true,
          }}
        >
          <option value="">Select a Department</option>
          {departments.map((dept) => (
            <option key={dept._id} value={dept._id}>
              {dept.department_name}
            </option>
          ))}
        </TextField>
        <TextField
          select
          label="Status"
          name="status"
          variant="outlined"
          fullWidth
          margin="normal"
          value={course?.status || ''}
          onChange={handleChange}
          SelectProps={{
            native: true,
          }}
        >
          <option value={1}>Active</option>
          <option value={0}>Inactive</option>
        </TextField>
        <Box display="flex" justifyContent="flex-end" marginTop={2}>
          <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
            {isSubmitting ? 'Saving...' : 'Save Changes'}
          </Button>
        </Box>
      </form>
    </Box>
  );
};
 
export default CourseEditPage;