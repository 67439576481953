// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useParams, useNavigate } from 'react-router-dom';
// import {
//   TextField, Button, Typography, Box
// } from '@mui/material';

// const UpdateAssessmentPage = () => {
//   const { id } = useParams(); // Get the assessment ID from the URL
//   const navigate = useNavigate(); // For navigation

//   const [assessment, setAssessment] = useState(null);
//   const [formData, setFormData] = useState({
//     assessment_name: "",
//     course_id: "",
//     chapter_id: "",
//     class_id: "",
//     levels: "",
//     due_date: "",
//   });
//   const [error, setError] = useState("");
//   const [snackbarMessage, setSnackbarMessage] = useState("");
//   const [openSnackbar, setOpenSnackbar] = useState(false);

//   useEffect(() => {
//     if (id) {
//       const fetchAssessment = async () => {
//         try {
//           const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/assessment/${id}`);
//           setAssessment(response.data.data);
//           setFormData({
//             assessment_name: response.data.data.assessment_name || "",
//             course_id: response.data.data.course_id || "",
//             chapter_id: response.data.data.chapter_id || "",
//             class_id: response.data.data.class_id || "",
//             levels: response.data.data.levels || "",
//             due_date: response.data.data.due_date || "",
//           });
//         } catch (error) {
//           setError("Failed to fetch assessment");
//         }
//       };

//       fetchAssessment();
//     }
//   }, [id]);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleUpdateAssessment = async () => {
//     try {
//       await axios.put(
//         `${process.env.REACT_APP_BACKEND}/api/assessment/${id}`,
//         formData
//       );
//       setSnackbarMessage("Assessment updated successfully");
//       setOpenSnackbar(true);
//       navigate('/assessments'); // Navigate back to the assessments list or another page
//     } catch (error) {
//       setError("Failed to update assessment");
//     }
//   };

//   return (
//     <Box sx={{ padding: 3 }}>
//       <Typography variant="h4" gutterBottom>
//         Update Assessment
//       </Typography>
//       {error && <Typography color="error">{error}</Typography>}
//       {snackbarMessage && <Typography>{snackbarMessage}</Typography>}
//       {assessment ? (
//         <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }} onSubmit={(e) => e.preventDefault()}>
//           <TextField
//             label="Assessment Name"
//             name="assessment_name"
//             value={formData.assessment_name}
//             onChange={handleChange}
//             fullWidth
//             required
//           />
//           <TextField
//             label="Course ID"
//             name="course_id"
//             value={formData.course_id}
//             onChange={handleChange}
//             fullWidth
//             required
//           />
//           <TextField
//             label="Chapter ID"
//             name="chapter_id"
//             value={formData.chapter_id}
//             onChange={handleChange}
//             fullWidth
//             required
//           />
//           <TextField
//             label="Class ID"
//             name="class_id"
//             value={formData.class_id}
//             onChange={handleChange}
//             fullWidth
//             required
//           />
//           <TextField
//             label="Levels"
//             name="levels"
//             value={formData.levels}
//             onChange={handleChange}
//             fullWidth
//             required
//           />
//           <TextField
//             label="Due Date"
//             name="due_date"
//             type="date"
//             value={formData.due_date}
//             onChange={handleChange}
//             fullWidth
//             required
//             InputLabelProps={{ shrink: true }}
//           />
//           <Button variant="contained" color="primary" onClick={handleUpdateAssessment}>
//             Update Assessment
//           </Button>
//         </Box>
//       ) : (
//         <Typography>Loading...</Typography>
//       )}
//     </Box>
//   );
// };

// export default UpdateAssessmentPage;


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import {
  TextField, Button, Typography, Box, MenuItem, Select, FormControl, InputLabel
} from '@mui/material';
import { getToken } from '../../../utils/AuthUtils';

const UpdateAssessmentPage = () => {
  const { id } = useParams(); // Get the assessment ID from the URL
  const navigate = useNavigate(); // For navigation

  const [assessment, setAssessment] = useState(null);
  const [formData, setFormData] = useState({
    assessment_name: "",
    course_id: "",
    chapter_id: "",
    class_id: "",
    levels: "",
    due_date: "",
  });
  const [error, setError] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // Fetch data for dropdowns
  const [standards, setStandards] = useState([]);
  const [courses, setCourses] = useState([]);
  const [chapters, setChapters] = useState([]);

  useEffect(() => {
    if (id) {
      const fetchAssessment = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/assessment/${id}`,
            {
              headers: {
                Authorization: `Bearer ${getToken()}`
            }
          }
          );
          setAssessment(response.data.data);
          setFormData({
            assessment_name: response.data.data.assessment_name || "",
            course_id: response.data.data.course_id || "",
            chapter_id: response.data.data.chapter_id || "",
            class_id: response.data.data.class_id || "",
            levels: response.data.data.levels || "",
            due_date: response.data.data.due_date || "",
          });
        } catch (error) {
          setError("Failed to fetch assessment");
        }
      };

      fetchAssessment();
    }

    // Fetch dropdown data
    const fetchDropdownData = async () => {
      try {
        const standardsResponse = await axios.get(`${process.env.REACT_APP_BACKEND}/api/classes`,
          {
            headers:`Bearer ${getToken()}`
          }
        );
        const coursesResponse = await axios.get(`${process.env.REACT_APP_BACKEND}/api/courses`,
          {
            headers:{
              Authorization: `Bearer ${getToken()}`
            }
          }
        );
        const chaptersResponse = await axios.get(`${process.env.REACT_APP_BACKEND}/api/chapters`,
          {
            headers:{
              Authorization: `Bearer ${getToken()}`
            }
          }
        );
        setStandards(standardsResponse.data.data);
        setCourses(coursesResponse.data.data);
        setChapters(chaptersResponse.data.data);
      } catch (error) {
        setError("Failed to fetch dropdown data");
      }
    };

    fetchDropdownData();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleUpdateAssessment = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_BACKEND}/api/assessment/${id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`
          }
        }
      );
      setSnackbarMessage("Assessment updated successfully");
      setOpenSnackbar(true);
      navigate('/assessmentlist'); // Navigate back to the assessments list or another page
    } catch (error) {
      setError("Failed to update assessment");
    }
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4">
        Update Assessment
      </Typography>
      {error && <Typography color="error">{error}</Typography>}
      {snackbarMessage && <Typography>{snackbarMessage}</Typography>}
      {assessment ? (
        <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }} onSubmit={(e) => e.preventDefault()}>
          <TextField
            label="Assessment Name"
            name="assessment_name"
            value={formData.assessment_name}
            onChange={handleChange}
            fullWidth
            required
          />

          {/* Dropdown for Standards */}
          <FormControl fullWidth required>
            <InputLabel id="standard-label">Standard</InputLabel>
            <TextField
                        select
              labelId="standard-label"
              name="class_id"
              value={formData.class_id}
              onChange={handleChange}
            >
              {standards.map((standard) => (
                <MenuItem key={standard._id} value={standard._id}>
                  {standard.standard}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>

          {/* Dropdown for Courses */}
          <FormControl fullWidth required>
            <InputLabel id="course-label">Course</InputLabel>
            <TextField
                        select
              labelId="course-label"
              name="course_id"
              value={formData.course_id}
              onChange={handleChange}
            >
              {courses.map((course) => (
                <MenuItem key={course._id} value={course._id}>
                  {course.course_name}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>

          {/* Dropdown for Chapters */}
          <FormControl fullWidth required>
            <InputLabel id="chapter-label">Chapter</InputLabel>
            <TextField
                        select
              labelId="chapter-label"
              name="chapter_id"
              value={formData.chapter_id}
              onChange={handleChange}
            >
              {chapters.map((chapter) => (
                <MenuItem key={chapter._id} value={chapter._id}>
                  {chapter.course_name}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>

          <TextField
            label="Levels"
            name="levels"
            value={formData.levels}
            onChange={handleChange}
            fullWidth
            required
          />
          <TextField
            label="Due Date"
            name="due_date"
            type="date"
            value={formData.due_date}
            onChange={handleChange}
            fullWidth
            required
            InputLabelProps={{ shrink: true }}
          />
          <Button variant="contained" color="primary" onClick={handleUpdateAssessment}>
            Update Assessment
          </Button>
        </Box>
      ) : (
        <Typography>Loading...</Typography>
      )}
    </Box>
  );
};

export default UpdateAssessmentPage;
