import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Snackbar,
  Alert,
  Container,
  Grid,
  Typography,
  CircularProgress,
  IconButton,
  Autocomplete,
  Input,
  MenuItem,
  Select,
  FormControl,
  FormControlLabel,
  Checkbox,
  InputLabel,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { getToken } from "../../../utils/AuthUtils";
import { Box } from "@mui/system";

const AddQuiz = () => {
  const navigate = useNavigate();

  const [quizCategory, setQuizCategory] = useState([]);
  const [selectedQuizCategory, setSelectedQuizCategory] = useState(null);
  const [quizSubCategory, setQuizSubCategory] = useState([]);
  const [selectedQuizSubCategory, setSelectedQuizSubCategory] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [questions, setQuestions] = useState([
    {
      question_image: "",
      question_text: "",
      question_type: "mcq",
      points: 0,
      options: ["", "", "", ""],
      answer: "",
    },
  ]);
  const [totalScore, setTotalScore] = useState("");
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [imageFiles, setImageFiles] = useState([]);

  // Fetch quiz categories
  useEffect(() => {
    const fetchQuizCategory = async () => {
      try {
        const token = getToken();
        if (!token) {
          console.log("Unauthorized user");
          return;
        }
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND}/api/quiz/category`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        if (response.ok) {
          setQuizCategory(data.data || []);
        }
      } catch (error) {
        console.error("Error fetching quiz categories:", error);
      }
    };
    fetchQuizCategory();
  }, []);

  // Fetch quiz sub-categories based on selected quiz category
  useEffect(() => {
    const fetchQuizSubCategory = async () => {
      if (!selectedQuizCategory) return;
      try {
        const token = getToken();
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND}/api/quiz/subcategory/${selectedQuizCategory._id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        if (response.ok) {
          setQuizSubCategory(data.data || []);
        }
      } catch (error) {
        console.error("Error fetching quiz subcategories:", error);
      }
    };
    fetchQuizSubCategory();
  }, [selectedQuizCategory]);

  // Handle change for question fields (question text, points, options, etc.)
  const handleQuestionChange = (index, e) => {
    const { name, value } = e.target;
    const updatedQuestions = [...questions];

    if (name.startsWith("option_")) {
      const optionIndex = parseInt(name.split("_")[1]) - 1;
      updatedQuestions[index].options[optionIndex] = value;
    } else if (name === "question_type") {
      updatedQuestions[index][name] = value;
      if (value === "yes/no") {
        updatedQuestions[index].options = []; // No options needed for yes/no
      } else {
        updatedQuestions[index].options = ["", "", "", ""]; // Reset options for MCQ
      }
    } else if (name === "answer" && value !== "") {
      updatedQuestions[index].answer = value; // Set the correct answer
    } else {
      updatedQuestions[index][name] = value;
    }

    setQuestions(updatedQuestions);
  };

  // Add new question to the form
  const handleAddQuestion = () => {
    setQuestions([
      ...questions,
      {
        question_image: "",
        question_text: "",
        question_type: "mcq",
        points: 0,
        options: ["", "", "", ""],
        answer: "",
      },
    ]);
  };

  // Remove question from the form
  const handleRemoveQuestion = (index) => {
    if (questions.length > 1) {
      setQuestions(questions.filter((_, i) => i !== index));
    }
  };

  // Handle image change for a question
  const handleImageChange = (index, e) => {
    const file = e.target.files[0];

    if (file) {
      const imageUrl = URL.createObjectURL(file); // Create image URL for preview

      setQuestions((prevQuestions) => {
        const updatedQuestions = [...prevQuestions];
        updatedQuestions[index] = {
          ...updatedQuestions[index],
          question_image: {
            file: file, // Store the file for future submission
            imageUrl: imageUrl, // Store the URL for preview
          },
        };
        return updatedQuestions;
      });

       // Add the file to the imageFiles array
    setImageFiles((prevImageFiles) => {
      const updatedImageFiles = [...prevImageFiles];
      updatedImageFiles[index] = file; // Maintain the order of images
      return updatedImageFiles;
    });
    }
  };

  // Submit the form
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Calculate total points and validate
    const totalPoints = questions.reduce(
      (sum, question) => sum + Number(question.points),
      0
    );
    if (Number(totalScore) !== totalPoints) {
      setError("Total score and sum of points do not match.");
      return;
    }

    // Check that each MCQ has a selected answer
    for (const question of questions) {
      if (question.question_type === "mcq" && !question.answer) {
        setError("Please select a correct answer for each MCQ question.");
        return;
      }
    }

    // Create FormData for submission
    const formData = new FormData();
    formData.append("catId", selectedQuizCategory?._id);
    formData.append("subcatId", selectedQuizSubCategory?._id);
    formData.append("total_score", totalScore);
    formData.append("quiz_title", title);
    formData.append("questions", JSON.stringify(questions.map(q => ({
      ...q,
      question_image: q.question_image.file ? "" :null // Append file for image
    }))));

    // Append all image files to FormData
  imageFiles.forEach((file, index) => {
    if (file) {
      formData.append(`question_image_${index}`, file);
    }
  });

    // // Append the image for each question
    // questions.forEach((question, index) => {
    //   if (question.question_image?.file) {
    //     formData.append(`question_image_${index}`, question.question_image.file);
    //   }
    // });

    setLoading(true);

    try {
      const token = getToken();
      if (!token) {
        setError("Unauthorized user.");
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND}/api/quiz`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 201) {
        setSuccess("Quiz successfully created!");
        resetForm();
        navigate("/teacher/quiz-list");
      } else {
        setError("Failed to create quiz.");
      }
    } catch (err) {
      setError("An error occurred while creating the quiz.");
    } finally {
      setLoading(false);
    }
  };

  // Reset form to initial state
  const resetForm = () => {
    setSelectedQuizCategory(null);
    setSelectedQuizSubCategory(null);
    setTotalScore("");
    setTitle("");
    setQuestions([
      {
        question_text: "",
        question_type: "mcq",
        points: "",
        options: ["", "", "", ""],
        answer: "",
      },
    ]);
  };

  // Close Snackbar (success or error)
  const handleSnackbarClose = () => {
    setSuccess(null);
    setError(null);
  };

  // handleNumberChange
  const handleNumberChange = (e) => {
    const value = e.target.value;
    // Allow only valid numbers or empty string
    if (!isNaN(value) && value >= 0) {
      setTotalScore(value);
    }
  };


  return (
    <Container maxWidth="xl" sx={{ padding: "20px", borderRadius: "8px" }}>
      <Typography variant="h4">
        Create Quiz
      </Typography>

      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormControl fullWidth>
              {/* <InputLabel>Quiz Category</InputLabel> */}
              <TextField
              select
                value={selectedQuizCategory || ""}
                onChange={(e) => setSelectedQuizCategory(e.target.value)}
                label="Quiz Category"
                required
              >
                {quizCategory.map((category) => (
                  <MenuItem key={category._id} value={category}>
                    {category.quiz_category_name}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>


          {/* Quiz Sub-Category */}
          <Grid item xs={12} sm={12}>
            <FormControl fullWidth>
              {/* <InputLabel>Quiz Subcategory</InputLabel> */}
              <TextField
              select
                value={selectedQuizSubCategory || ""}
                onChange={(e) => setSelectedQuizSubCategory(e.target.value)}
                label="Quiz Subcategory"
                required
              >
                {quizSubCategory.map((subCategory) => (
                  <MenuItem key={subCategory._id} value={subCategory}>
                    {subCategory.quiz_sub_category_name}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              label="Quiz Title"
              variant="outlined"
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </Grid>

          {/* Total Score */}
          <Grid item xs={12} sm={12}>
            <TextField
              label="Total Score"
              type="number"
              variant="outlined"
              fullWidth
              value={totalScore}
              // onChange={(e) => setTotalScore(e.target.value)}
              onChange={handleNumberChange}
              required
            />
          </Grid>
        </Grid>

        <Typography variant="h6" gutterBottom sx={{ marginTop: "20px" }}>
          Add Questions
        </Typography>

        {questions.map((question, index) => (
          <Box
            key={index}
            sx={{
              border: "1px solid #ccc",
              padding: "16px",
              borderRadius: "8px",
              marginBottom: "16px",
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Box>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleImageChange(index, e)}
                    style={{ display: "none" }}
                    id={`image-upload-${index}`}
                  />
                  <label htmlFor={`image-upload-${index}`}>
                    <Button variant="outlined" component="span">
                      Upload Image
                    </Button>
                  </label>
                  {question.question_image?.imageUrl && (
                    <Box sx={{ marginTop: "10px", textAlign: "center" }}>
                      <img
                        src={question.question_image.imageUrl}
                        alt="Question preview"
                        style={{
                          maxWidth: "100%",
                          maxHeight: "200px",
                          objectFit: "contain",
                          borderRadius: "8px",
                        }}
                      />
                    </Box>
                  )}
                </Box>
                </Grid>
           

              <Grid item xs={12} sm={12}> 
                <TextField
                  label="Question Text"
                  variant="outlined"
                  fullWidth
                  name="question_text"
                  value={question.question_text}
                  onChange={(e) => handleQuestionChange(index, e)}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={12}> 
                <TextField
                  label="Points"
                  type="number"
                  variant="outlined"
                  fullWidth
                  name="points"
                  value={question.points}
                  onChange={(e) => handleQuestionChange(index, e)}
                  required
                />
                </Grid>
             

              <Grid item xs={12} sm={12}> 
                <FormControl fullWidth>
                  {/* <InputLabel>Question Type</InputLabel> */}
                  <TextField
                  select
                    name="question_type"
                    value={question.question_type}
                    onChange={(e) => handleQuestionChange(index, e)}
                    label="Question Type"
                  >
                    <MenuItem value="mcq">MCQ</MenuItem>
                    <MenuItem value="yes/no">Yes/No</MenuItem>
                  </TextField>
                </FormControl>
              </Grid>

              {/* MCQ Options */}
              {question.question_type === "mcq" && (
                <Grid item xs={12} sm={12}>
                  {question.options.map((option, optionIndex) => (
                    <Box key={optionIndex} sx={{ marginBottom: "10px" }}>
                      <TextField
                        label={`Option ${optionIndex + 1}`}
                        variant="outlined"
                        fullWidth
                        name={`option_${optionIndex + 1}`}
                        value={option}
                        onChange={(e) => handleQuestionChange(index, e)}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={question.answer === option} // Compare the answer with the option text
                            onChange={() =>
                              handleQuestionChange(index, {
                                target: { name: "answer", value: option },
                              })
                            }
                            name="answer"
                            value={option} // Store the option text instead of index
                          />
                        }
                        label="Correct Answer"
                      />
                    </Box>
                  ))}
                </Grid>
              )}

              {/* Yes/No Answer */}
              {question.question_type === "yes/no" && (
                <Grid item xs={12} sm={12}>
                  <FormControl fullWidth>
                    {/* <InputLabel>Answer</InputLabel> */}
                    <TextField
                    select
                      value={question.answer || ""}
                      onChange={(e) => handleQuestionChange(index, e)}
                      name="answer"
                      label="Answer"
                    >
                      <MenuItem value="Yes">Yes</MenuItem>
                      <MenuItem value="No">No</MenuItem>
                    </TextField>
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={12} sm={12}>
                <IconButton
                  onClick={() => handleRemoveQuestion(index)}
                  color="error"
                  aria-label="delete"
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        ))}
<Grid container spacing={2}>
        <Grid item xs={12} md={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddQuestion}
            sx={{backgroundColor:"#e0e0e0" }}
          >
            Add Question
          </Button>
        </Grid>

        
          <Grid item xs={12} md={2}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Submit Quiz"
              )}
            </Button>
  
          </Grid>
          {/* <Button
            variant="outlined"
            color="secondary"
            onClick={resetForm}
            disabled={loading}
          >
            Reset
          </Button> */}
        </Grid>
      </form>

      <Snackbar
        open={success !== null || error !== null}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={success ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {success || error}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AddQuiz;
