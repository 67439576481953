import React, { useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import axios from "axios";
import { getToken } from "../../../utils/AuthUtils";
import { useLocation, useNavigate } from "react-router-dom";

function SchoolRating() {
  const [rating, setRating] = useState(0);
  const [openRatingDialog, setOpenRatingDialog] = useState(false);
  const [points, setPoints] = useState(0);
  const [isSaved, setIsSaved] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();
  const { id } = location.state || {}; // Ensure safe destructuring

  // Function to calculate points
  const calculatePoints = (stars) => stars * 2;

  // Function to handle dialog open
  const handleOpenRatingDialog = (index) => {
    const newRating = index + 1; // Ratings are 1-indexed
    setRating(newRating);
    setPoints(calculatePoints(newRating));
    setOpenRatingDialog(true);
  };

  // Function to handle dialog close
  const handleCloseRatingDialog = () => {
    // setPoints(null)
    setOpenRatingDialog(false);
  };

  // Function to save points
  const savePoints = async () => {
    try {
      const token = getToken();
      if (!token) {
        console.error("Unauthorized Access");
        return;
      }

      // Send points to API
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND}/api/assessment/add-teacher-rating`,
        { points, id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 201) {
        console.log("Points saved successfully:", points);
        setIsSaved(true); // Indicate success
        handleCloseRatingDialog();
        setTimeout(() => {
          navigate("/student/assessment-submited")
        }, 1000);
      } else {
        throw new Error("Failed to save points");
      }
    } catch (error) {
      console.error("Error saving points:", error);
    }
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        color: "#fff",
        padding: 2,
        backgroundColor: "#51cd51",
      }}
    >
      <Paper
        elevation={6}
        sx={{
          width: "100%",
          maxWidth: "400px",
          padding: 4,
          textAlign: "center",
          borderRadius: 2,
          color: "#fff",
        }}
      >
        <Typography
          variant="h4"
          sx={{ fontWeight: "bold", color: "#4CAF50", mb: 2 }}
        >
          ✔ Assessment Submitted!
        </Typography>
        <Typography variant="body1" sx={{ mb: 3, color: "#999999" }}>
          Thank you for submitting your assessment. Your feedback is important
          to us.
        </Typography>

        {/* Star Rating Section */}
        <Box>
          <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
            School Rating:
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center", gap: 1 }}>
            {[...Array(5)].map((_, index) => (
              <IconButton
                key={index}
                onClick={() => handleOpenRatingDialog(index)}
                sx={{
                  color: index < rating ? "#FFD700" : "#CCC",
                  transition: "color 0.3s",
                }}
              >
                <StarIcon fontSize="large" />
              </IconButton>
            ))}
          </Box>

          {/* Display Points */}
          {rating > 0 && (
            <Typography
              variant="body2"
              sx={{
                mt: 2,
                fontSize: "1rem",
                color: isSaved ? "#4CAF50" : "#FF5722",
              }}
            >
              {isSaved
                ? `You rated ${rating} star(s), earning ${points} points. Points saved successfully!`
                : `You rated ${rating} star(s), earning ${points} points. Saving...`}
            </Typography>
          )}
        </Box>
      </Paper>

      {/* Rating Confirmation Dialog */}
      <Dialog open={openRatingDialog} onClose={handleCloseRatingDialog}>
        <DialogTitle>Confirm Rating</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to give this rating?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRatingDialog} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={savePoints}
            color="primary"
            sx={{ backgroundColor: "#e0e0e0" }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default SchoolRating;
