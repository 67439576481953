import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Container,
  CircularProgress,
  Grid,
  Divider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getToken } from "../../../utils/AuthUtils";
import { ArrowBack } from "@mui/icons-material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useNavigate } from "react-router-dom";

const CourseListMain = () => {
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [expandedCourseId, setExpandedCourseId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCourses = async () => {
      setIsLoading(true);
      try {
        const token = getToken();
        if (token) {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/courses/assignedcoursestosc`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }


          );

          // Log the result
          console.log("Fetched courses:", response.data.data);

          setCourses(response.data.data);
        }
      } catch (err) {
        console.error("Error fetching courses:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCourses();
  }, []);

  const toggleExpand = (id) => {
    setExpandedCourseId(expandedCourseId === id ? null : id);
  };
  const handleRedirect = (id,gradenamee) => {
   navigate(`/school/ongoing-courses-chapter/${id}`, {
    state: { gradenamee },
  });
  };
  return (
    <Container maxWidth="100%">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        padding={1}
        sx={{ backgroundColor: "#e8e8e8",color:"36454F" }}
      >
        <Typography variant="h4">
          COURSES
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" padding={2}>
        {isLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            padding={2}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            {courses.length > 0 ? (
              courses.map((group) => (
                <Accordion
                  key={group.grade_id}
                  sx={{ backgroundColor: "#e0e0e0" }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    onClick={() => toggleExpand(group.grade_id)}
                  >
                    <Typography
                      variant="h6"
                      sx={{ color: "#36454F  !important" }}
                    >
                      {` ${group.grade_name}`}{" "}
                      {/* Displaying the grade_name instead of grade_id */}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box display="flex" flexDirection="column" gap={4}>
                      {group.courses.map((course, index) => (
                        <React.Fragment key={course._id}>
                          <Grid
                            container
                            spacing={3}
                            key={course._id}
                            sx={{
                              // backgroundColor: "#eaf5fe",
                              borderRadius: "16px",
                              padding: 2,
                            }}
                          >
                            {/* Course Image Section */}
                            <Grid
                              item
                              xs={12}
                              md={4}
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              {course.course_image ? (
                                <img
                                  src={course.course_image}
                                  alt="Course Image"
                                  style={{
                                    width: "100%",
                                    height: "auto",
                                    objectFit: "cover",
                                    borderRadius: "12px",
                                  }}
                                />
                              ) : (
                                <Typography>No Photo</Typography>
                              )}
                            </Grid>

                            {/* Course Details Section */}
                            <Grid item xs={12} md={8}>
                              {/* Course Title */}
                              <Typography
                                variant="h5"
                                sx={{
                                  fontWeight: "bold",
                                  color: "#000 !important",
                                  mb: 1,
                                }}
                              >
                                {course.courses_name}
                              </Typography>

                              {/* Course Description */}
                              <Typography sx={{ mb: 2, color: "#495057" }}>
                                {course.course_details}
                              </Typography>

                              {/* Additional Course Info */}
                              <Grid container spacing={2} alignItems="center">
                                {/* Left Section */}
                                <Grid item xs={12} md={9}>
                                  {/* Top Row: Date and Department */}
                                  <Grid container spacing={2} sx={{ mb: 2 }}>
                                    <Grid item xs={12} md={5}>
                                      <Typography
                                        variant="subtitle2"
                                        sx={{
                                          color: "#6c757d",
                                          border: "0.5px solid #e0e0e0",
                                          padding: "5px",
                                          borderRadius: "5px",
                                          textAlign: "center",
                                          width: "100%",
                                        }}
                                      >
                                        Date Created:{" "}
                                        {course.date_created
                                          ? new Date(
                                              course.date_created
                                            ).toLocaleDateString()
                                          : "N/A"}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={4.5}>
                                      <Typography
                                        variant="subtitle2"
                                        sx={{
                                          color: "#6c757d",
                                          border: "0.5px solid #e0e0e0",
                                          padding: "5px",
                                          borderRadius: "5px",
                                          textAlign: "center",
                                        }}
                                      >
                                        Department:{" "}
                                        {course.department_name || "N/A"}
                                      </Typography>
                                    </Grid>
                                  </Grid>

                                  {/* Bottom Row: Students, Teachers, Projects */}
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} md={3}>
                                      <Typography
                                        variant="subtitle2"
                                        sx={{
                                          textAlign: "center",
                                          padding: "5px",
                                          color: "#6c757d",
                                          backgroundColor: "#e2e0dd",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        Students:{" "}
                                        {course.students_count || "N/A"}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                      <Typography
                                        variant="subtitle2"
                                        sx={{
                                          textAlign: "center",
                                          padding: "5px",
                                          color: "#6c757d",
                                          backgroundColor: "#e2e0dd",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        Teachers Assigned:{" "}
                                        {course.teachers_count || "N/A"}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={2.5}>
                                      <Typography
                                        variant="subtitle2"
                                        sx={{
                                          textAlign: "center",
                                          padding: "5px",
                                          color: "#6c757d",
                                          backgroundColor: "#e2e0dd",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        Projects:{" "}
                                        {course.projects_count || "N/A"}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>

                                {/* Right Section: Button */}
                                <Grid
                                  item
                                  xs={12}
                                  md={3}
                                  textAlign={{ xs: "center", md: "right" }}
                                >
                                  <Button
                                    variant="contained"
                                    onClick={() => handleRedirect(course._id,group.grade_name)}
                                    sx={{
                                      textTransform: "none",
                                      padding: "8px 16px",
                                      borderRadius: "5px",
                                      backgroundColor: "#f47b1f",
                                      color: "#fff !important",
                                    }}
                                    endIcon={
                                      <PlayArrowIcon sx={{ color: "#fff" }} />
                                    }
                                  >
                                    View Chapters
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>{" "}
                          {index < group.courses.length - 1 && (
                            <Divider
                              sx={{
                                margin: "16px 0",
                                borderColor: "#777777 !important",
                              }}
                            />
                          )}
                        </React.Fragment>
                      ))}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              ))
            ) : (
              <Typography align="center">No courses found</Typography>
            )}
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default CourseListMain;
