import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  TextField,
  Card,
  CardContent,
  Typography,
  InputAdornment,
  IconButton,
  Grid,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility"; // Eye icon
import NotFound from "../../../assets/NOT_FOUND.png"; // Add the "No Projects Found" image
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import { getToken } from "../../../utils/AuthUtils";

const ProjectByStudentForTeacher = () => {
  const { id } = useParams();
  const [projects, setProjects] = useState([]);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const [canCreate, setCanCreate] = useState(false);  
const [canView, setCanView] = useState(false);  
const [canUpdate, setCanUpdate] = useState(false);
const [canDelete, setCanDelete] = useState(false);


useEffect(() => {
  const fetchPermissions = async () => {
    const token = localStorage.getItem('token_key');
    
    if (token) {
      try {
        const roleResponse = await axios.get(
          `${process.env.REACT_APP_ADMIN_BACKEND}/api/rolespermission/permissions/23`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        
        const rolePermissions = roleResponse.data.data;
        
        if (rolePermissions) {
          setCanCreate(Boolean(rolePermissions.create));
          setCanView(Boolean(rolePermissions.view));
          setCanUpdate(Boolean(rolePermissions.update));
          setCanDelete(Boolean(rolePermissions.delete));
        }
      } catch (error) {
        console.error("Error fetching role permissions:", error);
      }
    }
  };

  fetchPermissions();
}, []);



  // Fetch Projects based on search term
  const fetchProject = async (searchTerm = "") => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/project/listproject/bystudent/forteacher`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token_key"),
          },
          params: {
            student_id: id,
            search: searchTerm, // Pass the search term as a query parameter
          },
        }
      );
      setProjects(response.data.data);
    } catch (err) {
      setError(err.message || "Failed to fetch project data");
    }
  };

  // Fetch projects on initial load and when search term changes
  useEffect(() => {
    fetchProject(searchTerm);
  }, [searchTerm]); // Re-run fetchProject whenever the search term changes


  return (
    <Box sx={{ padding: "20px" }}>
      <Box
        p={1}
        mb={2}
        sx={{
          backgroundColor: "#e0e0e0cf",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4">Project List</Typography>
        <TextField
          // label="Search"
          variant="outlined"
          size="small"
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
          sx={{
            width: "300px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#00000030",
              },
              "&:hover fieldset": {
                borderColor: "#00000070",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#00000040",
              },
            },
            "& .MuiInputLabel-root": {
              display: "flex",
              alignItems: "center",
              transition: "all 0.3s ease",
            },
            "& .MuiInputLabel-shrink": {
              display: "flex",
              alignItems: "center",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      {/* filters */}
      <Grid item xs={12}>
        <Box
          display="flex"
          alignItems="center"
          padding={2}
          gap={1}
          sx={{ paddingRight: "0", paddingLeft: "0" }}
        >
          <Box flexGrow={1} /> {/* This will push the button to the right */}
        </Box>
      </Grid>
      {canView ? (
      <>
        {projects.length > 0 ? (
          <Grid container spacing={2}>
            {projects.map((project) => (
              <Grid item xs={12} sm={6} md={4} key={project.id}>
                <Card
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "15px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    position: "relative",
                  }}
                >
                  <CardContent sx={{ p: 2 }}>
                    {/* Image Section */}
                    <Box
                      component="img"
                      src={project.project_image} // Replace with actual image source if needed
                      alt={project.project_title}
                      sx={{
                        width: "100%",
                        height: "200px",
                        borderRadius: "12px",
                        mb: 2,
                      }}
                    />

                    {/* Product Name and Info */}
                    <Typography variant="h6" sx={{ mb: 1 }}>
                      {project.project_title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{ mb: 1 }}
                    >
                      {/* Uploaded by: {project.studentData.name} */}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{ mb: 1 }}
                    >
                      {project.student_id.grade_division}
                    </Typography>
                  </CardContent>

                  {/* View Project Button (Eye Icon) */}
                  <IconButton
                    sx={{
                      position: "absolute",
                      bottom: 10,
                      right: 10,
                      color: "#1976d2",
                    }}
                    onClick={() => navigate(`/projectinterface/${project._id}`)} // Navigate to the ProjectView page
                  >
                    <VisibilityIcon /> {/* Eye icon */}
                  </IconButton>
                </Card>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid item xs={12} sm={12} md={12}>
              <Typography
                align="center"
                sx={{ fontSize: "28px", color: "#0000004a", marginTop: "23vh" }}
              >
                No data found
              </Typography>
            </Grid>
        )}
      </>
    ) : (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ height: "200px", textAlign: "center" }}
      >
        <Typography variant="h6" color="textSecondary">
          No permission to view.
        </Typography>
      </Box>
    )}

      {/* Pagination at the Bottom Left */}
      {/* Uncomment if pagination is needed */}
      {/* <Box mt={4} display="flex" justifyContent="flex-start">
        <Pagination
          count={10}
          page={page}
          onChange={handlePageChange}
          color="primary"
        />
      </Box> */}
    </Box>
  );
};

export default ProjectByStudentForTeacher;
