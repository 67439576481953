import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  FormControl,
  TableRow,
  Typography,
  Paper,
  InputLabel,
  Autocomplete,
  TextField,
} from "@mui/material";
import axios from "axios";
import { getToken } from "../../../utils/AuthUtils";
import { useNavigate } from "react-router-dom";
import { Assessment } from "@mui/icons-material";

// const actions = ["Reports", "Comments", "Projects", "Quiz", "Attendance"];
const actions = ["Assessments", "Comments", "Projects"];

const buttonColors = {
  Assessments: "#f9d77b",
  Comments: "#8cd9a1",
  Projects: "#84c7e8",
  Quiz: "#f3b0be",
  Attendance: "#e8e8e8",
};

const GradesAssigned = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [grades, setGrades] = useState([]);
  const [students, setStudents] = useState([]);
  const navigate = useNavigate();
  const [gradeAssign, setGradeAssign] = useState({
    grade: "",
    gradeDivision: "",
    name: "",
  });
  const [canCreate, setCanCreate] = useState(false);  
const [canView, setCanView] = useState(false);  
const [canUpdate, setCanUpdate] = useState(false);  
const [canDelete, setCanDelete] = useState(false);  


useEffect(() => {
  const fetchPermissions = async () => {
    const token = localStorage.getItem('token_key');
    
    if (token) {
      try {
        const roleResponse = await axios.get(
          `${process.env.REACT_APP_ADMIN_BACKEND}/api/rolespermission/permissions/18`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        
        const rolePermissions = roleResponse.data.data;
        
        // Check role permissions or set the role
        if (rolePermissions ) {
          // navigate("/unauthorized");
          // console.log("You are not authorized to create a school",rolePermissions);
          setCanCreate(rolePermissions.create);
          setCanView(rolePermissions.view);
          setCanUpdate(rolePermissions.update);
          setCanDelete(rolePermissions.delete);
        }
      } catch (error) {
        console.error("Error fetching role permissions:", error);
        // navigate("/unauthorized"); // Redirect in case of error
      }
    }
  };

  fetchPermissions();
}, []);

  useEffect(() => {
    const fetchgradebyteacher = async () => {
      try {
        const token = getToken();
        if (!token) {
          console.log("Access Denied");
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/grade_assign/teacherData`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const gradeData = response.data.data;
        setGrades(gradeData);

        console.log("gradeData: ", response.data.data);
      } catch (error) {
        console.error("Error fetching teachers:", error);
      }
    };
    fetchgradebyteacher();
    const fetchstudentBygradedivision = async () => {
      if (gradeAssign.grade === "") {
        // If "Select All" is chosen, fetch all students
        fetchStudents();
        return;
      }
      if (!gradeAssign.gradeDivision) return;
      try {
        const token = getToken();
        if (!token) {
          console.log("Access Denied");
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/grade_assign/gradedivision/${gradeAssign.gradeDivision}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const students = response.data.data;
        setStudents(students);
        // setGradeAssign({
        //   grade: "",
        //   gradeDivision: "",
        //   name: "",
        // });
        if(students){
          setStudents(students);
        }else{
          setStudents([])
        }
        console.log("students: ", response.data.data);
      } catch (error) {
        console.error("Error fetching students:", error);
      }
    };
    fetchstudentBygradedivision();
  }, [gradeAssign.gradeDivision]);

  // useEffect(() => {
    // fetchStudents();
  // }, [gradeAssign]);

  const fetchStudents = async () => {
    // setLoading(true);
    try {
      const token = getToken();
      if (!token) {
        setError("Please login to access students.");
        return;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/grade_assign/students`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const data = response.data;
      if (data?.data) {
        setStudents(data.data.students);
      } else {
        setStudents([]); // Reset students if no data is found
        setError("No students found");
      }
    } catch (err) {
      setError("Failed to fetch students");
    } finally {
      setLoading(false);
    }
  };

  const handleActionClick = (action, studentId) => {
    if (action === "Comments") {
      navigate(`/teacher/comments/${studentId}`); // Redirect to /teacher/comments on "Comments" button click
    }
    // You can handle other actions here if needed
  };
  return (
    <Box sx={{ padding: 3 }}>
      {/* Grades Assigned Header */}
      <Box
        p={1}
        mb={2}
        sx={{
          backgroundColor: "#e0e0e0cf",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4">Student List</Typography>
        {/* <TextField
          // label="Search"
          variant="outlined"
          size="small"
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
          sx={{
            width: "300px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#00000030",
              },
              "&:hover fieldset": {
                borderColor: "#00000070",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#00000040",
              },
            },
            "& .MuiInputLabel-root": {
              display: "flex",
              alignItems: "center",
              transition: "all 0.3s ease",
            },
            "& .MuiInputLabel-shrink": {
              display: "flex",
              alignItems: "center",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        /> */}
      </Box>
      {/* Select Grade and Division */}
      <Box sx={{ display: "flex", gap: 2, marginBottom: 2 }}>
        <FormControl margin="normal" sx={{ minWidth: 300 }}>
        <Autocomplete
        options={grades}
        getOptionLabel={(option) =>
          typeof option === "string"
            ? option
            : `${option.grade_id?.gradename || ""} ${option.grade_division_id || ""}`.trim()
        }
        value={
          gradeAssign.grade
            ? grades.find((item) => item._id === gradeAssign.grade) || null
            : ""
        }
        onChange={(event, newValue) => {
          setStudents([]);
          if (newValue === "") {
            // Reset the grade and gradeDivision when "Select All" is chosen
            setGradeAssign({
              grade: "",
              gradeDivision: "",
            });
          } else if (newValue && newValue.grade_id) {
            const gradeDivision = `${newValue.grade_id.gradename || ""} ${newValue.grade_division_id || ""}`.trim();
            setGradeAssign({
              grade: newValue._id,
              gradeDivision,
            });
          } else {
            setGradeAssign({
              grade: "",
              gradeDivision: "",
            });
          }
        }}
        renderInput={(params) => (
          <>
            
            <TextField
              {...params}
              label="Grade"
              size="small"
              variant="outlined"
              aria-required="true"
            />
          </>
        )}
        isOptionEqualToValue={(option, value) =>
          (typeof option === "string" && option === value) ||
          (option._id && value._id && option._id === value._id)
        }
        clearOnEscape
        disablePortal
      />
        </FormControl>
      </Box>

      {/* Student List */}
      <TableContainer>
  {canView === 1 ? (

        <Table sx={{ width: "80%" }}>
          <TableBody>
            {students.length > 0 ? (
              students.map((student, index) => (
                <TableRow key={student._id}>
                  <TableCell>
                  {index + 1}. {student.name ? student.name : null}
                  </TableCell>
                  <TableCell
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    {/* Action Buttons with 5px gap */}
                    {actions.map((action) => (
                      <Button
                        key={action}
                        variant="contained"
                        sx={{
                          backgroundColor: buttonColors[action],
                          color: "#000",
                          "&:hover": {
                            backgroundColor: buttonColors[action],
                            opacity: 0.8,
                          },
                          // fontWeight: "bold",
                          padding: "6px 12px", // Adjust padding as needed
                          minWidth: "80px", // Ensures buttons are the same size
                          marginLeft: "5px", // Adds 5px gap between buttons
                        }}
                        onClick={() => handleActionClick(action, student._id)}
                      >
                        {action}
                      </Button>
                    ))}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <Typography align="center">No Students found for this grade</Typography>
            )}
          </TableBody>
        </Table>
         ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ height: "200px", textAlign: "center" }}
          >
            <Typography variant="h6" color="textSecondary">
              No permission to view.
            </Typography>
          </Box>
        )}
      </TableContainer>
    </Box>
  );
};

export default GradesAssigned;
