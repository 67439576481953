import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Snackbar,
  Alert,
  Container,
  Grid,
  Typography,
  CircularProgress,
  IconButton,
  Autocomplete,
  Input,
  MenuItem,
  Select,
  FormControl,
  FormControlLabel,
  Checkbox,
  InputLabel,
} from "@mui/material";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { getToken } from "../../../utils/AuthUtils";
import { Box } from "@mui/system";

const EditQuiz = () => {
  const navigate = useNavigate();
  const { quizId } = useParams();

  const [quizCategory, setQuizCategory] = useState([]);
  const [selectedQuizCategory, setSelectedQuizCategory] = useState(null);
  const [quizSubCategory, setQuizSubCategory] = useState([]);
  const [selectedQuizSubCategory, setSelectedQuizSubCategory] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [totalScore, setTotalScore] = useState("");
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [imageFiles, setImageFiles] = useState([]);
  const [profileImagePreview, setProfileImagePreview] = useState("");

  // Fetch quiz data by ID
  useEffect(() => {
    fetchQuiz(quizId);
  }, [quizId]);

  const fetchQuiz = async (quizId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/quiz/quiz/${quizId}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      const data = await response.json();

      if (data?.data) {
        const quizData = data.data.quizData;
        const questionData = data.data.quizQuestionData;

        setQuestions(questionData.map(q => ({
          question_image: q.question_image || '',
          question_text: q.question_text || '',
          question_type: q.question_type || 'mcq',
          points: q.points || 0,
          options: q.options || ['', '', '', ''],
          answer: q.answer || '',
        })));

        setSelectedQuizCategory(quizData.quiz_category_id._id);
        setSelectedQuizSubCategory(quizData.quiz_sub_category_id._id);
        setTotalScore(quizData.quiz_score);
        setTitle(quizData.quiz_title);
      } else {
        setError('Quiz not found');
      }
    } catch (err) {
      setError('Failed to fetch quiz');
    } finally {
      setLoading(false);
    }
  };

  // Fetch quiz categories
  useEffect(() => {
    const fetchQuizCategory = async () => {
      try {
        const token = getToken();
        if (!token) {
          console.log("Unauthorized user");
          return;
        }
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/quiz/category`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        if (response.ok) {
          setQuizCategory(data.data || []);
        }
      } catch (error) {
        console.error("Error fetching quiz categories:", error);
      }
    };
    fetchQuizCategory();
  }, []);

  // Fetch quiz sub-categories based on selected quiz category
  useEffect(() => {
    const fetchQuizSubCategory = async () => {
      if (!selectedQuizCategory) return;
      try {
        const token = getToken();
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/quiz/subcategory/${selectedQuizCategory}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        if (response.ok) {
          setQuizSubCategory(data.data || []);
        }
      } catch (error) {
        console.error("Error fetching quiz subcategories:", error);
      }
    };
    fetchQuizSubCategory();
  }, [selectedQuizCategory]);

  const handleQuestionChange = (index, e) => {
    const { name, value } = e.target;
    const updatedQuestions = [...questions];

    if (name.startsWith("option_")) {
      const optionIndex = parseInt(name.split("_")[1]) - 1;
      updatedQuestions[index].options[optionIndex] = value;
    } else if (name === "question_type") {
      updatedQuestions[index][name] = value;
      if (value === "yes/no") {
        updatedQuestions[index].options = [];
      } else {
        updatedQuestions[index].options = ["", "", "", ""];
      }
    } else if (name === "answer" && value !== "") {
      updatedQuestions[index].answer = value;
    } else {
      updatedQuestions[index][name] = value;
    }

    setQuestions(updatedQuestions);
  };

  const handleAddQuestion = () => {
    setQuestions([
      ...questions,
      {
        question_image: "",
        question_text: "",
        question_type: "mcq",
        points: 0,
        options: ["", "", "", ""],
        answer: "",
      },
    ]);
  };

  const handleRemoveQuestion = (index) => {
    if (questions.length > 1) {
      setQuestions(questions.filter((_, i) => i !== index));
    }
  };

  const handleImageChange = (index, e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setQuestions((prevQuestions) => {
        const updatedQuestions = [...prevQuestions];
        updatedQuestions[index] = {
          ...updatedQuestions[index],
          question_image: {
            file: file,
            imageUrl: imageUrl,
          },
        };
        return updatedQuestions;
      });

      setImageFiles((prevImageFiles) => {
        const updatedImageFiles = [...prevImageFiles];
        updatedImageFiles[index] = file;
        return updatedImageFiles;
      });
    }
  };

  const handleCancel = () => {
    navigate('/teacher/quiz-list'); // Navigate to the desired route on cancel
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // const totalPoints = questions.reduce(
    //   (sum, question) => sum + Number(question.points),
    //   0
    // );
    // if (Number(totalScore) !== totalPoints) {
    //   setError("Total score and sum of points do not match.");
    //   return;
    // }

    // for (const question of questions) {
    //   if (question.question_type === "mcq" && !question.answer) {
    //     setError("Please select a correct answer for each MCQ question.");
    //     return;
    //   }
    // }

    // const formData = new FormData();
    // formData.append("catId", selectedQuizCategory?._id);
    // formData.append("subcatId", selectedQuizSubCategory?._id);
    // formData.append("total_score", totalScore);
    // formData.append("quiz_title", title);
    // formData.append("questions", JSON.stringify(questions.map(q => ({
    //   ...q,
    //   question_image: q.question_image.file ? "" : null,
    // }))));

    // imageFiles.forEach((file, index) => {
    //   if (file) {
    //     formData.append(`question_image_${index}`, file);
    //   }
    // });


    setLoading(true);

    try {
      const token = getToken();
      if (!token) {
        setError("Unauthorized user.");
        return;
      }

      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND}/api/quiz/edit/${quizId}`,
        {selectedQuizCategory,title,selectedQuizSubCategory,totalScore},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setSuccess("Quiz successfully updated!");
        resetForm();
        navigate("/teacher/quiz-list");
      } else {
        setError("Failed to update quiz.");
      }
    } catch (err) {
      setError("An error occurred while updating the quiz.");
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setSelectedQuizCategory(null);
    setSelectedQuizSubCategory(null);
    setTotalScore("");
    setTitle("");
    setQuestions([{
      question_text: "",
      question_type: "mcq",
      points: 0,
      options: ["", "", "", ""],
      answer: "",
    }]);
    setImageFiles([]);
  };

  // Close Snackbar (success or error)
  const handleSnackbarClose = () => {
    setSuccess(null);
    setError(null);
  };

  return (
    <Container maxWidth="xl" sx={{ padding: "20px", borderRadius: "8px" }}>
      <Typography variant="h4">
        Edit Quiz
      </Typography>

      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Quiz Category</InputLabel>
              <Select
                value={selectedQuizCategory || ""}
                onChange={(e) => setSelectedQuizCategory(e.target.value)}
                label="Quiz Category"
                required
              >
                {quizCategory.map((category) => (
                  <MenuItem key={category._id} value={category._id}>
                    {category.quiz_category_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Quiz Sub-Category */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Quiz Subcategory</InputLabel>
              <Select
                value={selectedQuizSubCategory || ""}
                onChange={(e) => setSelectedQuizSubCategory(e.target.value)}
                label="Quiz Subcategory"
                required
              >
                {quizSubCategory.map((subCategory) => (
                  <MenuItem key={subCategory._id} value={subCategory._id}>
                    {subCategory.quiz_sub_category_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Quiz Title"
              variant="outlined"
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </Grid>

          {/* Total Score */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Total Score"
              type="number"
              variant="outlined"
              fullWidth
              value={totalScore}
              onChange={(e) => setTotalScore(e.target.value)}
              required
            />
          </Grid>
        </Grid>

        {/* <Typography variant="h6" gutterBottom sx={{ marginTop: "20px" }}>
          Add Questions
        </Typography>

        {questions.map((question, index) => (
          <Box
            key={index}
            sx={{
              border: "1px solid #ccc",
              padding: "16px",
              borderRadius: "8px",
              marginBottom: "16px",
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Box>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleImageChange(index, e)}
                    style={{ display: "none" }}
                    id={`image-upload-${index}`}
                  />
                  <label htmlFor={`image-upload-${index}`}>
                    <Button variant="outlined" component="span">
                      Upload Image
                    </Button>
                  </label>
                  {question.question_image?.imageUrl && (
                    <Box sx={{ marginTop: "10px", textAlign: "center" }}>
                      <img
                        src={question.question_image.imageUrl}
                        alt="Question preview"
                        style={{
                          maxWidth: "100%",
                          maxHeight: "200px",
                          objectFit: "contain",
                          borderRadius: "8px",
                        }}
                      />
                    </Box>
                  )}
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="Question Text"
                  variant="outlined"
                  fullWidth
                  name="question_text"
                  value={question.question_text}
                  onChange={(e) => handleQuestionChange(index, e)}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="Points"
                  type="number"
                  variant="outlined"
                  fullWidth
                  name="points"
                  value={question.points}
                  onChange={(e) => handleQuestionChange(index, e)}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Question Type</InputLabel>
                  <Select
                    name="question_type"
                    value={question.question_type}
                    onChange={(e) => handleQuestionChange(index, e)}
                    label="Question Type"
                  >
                    <MenuItem value="mcq">MCQ</MenuItem>
                    <MenuItem value="yes/no">Yes/No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* MCQ Options */}
              {/* {question.question_type === "mcq" && (
                <Grid item xs={12} sm={6}>
                  {question.options.map((option, optionIndex) => (
                    <Box key={optionIndex} sx={{ marginBottom: "10px" }}>
                      <TextField
                        label={`Option ${optionIndex + 1}`}
                        variant="outlined"
                        fullWidth
                        name={`option_${optionIndex + 1}`}
                        value={option}
                        onChange={(e) => handleQuestionChange(index, e)}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={question.answer === option} // Compare the answer with the option text
                            onChange={() =>
                              handleQuestionChange(index, {
                                target: { name: "answer", value: option },
                              })
                            }
                            name="answer"
                            value={option} // Store the option text instead of index
                          />
                        }
                        label="Correct Answer"
                      />
                    </Box>
                  ))}
                </Grid>
              )} */}

              {/* Yes/No Answer */}
              {/* {question.question_type === "yes/no" && (
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Answer</InputLabel>
                    <Select
                      value={question.answer || ""}
                      onChange={(e) => handleQuestionChange(index, e)}
                      name="answer"
                      label="Answer"
                    >
                      <MenuItem value="Yes">Yes</MenuItem>
                      <MenuItem value="No">No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              )} */}

              {/* <Grid item xs={12} sm={12}>
                <IconButton
                  onClick={() => handleRemoveQuestion(index)}
                  color="error"
                  aria-label="delete"
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        ))} */}

        {/* <Button
          variant="contained"
          color="primary"
          onClick={handleAddQuestion}
          sx={{ marginBottom: "20px" }}
        >
          Add Question
        </Button>  */}

        <Box sx={{ marginBottom: "20px", marginTop: "10px" }}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            sx={{ marginRight: "10px",backgroundColor:"#e0e0e0" }}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "UPDATE"
            )}
          </Button>

          <Button variant="contained" sx={{mx:2, backgroundColor:'#e0e0e0'}} onClick={handleCancel}>
            CANCEL
          </Button>

          
        </Box>
      </form>

      <Snackbar
        open={success !== null || error !== null}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={success ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {success || error}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default EditQuiz;
