import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Container,
  Divider,
  Grid,
  Checkbox,
  useTheme,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Person, LightbulbCircle } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { getToken } from "../../../utils/AuthUtils";

const mcqData1 = [
  {
    id: 1,
    question:
      "What is the Primary function of a solar wind mill in renewable energy system?",
    options: [
      "Directly into electricity",
      "Directly into electricity",
      "Directly into electricity",
      "Directly into electricity",
    ],
    selected: false,
    score: 10,
    total: 10,
  },
  {
    id: 2,
    question:
      "What is the Primary function of a solar wind mill in renewable energy system?",
    options: [
      "Directly into electricity",
      "Directly into electricity",
      "Directly into electricity",
      "Directly into electricity",
    ],
    selected: false,
    score: 10,
    total: 10,
  },
  {
    id: 3,
    question:
      "What is the Primary function of a solar wind mill in renewable energy system?",
    options: [
      "Directly into electricity",
      "Directly into electricity",
      "Directly into electricity",
      "Directly into electricity",
    ],
    selected: false,
    score: 10,
    total: 10,
  },
];
const AssessmentView = () => {
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [assessmentQuestions, setAssessmentQuestions] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAssessmentQuestions = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/quiz/quiz/nopagination/${id}`,
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        const assessmentData = response.data.data || [];
        setAssessmentQuestions(
          Array.isArray(assessmentData) ? assessmentData : []
        );
      } catch (error) {
        console.error("Error fetching assessment questions:", error);
      }
    };
    fetchAssessmentQuestions();
  }, [id]);

  const handleAnswerChange = (questionIndex, value) => {
    setSelectedAnswers({
      ...selectedAnswers,
      [questionIndex]: value,
    });
  };

  const handleRadioChange = (questionId, value) => {
    setSelectedAnswers((prevState) => ({
      ...prevState,
      [questionId]: value,
    }));
  };

  const handleTextChange = (questionId, value) => {
    setSelectedAnswers((prevState) => ({
      ...prevState,
      [questionId]: value,
    }));
  };

  const handleSubmit = async () => {
    // const formattedAnswers = assessmentQuestions.map((question,index) => ({
    //     [index]:selectedAnswers[index] || "", // Only the selected answer or an empty string
    //   }));

    const formattedAnswers = assessmentQuestions.map((question, index) => ({
      id: question._id, // Use the question's _id from assessmentQuestions
      answer: selectedAnswers[index] || "", // Selected answer or empty if unanswered
    }));

    const payload = {
      answers: formattedAnswers,
      assessment_id: id, // Using the id from useParams as assessment_id
    };

    console.log("Payload:", payload);

    let totalPoints = 0;

    // Calculate total points
    // assessmentQuestions.forEach((question, index) => {
    //     if (selectedAnswers[index] === question.answer) {
    //         totalPoints += question.points; // Add points if the answer is correct
    //     }
    // });

    assessmentQuestions.forEach((question, index) => {
      const selectedAnswer = selectedAnswers[index];

      // Calculate points only for "yes/no" and "mcq" types
      if (
        question.question_type === "mcq" ||
        question.question_type === "yes/no"
      ) {
        if (selectedAnswer === question.answer) {
          totalPoints += question.points; // Add points if the answer is correct
        }
      }
      // For "descriptive" questions, points are always 0, so no action needed.
    });

    // console.log("Formatted Answers:", formattedAnswers);
    // console.log("Total Points:", totalPoints);
    console.log("Selected Answers:", selectedAnswers);
    try {
      const token = getToken();
      if (!token) {
        console.log("Unauthorized Access");
        return;
      }
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND}/api/quiz/student/answer`,
        {
          ...payload,
          // total_points: totalPoints, // Adding total_points to the payload if needed
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const assesmentData = response.data.data;
      setAssessmentQuestions(assesmentData);
      // navigate("/student/unit-assessment/submitted", { state: { id } });
      console.log("assesmentData: ", assesmentData);

      // const filteredTeachers = resourcePersonData.filter(
      //   (item) => item.teacher_subCategoryId === newProduct.teacherSubCategory
      // );
      // setTeacherFilter(filteredTeachers);
    } catch (error) {
      console.error("Error fetching teachers:", error);
    }
  };

  return (
    <Container maxWidth="100%" sx={{ paddingRight: "0px" }}>
      <Box
        padding={1}
        display="flex"
        justifyContent="space-between"
        backgroundColor="#e0e0e0"
        mb={2}
        mt={2}
      >
        <Typography variant="h4" component="h1" p={1}>
          Quiz View
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: "#e53935", // Red background
          color: "#ffffff", // White text
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "10px 20px",
          borderRadius: "8px",
          width: "100%",
          margin: "20px auto", // Centers the header
        }}
      >
        <LightbulbCircle
          sx={{
            marginRight: "10px",
            fontSize: { xs: "18px", sm: "20px", md: "120px", lg: "120px" },
          }}
        />
        <Typography
          variant="h6"
          component="span"
          sx={{ fontSize: { xs: "18px", sm: "20px", md: "50px", lg: "50px" } }}
        >
          Knowledge Check
        </Typography>
      </Box>

      <Box
        mt={2}
        sx={{
          padding: "20px",
          backgroundColor: "#fbe9e7",
          borderRadius: "60px",
          border: "1px solid",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: "0 auto",
        }}
      >
        <Container maxWidth="95%" sx={{ padding: "24px !important" }}>
          {Array.isArray(assessmentQuestions) &&
          assessmentQuestions.length > 0 ? (
            assessmentQuestions.map((question, index) => (
              <Box key={index} sx={{ marginBottom: "20px" }}>
                {/* Question Header */}
                <Grid
                  container
                  alignItems="center"
                  spacing={2}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: { xs: "column", sm: "row" },
                    gap: { xs: "10px", sm: "0" },
                  }}
                >
                  {/* Question Number */}
                  <Grid item xs={12} sm={2} md={1}>
                    <Box
                      sx={{
                        backgroundColor: "#fb8c00",
                        color: "#fff",
                        fontWeight: "bold",
                        fontSize: {
                          xs: "16px",
                          sm: "18px",
                          md: "22px",
                          lg: "28px",
                        },
                        borderRadius: "50%",
                        height: { xs: "50px", sm: "60px" },
                        width: { xs: "50px", sm: "60px" },
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mx: "auto", // Centers the box horizontally on small screens
                      }}
                    >
                      {index + 1}
                    </Box>
                  </Grid>

                  {/* Question Text */}
                  <Grid item xs={12} sm={10} md={11}>
                    <Typography
                      variant="h4"
                      sx={{
                        fontSize: {
                          xs: "16px !important",
                          sm: "18px !important",
                          md: "22px !important",
                          lg: "30px !important",
                        },
                        color: "#000 !important",
                        textAlign: { xs: "center", sm: "left" },
                      }}
                    >
                      {question.question_text}
                    </Typography>
                  </Grid>

                  {/* Question Image */}
                  {question?.question_image && (
                    <Grid
                      item
                      xs={12}
                      sx={{
                        textAlign: { xs: "center", md: "center", lg: "left" }, // Responsive alignment
                        my:4,
                      }}
                    >
                      <img
                        src={question?.question_image}
                        alt="Question"
                        style={{
                          width: "100%",
                          maxWidth: "300px",
                          height: "auto",
                          objectFit: "cover",
                        }}
                      />
                    </Grid>
                  )}
                </Grid>

                {/* Question Type Handling */}
                <Grid
                  container
                  justifyContent="center"
                  sx={{ marginTop: "10px" }}
                >
                  <Grid item xs={8}>
                    {question.question_type === "mcq" && (
                      <Grid container spacing={2}>
                        {question.options.map((option, optionIndex) => (
                          <Grid item xs={12} md={6} key={optionIndex}>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Checkbox
                                checked={selectedAnswers[index] === option}
                                onChange={(e) =>
                                  handleAnswerChange(index, option)
                                }
                                sx={{
                                  transform: "scale(1.5)",
                                  "&.Mui-checked": {
                                    color: "#43a047",
                                  },
                                }}
                              />
                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: {
                                    xs: "18px",
                                    sm: "20px",
                                    md: "22px",
                                    lg: "24px",
                                  },
                                  fontWeight: 224,
                                  color:
                                    question.selected === option
                                      ? "#43a047"
                                      : "#000",
                                }}
                              >
                                {option}
                              </Typography>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    )}

                    <Grid item xs={12} display="flex" justifyContent="center">
                      {question.question_type === "yes/no" && (
                        <RadioGroup
                          name={`question-${index}`}
                          value={selectedAnswers[index] || ""}
                          onChange={(e) =>
                            handleAnswerChange(index, e.target.value)
                          }
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "10px",
                          }}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio sx={{ transform: "scale(1.5)" }} />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio sx={{ transform: "scale(1.5)" }} />}
                            label="No"
                          />
                        </RadioGroup>
                      )}
                    </Grid>

                    {question.question_type === "descriptive" && (
                      <textarea
                        style={{
                          width: "100%",
                          border: "1px solid #ccc",
                          borderRadius: "5px",
                          padding: "10px",
                          fontSize: "18px",
                        }}
                        value={selectedAnswers[index] || ""}
                        onChange={(e) =>
                          handleAnswerChange(index, e.target.value)
                        }
                      />
                    )}
                  </Grid>
                </Grid>

                {/* Divider */}
                {index < assessmentQuestions.length - 1 && (
                  <Divider sx={{ margin: "20px 0", borderColor: "#000" }} />
                )}
              </Box>
            ))
          ) : (
            <Typography>No questions available.</Typography>
          )}
          {/* Submit Button */}
          <Box sx={{ mt: 3, textAlign: "center" }}>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Submit Answers
            </Button>
          </Box>
        </Container>
      </Box>
    </Container>
  );
};

export default AssessmentView;
