import React, { useState } from "react";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";

const ImageCarousel = ({ slider }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Check screen size for responsive adjustments
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  // Handle next image click
  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slider.length); // Loop back to first image
  };

  // Handle previous image click
  const handlePrevious = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + slider.length) % slider.length // Loop back to last image
    );
  };

  const currentImage = slider[currentIndex];
  React.useEffect(() => {
    const interval = setInterval(handleNext, 5000); // Change image every 3 seconds
    return () => clearInterval(interval); // Clear interval on component unmount
  }, []);
  

  return (
    <Box sx={{ maxWidth: "100%", position: "relative", height:"auto"}}>
      {/* Clickable Image */}
      <a href={currentImage.redirected_url} target="_blank" rel="noopener noreferrer">
        <Box
          component="img"
          src={currentImage.image_url}
          alt={currentImage.title}
          sx={{
            width: "100%",
            height: isSmallScreen ? "200px" : "auto", // Responsive image height
            objectFit: "cover", // Ensure the image fills the container without distortion
            borderRadius: "18px", // Rounded corners
            height:"440px"
          }}
        />
      </a>

      {/* Previous Button */}
      <Button
        onClick={handlePrevious}
        sx={{
          position: "absolute",
          top: "50%",
          left: "10px",
          transform: "translateY(-50%)",
          zIndex: 1,
        //   backgroundColor: "rgba(0, 0, 0, 0.5)",
          color: "#fff !important",
        }}
      >
        &lt;
      </Button>

      {/* Next Button */}
      <Button
        onClick={handleNext}
        sx={{
          position: "absolute",
          top: "50%",
          right: "10px",
          transform: "translateY(-50%)",
          zIndex: 1,
        //   backgroundColor: "rgba(0, 0, 0, 0.5)",
          color: "#fff !important",
        }}
      >
        &gt;
      </Button>

      {/* Optional Image Caption */}
      {/* <Box
        sx={{
          position: "absolute",
          bottom: "10px",
          left: "50%",
          transform: "translateX(-50%)",
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          color: "white",
          padding: "5px 15px",
          borderRadius: "5px",
        }}
      >
        <Typography>{currentImage.title}</Typography>
      </Box> */}
    </Box>
  );
};

export default ImageCarousel;
