import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Box,
  Collapse,
  Typography,
  Icon,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
// course
import BookIcon from '@mui/icons-material/Book';
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
// project
import WorkIcon from '@mui/icons-material/Work';
import TaskIcon from '@mui/icons-material/Task';
// teacher comment
import CommentIcon from '@mui/icons-material/Comment';
import ChatIcon from '@mui/icons-material/Chat';


import LogoutIcon from "@mui/icons-material/Logout";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SchoolIcon from "@mui/icons-material/School";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import logoFull from "../assets/Logofull.png";
import logoIcon from "../assets/logoicon.png";
import Logout from "../assets/Robo.jpg";
import { Link, Outlet, useNavigate } from "react-router-dom";
import CategoryIcon from "@mui/icons-material/Category";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import HandshakeIcon from "@mui/icons-material/Handshake";
import BusinessIcon from "@mui/icons-material/Business";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import PersonIcon from "@mui/icons-material/Person"; // For Teachers
import AssignmentIcon from "@mui/icons-material/Assignment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PanToolOutlinedIcon from "@mui/icons-material/PanToolOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { FaListAlt } from "react-icons/fa";
import InventoryIcon from "@mui/icons-material/Inventory";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import axios from "axios";
// import { getToken } from "../../../utils/AuthUtils";
import "./Layout.css";
import { getRole, removeToken, getDecodedToken } from "../utils/AuthUtils";
import {
  Apartment,
  ApartmentOutlined,
  AssessmentOutlined,
  CheckCircle,
  BookOnline,
  BookRounded,
  Class,
  ClassOutlined,
  Edit,
  GroupOutlined,
  ListAltOutlined,
  MenuBookOutlined,
  Quiz,
} from "@mui/icons-material";
import LockIcon from "@mui/icons-material/Lock";
import { color } from "@mui/system";
import { jwtDecode } from "jwt-decode";
import EditIcon from "@mui/icons-material/Edit";

const drawerWidth = 300;
const collapsedWidth = 65;
const collapseScreenWidth = 1500;

const buttonPaths = ["/school/course-list", "/ProjectPage", "/list"];
const buttonLabels = [
  "Course",
  "Project",
  "Assessment",
  "Quiz",
  "Teacher Comments",
  // "Reports",
  // "Tickets",
  // "Task",
];
const Layout = () => {
  const [role, setRole] = useState("");
  const [loading, setLoading] = useState(true);
  const [permissions, setPermissions] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [collapsed, setCollapsed] = useState(false);
  const [openMenus, setOpenMenus] = useState({});
  const [user, setUser] = useState(null); // State to hold user information
  const [userdata, setData] = useState(null); // To store fetched data
  const [schoolData, setSchoolData] = useState(null);
  const [userName, setUserName] = useState(""); // Add state for user name
  const [profilePicture, setProfilePicture] = useState(""); // Add state for profile picture
  const [expanded, setExpanded] = useState(false); // Accordion state
  const navigate = useNavigate();

  const token = localStorage.getItem("token"); // Assuming the token is stored in localStorage

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const decoded = getDecodedToken();
        const token = localStorage.getItem('token_key');
        const response = await axios.get(`${process.env.REACT_APP_ADMIN_BACKEND}/api/rolemodules/byroleformenu/${decoded.role_id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setPermissions(response.data.data);
        console.log("permissions-------", permissions);
      } catch (error) {
        console.error("Error fetching permissions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPermissions();
  }, []);

  const handleResetPassword = () => {
    navigate("/resetpassword"); // Adjust the path as necessary
  };
  // Check for token on mount and redirect if not present
  useEffect(() => {
    const token = localStorage.getItem("token_key"); // Replace 'authToken' with your actual token key

    // const token = getToken();
    if (!token) {
      navigate("/login");
    } else {
      try {
        const decoded = jwtDecode(token); // Decode the token
        console.log(decoded); // Log the decoded token to the console

        setUser(decoded); // Set user information in state
        setUserName(decoded.name);
        // Fetch data based on role_name
        const id = decoded.reg_id; // Extract reg_id from the decoded token

        if (decoded.role_name === "student") {
          fetchStudentData(id); // Call the student API
        } else if (decoded.role_name === "teacher") {
          fetchTeacherData(id); // Call the student API
        } else {
          const userData = decoded; // Set other userdata from decoded token

          fetchSchoolData(id);
        }
        //     else{
        //   setData(response.data.data);
        //  fetchSchoolData(userdata.school_id);
        //     }
      } catch (error) {
        console.error("Token decoding failed", error);
        navigate("/login"); // Redirect if decoding fails
      }
    }
  }, [token, navigate]);

  // useEffect to fetch school data once userdata is available
  useEffect(() => {
    if (userdata && userdata.school_id) {
      fetchSchoolData(userdata.school_id);
    }
  }, [userdata]);
  // Handle screen resize to collapse sidebar when width is below 1502px
  const fetchStudentData = async (id) => {
    try {
      const token = localStorage.getItem("token_key"); // Replace 'authToken' with your actual token key

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/student/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setData(response.data.data);
      // console.log("GGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGG",data)
      fetchSchoolData(userdata.school_id);
    } catch (error) {
      console.error("Error fetching student data", error);
    }
  };
  const fetchTeacherData = async (id) => {
    try {
      const token = localStorage.getItem("token_key"); // Replace 'authToken' with your actual token key

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/teacher/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setData(response.data.data);
      fetchSchoolData(userdata.school_id);
    } catch (error) {
      console.error("Error fetching teacher data", error);
    }
  };
  const fetchSchoolData = async (schoolId) => {
    try {
      const token = localStorage.getItem("token_key"); // Replace 'authToken' with your actual token key

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/school/get-school-byId/${schoolId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setSchoolData(response.data.data);
      console("cvxzvbnxcxbv", schoolData);
    } catch (error) {
      console.error("Error fetching teacher data", error);
    }
  };
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < collapseScreenWidth) {
        setCollapsed(true);
      } else {
        setCollapsed(false);
      }
    };

    window.addEventListener("resize", handleResize);

    // Initial check on mount
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // Simulate fetching user details
    const fetchUserDetails = async () => {
      // Replace this with actual API call or logic to fetch user details
      const user = {
        picture: "https://via.placeholder.com/150", // Replace with real picture URL
      };
      setProfilePicture(user.picture);
    };

    fetchUserDetails();

    const role = getRole();
    setRole(role);

    const getMenuItemsByRole = (role) => {
      switch (role) {
        case "school":
          return [
            { text: "Dashboard", route: "school", icon: <DashboardIcon /> },
            // { text: "Courses", route: "/coursesTable", icon: <MenuBookIcon /> },
            {
              text: "Academic",
              icon: <SchoolOutlinedIcon />,
              subItems: [
                ...(permissions.includes("4-grade_division-1") ? [{
                  text: "Grade Division",
                  path: "school/grade-division",
                  icon: <AccountTreeIcon />,
                }] : [{}]),
                ...(permissions.includes("4-assigned_department-1") ? [{
                  text: "Assigned Departments",
                  path: "school/assign-department/list",
                  icon: <ApartmentOutlined />,
                }] : [{}]),
                // {
                //   text: "Grade Division",
                //   path: "school/grade-division",
                //   icon: <AccountTreeIcon />,
                // },
                // {
                //   text: "Assigned Departments",
                //   path: "school/assign-department/list",
                //   icon: <ApartmentOutlined />,
                // },
                // {
                //   text: "Courses",
                //   Icon: <MenuBookOutlined />,
                //   path: "school/course-list",
                // },
                // {
                //   text: "Departments",
                //   Icon: <BusinessIcon />,
                //   path: "school/departments",
                // },
                ...(permissions.includes("4-assigned_courses_list-1") ? [{
                  text: "Assigned Courses List",
                  path: "school/assigned-courses-list",
                  icon: <SchoolIcon />,
                }] : [{}]),...(permissions.includes("4-assigned_product_list-1") ? [{
                  text: "Assigned Product List",
                  path: "school/assigned-product-list",
                  icon: <InventoryIcon />,
                }] : [{}]),
                // {
                //   text: "Assigned Courses List",
                //   path: "school/assigned-courses-list",
                //   icon: <SchoolIcon />,
                // },
                // {
                //   text: "Assigned Product List",
                //   path: "school/assigned-product-list",
                //   icon: <InventoryIcon />,
                // },
                // {
                //   text: "Chapters",
                //   Icon: <MenuBookIcon />,
                //   path: "school/chapters",

                // },
              ],
            },
            // {
            //   text: "Teachers",
            //   icon: <PersonIcon />,
            //   subItems: [
            //     {
            //       text: "List",
            //       path: "school/teachers",
            //       icon: <VisibilityIcon />,
            //     },http://localhost:5000/parents
            //     // {
            //     //   text: "Add Teacher", path: "school/add-teacher",
            //     //   icon: <AddCircleOutlinedIcon />,
            //     // },

            //   ],
            // },
            {
              ...(permissions.includes("4-teacher-1") ? {
                text: "Teachers",
                route: "school/teachers",
                icon: <PersonIcon />,
              } : {})
            },
            {
              ...(permissions.includes("4-student-1") ? {
                text: "Students", 
                route: "students", 
                icon: <EmojiPeopleIcon />
              } : {})
            },
            {
              ...(permissions.includes("4-parent-1") ? {
                text: "Parents",
                route: "parents",
                icon: <SupervisedUserCircleIcon />,
              } : {})
            },
            {
              ...(permissions.includes("4-student_bulk_upload-1") ? {
                text: "Student Bulk Upload",
                route: "school/student-data-bulk-upload",
                icon: <SupervisedUserCircleIcon />,
              } : {})
            },
            // {
            //   text: "Teachers",
            //   route: "school/teachers",
            //   icon: <PersonIcon />,
            // },
            // { text: "Students", route: "students", icon: <EmojiPeopleIcon /> },
            // {
            //   text: "Parents",
            //   route: "parents",
            //   icon: <SupervisedUserCircleIcon />,
            // },
            // {
            //   text: "Student Bulk Upload",
            //   route: "school/student-data-bulk-upload",
            //   icon: <SupervisedUserCircleIcon />,
            // },
          ];
        case "teacher":
          return [
            {
              text: "Dashboard",
              route: "teacher",
              icon: <DashboardIcon />,
            },
            {
              ...(permissions.includes("5-student_list-1") ? {
                text: "Students",
                route: "teacher/student-list",
                icon: <EmojiPeopleIcon />,
              } : {})
            },
            {
              ...(permissions.includes("5-courses_list-1") ? {
                text: "Courses",
                route: "teacher/my-courses",
                icon: <MenuBookIcon />,
              } : {})
            },
            {
              ...(permissions.includes("5-comments-1") ? {
                text: "Comments",
                route: "teacher/comments/allstudents",
                icon: <ChatIcon />,
              } : {})
            },
            // {
            //   text: "Students",
            //   route: "teacher/student-list",
            //   icon: <EmojiPeopleIcon />,
            // },
            // {
            //   text: "Courses",
            //   route: "teacher/my-courses",
            //   icon: <MenuBookIcon />,
            // },
            // {
            //   text: "Comments",
            //   route: "teacher/comments/allstudents",
            //   icon: <ChatIcon />,
            // },
            {
              text: "Report",
              icon: <AssignmentIcon />,
              subItems: [
                ...(permissions.includes("5-report_unit_assessment-1") ? [{
                  text: "Unit Assesment",
                  path: "teacher/unit-assessment-report",
                  icon: <CheckCircle />,
                }] : [{}]),
                ...(permissions.includes("5-report_quiz_report-1") ? [{
                  text: "Quiz Report",
                  path: "teacher/quiz-report",
                  icon: <CheckCircle />,
                }] : [{}]),
                // {
                //   text: "Unit Assesment",
                //   path: "teacher/unit-assessment-report",
                //   icon: <CheckCircle />,
                // },
                // {
                //   text: "Quiz Report",
                //   path: "teacher/quiz-report",
                //   icon: <CheckCircle />,
                // },

                // { text: "Chapter Assesment", path: "teacher/gradeassigned" },
                // { text: "Term Assesment", path: "teacher/gradeassigned" },
                // { text: "Participation", path: "teacher/gradeassigned" },
              ],
            },
            {
              ...(permissions.includes("5-project-1") ? {
                text: "Projects",
                route: "teacher/ProjectPage",
                icon: <AssessmentOutlined />,
              } : {})
            },
            {
              ...(permissions.includes("5-quiz-1") ? {
                text: "Quiz",
                route: "teacher/quiz-list",
                icon: <Quiz />,
              } : {})
            },
            // {
            //   text: "Projects",
            //   route: "teacher/ProjectPage",
            //   icon: <AssessmentOutlined />,
            // },
            // {
            //   text: "Quiz",
            //   route: "teacher/quiz-list",
            //   icon: <Quiz />,
            // },
          ];
        case "student":
          return [
            // { text: "Content", path: "/student/ongoing_courses" },
            {
              text: "Dashboard",
              route: "student",
              icon: <DashboardIcon />,
            },
            {
              ...(permissions.includes("6-courses-1") ? {
                text: "Courses",
                route: "student/ongoing_courses",
                icon: <MenuBookIcon />,
              } : {})
            },
            {
              ...(permissions.includes("6-project_list-1") ? {
                text: "Projects",
                route: "student/project-page",
                icon: <TaskIcon />,
              } : {})
            },
            // {
            //   text: "Courses",
            //   route: "student/ongoing_courses",
            //   icon: <MenuBookIcon />,
            // },
            // {
            //   text: "Projects",
            //   route: "student/project-page",
            //   icon: <TaskIcon />,
            // },
            // {
            //   text: "Assignment",
            //   route: "student/assignment_sub",
            //   icon: <MenuBookIcon />,
            // },
            {
              ...(permissions.includes("6-teachers_comments-1") ? {
                text: "Teachers Comment",
              route: "student/teachercomment",
              icon: <ChatIcon />,
              } : {})
            },
            // {
            //   text: "Teachers Comment",
            //   route: "student/teachercomment",
            //   icon: <ChatIcon />,
            // },
            {
              text: "Report",
              icon: <AssignmentIcon />,
              subItems: [
                ...(permissions.includes("6-unit_assessment_report-1") ? [{
                  text: "Unit Assesment",
                  path: "student/unit-assessment-List",
                }] : [{}]),
                // {
                //   text: "Unit Assesment",
                //   path: "student/unit-assessment-List",
                // },
                // { text: "Chapter Assesment", path: "student/gradeassigned" },
                // { text: "Participation", path: "student/gradeassigned" },
              ],
            },
          ];

        default:
          return [
            // { text: "Dashboard", route: "/dashboard", icon: <DashboardIcon /> },
          ];
      }
    };

    setMenuItems(getMenuItemsByRole(role));
  }, [permissions]);

  const handleToggle = () => {
    setCollapsed(!collapsed);
  };
  // const handleResetPassword = () => {
  //   navigate("/resetpassword"); // Adjust the path as necessary
  // };

  const toggleAccordion = () => {
    setExpanded(!expanded);
  };

  const handleLogout = () => {
    localStorage.removeItem("token_key");
    navigate("/");
  };

  const handleMenuClick = (menu) => {
    if (collapsed) {
      setCollapsed(false);
    }
    setOpenMenus((prev) => ({
      ...prev,
      [menu]: !prev[menu],
    }));
  };

  const renderSubItems = (subItems = [], level = 0) => {
    return subItems.map((subItem, index) =>
      subItem.subItems ? (
        <React.Fragment key={index}>
          <ListItem button onClick={() => handleMenuClick(subItem.text)}>
            <ListItemIcon sx={{ color: "black" }}>{subItem.icon}</ListItemIcon>
            <ListItemText primary={subItem.text} />
            <ExpandMoreIcon />
          </ListItem>
          <Collapse in={openMenus[subItem.text]} timeout="auto" unmountOnExit>
            <List sx={{ pl: level * 4 }}>
              {renderSubItems(subItem.subItems, level + 1)}
            </List>
          </Collapse>
        </React.Fragment>
      ) : (
        <ListItem
          button
          key={index}
          sx={{ marginLeft: "30px" }}
          component={Link}
          to={subItem.path}
        >
          <ListItemIcon sx={{ color: "black" }}>{subItem.icon}</ListItemIcon>
          <ListItemText primary={subItem.text} />
        </ListItem>
      )
    );
  };
  return (
    <div style={{ display: "flex" }}>
      <AppBar
        position="fixed"
        style={{
          backgroundColor: "#fff !important",
          color: "#0000066",
          boxShadow: "none",
          width: `calc(100% - ${collapsed ? collapsedWidth : drawerWidth}px)`,
        }}
      >
        {/* Welcome Note */}
        {/* {role === "school" && ( */}
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            edge="start"
            aria-label="menu"
            onClick={handleToggle}
            sx={{ fontSize: "13px", backgroundColor: "#fff!important" }}
          >
            <MenuIcon sx={{ color: "black" }} />
          </IconButton>

          <Box
            sx={{
              textAlign: "right",
              paddingRight: "22px",
              fontWeight: "bold",
              color: "#d1d1d1",
              marginTop: "0px !important",
              lineHeight: "0px !important",
            }}
          >
            {user ? (
              <h6
                sx={{
                  margin: "0px !important",
                }}
              >
                Welcome, {userName}!
              </h6> // Render user-specific content
            ) : (
              <p>Loading...</p> // Optional loading state
            )}
          </Box>
          {role === "school" && (
            <Box
              className="top-bar-container"
              sx={{
                flexGrow: 1,
                overflowX: "auto",
                whiteSpace: "nowrap",
                display: "block",
                textAlign: "center",
              }}
            >
              {buttonLabels.map((text, index) => (
                <Button
                  key={text}
                  component={Link}
                  to={buttonPaths[index]}
                  sx={{
                    minWidth: "120px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    backgroundColor: "#fff",
                    fontSize: "13px",
                    marginRight: "10px",
                    padding: "5px 10px",
                    color: "#00000066 !important",
                  }}
                >
                  <PlayArrowIcon
                    sx={{ marginRight: "3px", fontSize: "18px !important" }}
                  />
                  {text}
                </Button>
              ))}
            </Box>
          )}
        </Toolbar>
        {/* )} */}
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: collapsed ? collapsedWidth : drawerWidth,

          flexShrink: 0,

          "& .MuiDrawer-paper": {
            width: collapsed ? collapsedWidth : drawerWidth,

            backgroundColor: "#f1f0f0",

            overflowX: "hidden",

            display: "flex",

            flexDirection: "column",
          },
        }}
      >
        {/* Logo Section */}
        <Box sx={{ textAlign: "center", padding: collapsed ? 1 : 2 }}>
          <Link to="/dashboard">
            <img
              src={
                schoolData?.school_logo ||
                "https://blr1.vultrobjects.com/nanoskool/1727801083707-7ca04a89-4afc-47e4-ac25-b5527794b4ea.jpg"
              }
              alt="Logo"
              style={{ width: collapsed ? "100%" : "65%" }}
            />
          </Link>
        </Box>

        {/* Menu Section */}
        <Box sx={{ flexGrow: 1, overflowY: "auto" }}>
          <List>
            {menuItems.map(({ text, route, icon, subItems }) => (
              <React.Fragment key={text}>
                {subItems ? (
                  <>
                    <ListItem
                      button
                      onClick={() => {
                        if (collapsed) setCollapsed(false); // Expand sidebar on click

                        handleMenuClick(text);
                      }}
                    >
                      <ListItemIcon
                        sx={{ color: "black", fontSize: "18px !important" }}
                      >
                        {icon}
                      </ListItemIcon>

                      {!collapsed && (
                        <ListItemText
                          primary={text}
                          sx={{
                            opacity: 1,

                            transition: "opacity 0.3s",

                            fontSize: "13px",
                          }}
                        />
                      )}

                      {!collapsed && (
                        <ExpandMoreIcon sx={{ fontSize: "18px !important" }} />
                      )}
                    </ListItem>
                    <Collapse
                      in={!collapsed && openMenus[text]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List sx={{ pl: collapsed ? 0 : 4 }}>
                        {renderSubItems(subItems)}
                      </List>
                    </Collapse>
                  </>
                ) : (
                  <ListItem
                    button
                    component={Link}
                    to={route}
                    onClick={() => collapsed && setCollapsed(false)}
                  >
                    <ListItemIcon
                      sx={{ color: "black", fontSize: "18px !important" }}
                    >
                      {icon}
                    </ListItemIcon>

                    {!collapsed && (
                      <ListItemText primary={text} sx={{ fontSize: "13px" }} />
                    )}
                  </ListItem>
                )}
              </React.Fragment>
            ))}
          </List>
        </Box>

        {/* Profile Section with Accordion */}
          <Accordion
            expanded={!collapsed && expanded}
            onChange={() => {
              if (collapsed) setCollapsed(false); // Expand sidebar on click

              toggleAccordion();
            }}
            sx={{
              boxShadow: "none",

              "&::before": { display: "none" },

              borderRadius: "8px",

              width: "100%",
            }}
          >
            <AccordionSummary
              expandIcon={!collapsed && <ExpandMoreIcon />}
              sx={{
                display: "flex",

                alignItems: "center",

                padding: "0 16px",
              }}
            >
              <Avatar
                src={profilePicture}
                alt="Profile Picture"
                sx={{ width: 40, height: 40, marginRight: collapsed ? 0 : 2 }}
              />

              {!collapsed && (
                <Typography sx={{ flexGrow: 1 }}>{userName}</Typography>
              )}
            </AccordionSummary>

            {!collapsed && (
              <AccordionDetails>
                <List>
                  <ListItem button onClick={() => navigate("/editprofile")}>
                    <ListItemIcon>
                      <EditIcon />
                    </ListItemIcon>
                    <ListItemText primary="Edit Profile" />
                  </ListItem>
                  <ListItem button onClick={handleResetPassword}>
                    <ListItemIcon>
                      <LockIcon />
                    </ListItemIcon>
                    <ListItemText primary="Reset Password" />
                  </ListItem>
                  <ListItem button onClick={handleLogout}>
                    <ListItemIcon>
                      <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                  </ListItem>
                </List>
              </AccordionDetails>
            )}
          </Accordion>
      </Drawer>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          px: { xs: 0, md: 3.5 },
          py: 3,
          mt: 8,
          width: {
            xs: "75%",
            md: "75%",
          },
        }}
      >
        <Outlet />
      </Box>
    </div>
  );
};

export default Layout;
