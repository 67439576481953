import React, { useState } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  Typography,
  Container,
  Paper,
  Box,
} from "@mui/material";
import { getToken } from "../utils/AuthUtils";
import { useNavigate } from "react-router-dom";

const ResetPassword = ({ onSuccess }) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleResetPassword = async () => {
    setErrorMessage(""); // Clear error message on each attempt
    if (newPassword !== confirmPassword) {
      setErrorMessage("New password and confirm password do not match.");
      return;
    }

    try {
      const token = getToken();
      await axios.put(
        `${process.env.REACT_APP_BACKEND}/api/password-reset`,
        {
          oldPassword,
          password: newPassword,
          confirmPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      onSuccess(); // Callback to notify parent component
    } catch (error) {
      if (error.response) {
        setErrorMessage(
          error.response.data.message || "Failed to reset password."
        );
      } else {
        setErrorMessage("Internal server error.");
      }
    }
  };

  const handleCancel = () => {
    navigate(-1); // Navigate to the desired route on cancel
  };

  return (
    <Container
      maxWidth="xl"
      sx={{
        padding: "20px",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "flex-start", // Aligns the container to the left
      }}
    >
      <Box
        sx={{
          padding: "20px",
          maxWidth: "700px",
          marginTop: "32px",
          border: "1px solid #ccc",
          borderRadius: "8px",
        }}
      >
        <Typography variant="h5" align="left" sx={{ mb: 2 }}>
          Reset Password
        </Typography>
        {errorMessage && (
          <Typography variant="body2" color="error" sx={{ mb: 2 }}>
            {errorMessage}
          </Typography>
        )}
        <Box component="form" noValidate>
          <TextField
            margin="normal"
            required
            fullWidth
            type="password"
            label="Old Password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            type="password"
            label="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            type="password"
            label="Confirm New Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <Box display="flex" justifyContent="center">
            <Button
              onClick={handleResetPassword}
              color="primary"
              variant="contained"
              sx={{ m: 2, backgroundColor: "#e0e0e0" }}
            >
              Reset Password
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCancel}
              sx={{
                m: 2,
                backgroundColor: "#e0e0e0",
                color: "#000",
                "&:hover": { backgroundColor: "#d6d6d6" },
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default ResetPassword;
