import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Button,
  Snackbar,
  Alert,
  TextField,
  Box,
  TablePagination,
  Switch,
  Grid,
  Link,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  Container,
  Typography,
  InputAdornment,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SearchIcon from "@mui/icons-material/Search";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { width } from "@mui/system";
import { getToken } from "../../../utils/AuthUtils";
import axios from "axios";

const QuizReport = () => {
  const [quizes, setQuizes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const [serialNumbers, setSerialNumbers] = useState([]);
  const [totalItems, setTotalItems] = useState([]);
  const [courses, setCourses] = useState([]);
  const [grades, setGrades] = useState([]);
  const [students, setStudents] = useState([]);
  const [forFilter, setForFilter] = useState({
    course: "",
    grade: "",
    gradeDivision: "",
    studentId: "",
  });
  const [quizCategory, setQuizCategory] = useState([]);
  const [selectedQuizCategory, setSelectedQuizCategory] = useState(null);
  const [quizSubCategory, setQuizSubCategory] = useState([]);
  const [selectedQuizSubCategory, setSelectedQuizSubCategory] = useState(null);

  // New state variable for status toggle
  const [statusToggle, setStatusToggle] = useState(false);

  const [canCreate, setCanCreate] = useState(false);  
const [canView, setCanView] = useState(false);  
const [canUpdate, setCanUpdate] = useState(false);  
const [canDelete, setCanDelete] = useState(false);  


useEffect(() => {
  const fetchPermissions = async () => {
    const token = localStorage.getItem('token_key');
    
    if (token) {
      try {
        const roleResponse = await axios.get(
          `${process.env.REACT_APP_ADMIN_BACKEND}/api/rolespermission/permissions/22`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        
        const rolePermissions = roleResponse.data.data;
        
        // Check role permissions or set the role
        if (rolePermissions ) {
          // navigate("/unauthorized");
          // console.log("You are not authorized to create a school",rolePermissions);
          setCanCreate(rolePermissions.create);
          setCanView(rolePermissions.view);
          setCanUpdate(rolePermissions.update);
          setCanDelete(rolePermissions.delete);
        }
      } catch (error) {
        console.error("Error fetching role permissions:", error);
        // navigate("/unauthorized"); // Redirect in case of error
      }
    }
  };

  fetchPermissions();
}, []);


  useEffect(() => {
    fetchQuiz();
    // fetchUnitAssessmentbyStudent();
  }, [page, rowsPerPage, searchTerm, forFilter.course]);

  

  const fetchQuiz = async () => {
    try {
      const token = getToken();
      if (!token) {
        console.log("Unauthorized user");
        return;
      }
      const response = await fetch(
        `${
          process.env.REACT_APP_BACKEND
        }/api/quiz/assessmentreport?page=${
          page + 1
        }&limit=${rowsPerPage}&search=${searchTerm}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (data?.data) {
        const assessmentWithSerial = data.data.map((assessment, index) => ({
          ...assessment,
          serial: page * rowsPerPage + index + 1,
          statusToggle: !assessment.status, // Add this line
        }));
        setQuizes(assessmentWithSerial);
        setSerialNumbers(assessmentWithSerial.map((t) => t.serial));
        setTotalItems(data.pagination.totalItems); // Set totalItems for pagination
      } else {
        setError("No teachers found");
      }
    } catch (err) {
      setError("Failed to fetch teachers");
    } finally {
      setLoading(false);
    }
  };

  // Fetch quiz categories
  useEffect(() => {
    const fetchQuizCategory = async () => {
      try {
        const token = getToken();
        if (!token) {
          console.log("Unauthorized user");
          return;
        }
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND}/api/quiz/category`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        if (response.ok) {
          setQuizCategory(data.data || []);
        }
      } catch (error) {
        console.error("Error fetching quiz categories:", error);
      }
    };
    fetchQuizCategory();
  }, []);

  // Fetch quiz sub-categories based on selected quiz category
  useEffect(() => {
    const fetchQuizSubCategory = async () => {
      if (!selectedQuizCategory) return;
      try {
        const token = getToken();
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND}/api/quiz/subcategory/${selectedQuizCategory._id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        if (response.ok) {
          setQuizSubCategory(data.data || []);
        }

        // fetch table
        const response1 = await fetch(
          `${process.env.REACT_APP_BACKEND}/api/quiz/assessmentreportbycatid/${selectedQuizCategory._id}?page=${
          page + 1
        }&limit=${rowsPerPage}&search=${searchTerm}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data1 = await response1.json();
        if (data1?.data) {
          const quizzesWithSerial = data1.data.map((quiz, index) => ({
            ...quiz,
            serial: page * rowsPerPage + index + 1,
          }));
          setQuizes(quizzesWithSerial);
          console.log("quiz reports::", quizzesWithSerial);
          
          setTotalItems(data1.pagination.totalItems);
        } else {
          setError("No quizzes found");
        }
      } catch (error) {
        console.error("Error fetching quiz list:", error);
      }
    };
    fetchQuizSubCategory();
  }, [selectedQuizCategory]);


  useEffect(() => {
    fetchQuizListBySubCatId();
  }, [selectedQuizSubCategory]);

  const fetchQuizListBySubCatId = async () => {
    try {
      const token = getToken();

      // fetch table
      const response1 = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/quiz/assessmentreportbysubcatid/${selectedQuizCategory._id}/${selectedQuizSubCategory._id}?page=${
          page + 1
        }&limit=${rowsPerPage}&search=${searchTerm}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data1 = await response1.json();
      if (data1?.data) {
        const quizzesWithSerial = data1.data.map((quiz, index) => ({
          ...quiz,
          serial: page * rowsPerPage + index + 1,
        }));
        setQuizes(quizzesWithSerial);
        setTotalItems(data1.pagination.totalItems);
      } else {
        setError("No quizzes found");
      }
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

 





  const deleteTeacher = async (id) => {
    try {
      await fetch(`${process.env.REACT_APP_BACKEND}/api/teacher/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      setSnackbarMessage("Teacher deleted successfully");
      setOpenSnackbar(true);
      fetchQuiz();
    } catch (err) {
      setError("Failed to delete teacher");
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleSearch = (event) => {
    setForFilter({ studentId: "" });
    setSearchTerm(event.target.value);
    setPage(0); // Reset to first page on search
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page on rows per page change
  };

  const handleStatusToggle = (id, currentStatus) => {
    const newStatus = !currentStatus;
    updateTeacherStatus(id, newStatus); // Update parent status in the backend
  };
  const updateTeacherStatus = async (id, status) => {
    try {
      await fetch(
        `${process.env.REACT_APP_BACKEND}/api/teacher/${id}/status_teacher`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify({ status }),
        }
      );
      setSnackbarMessage("Teacher status updated successfully");
      setOpenSnackbar(true);
      fetchQuiz(); // Refresh the table after status change
    } catch (err) {
      setError("Failed to update Teacher status");
    }
  };

  const moveToAddTeacher = () => {
    navigate("/add-teacher");
  };

  return (
    <Container maxWidth="100%">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ backgroundColor: "#e0e0e0" }}
        p={1}
        mt={2}
        mb={2}
      >
        <Typography variant="h4">Quiz Report</Typography>
        <TextField
          // label="Search"
          variant="outlined"
          size="small"
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
          sx={{
            width: "300px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#00000030",
              },
              "&:hover fieldset": {
                borderColor: "#00000070",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#00000040",
              },
            },
            "& .MuiInputLabel-root": {
              display: "flex",
              alignItems: "center",
              transition: "all 0.3s ease",
            },
            "& .MuiInputLabel-shrink": {
              display: "flex",
              alignItems: "center",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      {/* <Button variant="contained" color="primary" onClick={() => navigate('/teacher/add-teacher')}>
        Add Teacher
      </Button> */}
      <Grid container spacing={1} mb={2} alignItems="flex-start">
        <Grid item xs={12} md={3}>
        <Autocomplete
            size="small"
            options={quizCategory.filter(
              (value, index, self) =>
                index ===
                self.findIndex((t) => t?.quiz_category_name === value?.quiz_category_name)
            )}
            getOptionLabel={(option) => option.quiz_category_name || "N/A"}
            value={selectedQuizCategory || null}
            onChange={(e, newValue) => {
              if(newValue){
                setSelectedQuizCategory(newValue._id)
                setSelectedQuizSubCategory("")
              }
            else{
              setSelectedQuizCategory("")
              setSelectedQuizSubCategory("")
              fetchQuiz();
            }}}
            renderInput={(params) => (
              <TextField {...params} label="Select quiz category" />
            )}
            sx={{ width: "100%" }}
          />
        </Grid>

        <Grid item xs={12} md={3}>
       {selectedQuizCategory&&
        <Autocomplete
            size="small"
            options={quizSubCategory.filter(
              (value, index, self) =>
                index ===
                self.findIndex((t) => t?.quiz_sub_category_name === value?.quiz_sub_category_name)
            )}
            getOptionLabel={(option) => option.quiz_sub_category_name || "N/A"}
            value={selectedQuizSubCategory || null}
            onChange={(e, newValue) => {
              if(newValue){
                setSelectedQuizSubCategory(newValue)
              }else{
                setSelectedQuizSubCategory("")
              }}}
            renderInput={(params) => (
              <TextField {...params} label="Select quiz sub category" />
            )}
            sx={{ width: "100%" }}
          />}
        </Grid>
        {/* <Grid item xs={12} md={3}>
          <FormControl
            margin="normal"
            fullWidth
          >
            <TextField
              select
              value={forFilter.studentId}
              size="small"
              onChange={(e) =>
                setForFilter(() => ({
                  ...forFilter,
                  studentId: e.target.value,
                }))
              }
              label="Student"
              aria-required="true"
            >
              {students.map((student) => (
                <MenuItem key={student?._id} value={student?._id}>
                  {student?.name}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid> */}
      </Grid>
      <TableContainer component={Paper}>
      {canView === 1 ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>No.</TableCell>
              <TableCell>Student Name</TableCell>
              <TableCell>Grade</TableCell>
              <TableCell>Quiz Category</TableCell>
              <TableCell>Quiz Subcategory</TableCell>
              <TableCell>Quiz Title</TableCell>
              <TableCell>Score</TableCell>
              <TableCell>Class Average</TableCell>
              {/* <TableCell>
                Participation
              </TableCell> */}
              {/* <TableCell>
                Comment
              </TableCell> */}
              <TableCell>Date</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {quizes.length > 0 ? (
              quizes.map((assessment) => {
                // Ensure assessment.answers is an array before proceeding
                const descriptiveScore =
                  assessment?.answers && Array.isArray(assessment?.answers)
                    ? assessment?.answers
                        .filter(
                          (answer) =>
                            answer?.question_id &&
                            answer?.question_id?.question_type === "descriptive"
                        )
                        .reduce((acc, answer) => acc + (answer?.points || 0), 0) // Ensure points exist
                    : 0;

                const notdescriptiveScore =
                  assessment?.answers && Array.isArray(assessment?.answers)
                    ? assessment?.answers
                        .filter(
                          (answer) =>
                            answer?.question_id &&
                            answer?.question_id?.question_type != "descriptive"
                        )
                        .reduce((acc, answer) => acc + (answer?.points || 0), 0) // Ensure points exist
                    : 0;

                return (
                  <TableRow key={assessment?._id}>
                    <TableCell>{assessment?.serial}</TableCell>
                    <TableCell>{assessment?.studentDetails?.name}</TableCell>
                    <TableCell>
                      {assessment?.studentDetails?.grade_division}
                    </TableCell>
                    <TableCell>{assessment?.quizCategoryDetails?.quiz_category_name}</TableCell>
                    <TableCell>{assessment?.quizSubCategoryDetails?.quiz_sub_category_name}</TableCell>
                    <TableCell>
                      {assessment?.quizDetails?.quiz_title}
                    </TableCell>
                    {/* Display the descriptive score */}
                    <TableCell>{assessment?.score}</TableCell>
                    <TableCell>
                      {assessment?.score}/{assessment?.averageScore}
                    </TableCell>
                    {/* <TableCell>{assessment.teacher_id}</TableCell> */}
                    {/* <TableCell>{assessment.teacher_id}</TableCell> */}
                    <TableCell>
                      {new Date(assessment?.createdAt).toLocaleDateString(
                        "en-CA"
                      )}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        color="secondary"
                        onClick={() =>
                          navigate(
                            `/teacher/quiz-view/${assessment?.quizDetails?._id}/${assessment?._id}`
                          )
                        }
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>  ) : (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ height: "200px", textAlign: "center" }}
    >
      <Typography variant="h6" color="textSecondary">
        No permission to view.
      </Typography>
    </Box>
  )}
      </TableContainer>
      {totalItems > 100 && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding={2}
        >
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[100, 150, 200, 250]}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Box>
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}
        >
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default QuizReport;
