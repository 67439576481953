import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Container,
  Divider,
  Grid,
  Checkbox,
  useTheme,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { Person, LightbulbCircle } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import axios from "axios";
import { getToken } from "../../../utils/AuthUtils";

const mcqData = [
  {
    id: 1,
    question:
      "What is the Primary function of a solar wind mill in renewable energy system?",
    options: [
      "Directly into electricity",
      "Directly into electricity",
      "Directly into electricity",
      "Directly into electricity",
    ],
    selected: 1,
    score: 10,
    total: 10,
  },
  {
    id: 2,
    question:
      "What is the Primary function of a solar wind mill in renewable energy system?",
    options: [
      "Directly into electricity",
      "Directly into electricity",
      "Directly into electricity",
      "Directly into electricity",
    ],
    selected: 2,
    score: 0,
    total: 10,
  },
  {
    id: 3,
    question:
      "What is the Primary function of a solar wind mill in renewable energy system?",
    options: [
      "Directly into electricity Directly into electricity Directly into electricity",
      "Directly into electricity",
      "Directly into electricity",
      "Directly into electricity",
    ],
    selected: 3,
    score: 10,
    total: 10,
  },
];

const AssessmentView = () => {
  const [selectedAnswers, setSelectedAnswers] = useState({});
  ////
  const [assessmentQuestions, setAssessmentQuestions] = useState([]);
  const [evaluatedAssessment, setEvaluatedAssessment] = useState([]);
  const location = useLocation();
  const { gradename, course_name, chapterindex, chapter_namee, unitnamee } =
    location.state || {};
  const navigate = useNavigate();
  const [marks, setMarks] = useState({});
  const { quizid, evalquizid } = useParams();
  const [sum1, setSum1] = useState("");

  useEffect(() => {
    const fetchAssessmentQuestions = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/quiz/quiz/nopagination/${quizid}`,
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        const assessmentData = response.data.data || [];
        setAssessmentQuestions(
          Array.isArray(assessmentData) ? assessmentData : []
        );
        const sum = assessmentData.reduce(
          (accumulator, currentValue) => accumulator + currentValue.points,
          0
        );
        setSum1(sum);
      } catch (error) {
        console.error("Error fetching assessment questions:", error);
      }
    };
    fetchAssessmentQuestions();
  }, [quizid]);

  useEffect(() => {
    const fetchEvaluatedAssessment = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/quiz/evaluated/${evalquizid}`,
          { headers: { Authorization: `Bearer ${getToken()}` } }
        );
        const evaluatedAssessmentData = response.data.data || [];
        setEvaluatedAssessment(evaluatedAssessmentData);
      } catch (error) {
        console.error("Error fetching evaluated assessment:", error);
      }
    };
    fetchEvaluatedAssessment();
  }, [evalquizid]);

  const handleAnswerChange = (questionIndex, value) => {
    setSelectedAnswers({
      ...selectedAnswers,
      [questionIndex]: value,
    });
  };

  const handleMarksChange = (questionId, value, maxPoints) => {
    const enteredMarks = parseInt(value, 10);
    if (enteredMarks > maxPoints) {
      // alert(`Entered marks exceed the allowed points. Max points: ${maxPoints}`);
      return;
    }
    setMarks({
      ...marks,
      [questionId]: value,
    });
  };

  // const handleCheckboxChange = (questionId, optionIndex) => {
  //   setSelectedAnswers((prevState) => ({
  //     ...prevState,
  //     [questionId]: optionIndex,
  //   }));
  // };

  // Function to get evaluated answer for each question
  const getEvaluatedAnswer = (questionId) => {
    if (evaluatedAssessment.answers) {
      const answer = evaluatedAssessment.answers.find(
        (a) => a.question_id === questionId
      );
      return answer ? answer : null;
    }
    return null;
  };

  return (
    <Container maxWidth="100%" sx={{ paddingRight: "0px" }}>
      <Box
        padding={1}
        display="flex"
        justifyContent="space-between"
        backgroundColor="#e0e0e0"
        mb={2}
        mt={2}
      >
        <Typography variant="h4" component="h1" p={1}>
          Quiz View
        </Typography>
      </Box>

      <Box
        sx={{
          backgroundColor: "#e53935",
          color: "#ffffff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "10px 20px",
          borderRadius: "8px",
          width: "100%",
          margin: "20px auto",
        }}
      >
        <LightbulbCircle
          sx={{
            marginRight: "10px",
            fontSize: { xs: "18px", sm: "20px", md: "120px", lg: "120px" },
          }}
        />
        <Typography
          variant="h6"
          component="span"
          sx={{ fontSize: { xs: "18px", sm: "20px", md: "50px", lg: "50px" } }}
        >
          Knowledge Check
        </Typography>
      </Box>

      <Box
        mt={2}
        sx={{
          padding: "20px",
          backgroundColor: "#fbe9e7",
          borderRadius: "60px",
          border: "1px solid",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: "0 auto",
        }}
      >
        <Container maxWidth="95%" sx={{ padding: "24px !important" }}>
          {assessmentQuestions.map((question, index) => (
            <Box key={question?._id} sx={{ marginBottom: "20px" }}>
              {/* Question Header */}
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: { xs: "column", sm: "row" },
                  gap: { xs: "10px", sm: "0" },
                }}
              >
                {/* Question Number */}
                <Grid item xs={12} sm={2} md={1}>
                  <Box
                    sx={{
                      backgroundColor: "#fb8c00",
                      color: "#fff",
                      fontWeight: "bold",
                      fontSize: {
                        xs: "16px",
                        sm: "18px",
                        md: "22px",
                        lg: "28px",
                      },
                      borderRadius: "50%",
                      height: { xs: "50px", sm: "60px", md: "70px" },
                      width: { xs: "50px", sm: "60px", md: "70px" },
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      mx: "auto", // Center the box on small screens
                    }}
                  >
                    {index + 1}
                  </Box>
                </Grid>

                {/* Question Text */}
                <Grid item xs={12} sm={10} md={11}>
                  <Typography
                    variant="h4"
                    sx={{
                      fontSize: {
                        xs: "16px !important",
                        sm: "18px !important",
                        md: "22px !important",
                        lg: "30px !important",
                      },
                      color: "#000 !important",
                      textAlign: { xs: "center", sm: "left" },
                    }}
                  >
                    {question.question_text}
                  </Typography>
                </Grid>

                {/* Question Image */}
                {question?.question_image && (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      textAlign: { xs: "center", md: "center", lg: "left" },
                      mt: 2,
                    }}
                  >
                    <img
                      src={question?.question_image}
                      alt="Question"
                      style={{
                        width: "100%",
                        maxWidth: "300px",
                        height: "auto",
                        objectFit: "cover",
                      }}
                    />
                  </Grid>
                )}
              </Grid>

              {/* Question Options */}
              <Grid
                container
                justifyContent="center"
                sx={{ marginTop: "10px" }}
              >
                <Grid item xs={8}>
                  {question.question_type === "mcq" && (
                    <Grid container spacing={2}>
                      {question.options.map((option, optionIndex) => (
                        <Grid item xs={12} md={6} key={optionIndex}>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Checkbox
                              checked={
                                getEvaluatedAnswer(question._id)
                                  ?.user_answer === option
                              }
                              sx={{
                                transform: "scale(1.5)",
                                "&.Mui-checked": { color: "#43a047" }, // Checkbox checked color remains green
                              }}
                            />

                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: "25px",
                                fontWeight: "bold",
                                color:
                                  option === question.answer // Correct answer
                                    ? "#43a047" // Green for correct answer
                                    : getEvaluatedAnswer(question._id)
                                        ?.user_answer === option // Wrong answer
                                    ? "#e53935" // Red for wrong answer
                                    : "#000", // Default color for unselected options
                              }}
                            >
                              {option}
                            </Typography>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  )}

                  {/* Yes/No Question */}
                  {question.question_type === "yes/no" && (
                    <Grid container spacing={2}>
                      {["Yes", "No"].map((option, optionIndex) => (
                        <Grid item xs={6} key={optionIndex}>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Checkbox
                              checked={
                                getEvaluatedAnswer(question._id)
                                  ?.user_answer === option
                              }
                              sx={{
                                transform: "scale(1.5)",
                                "&.Mui-checked": { color: "#43a047" }, // Green for checked box
                              }}
                            />
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: "25px",
                                fontWeight: "bold",
                                color:
                                  option === question.answer // Correct answer
                                    ? "#43a047" // Green
                                    : getEvaluatedAnswer(question._id)
                                        ?.user_answer === option // Wrong answer
                                    ? "#e53935" // Red
                                    : "#000", // Default color for unselected options
                              }}
                            >
                              {option}
                            </Typography>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  )}

                  {/* Descriptive Question */}
                  {question.question_type === "descriptive" && (
                    <Box mt={2}>
                      <textarea
                        readOnly
                        value={
                          getEvaluatedAnswer(question._id)?.user_answer || ""
                        }
                        style={{
                          width: "100%",
                          minHeight: "100px",
                          border: "1px solid #ccc",
                          borderRadius: "8px",
                          padding: "10px",
                        }}
                      />
                      {/* <Box mt={2}>
                    <Typography>
                      Points Awarded:{" "}
                      {getEvaluatedAnswer(question._id)?.points || "0"} /{" "}
                      {question.points}
                    </Typography>
                  </Box> */}
                    </Box>
                  )}
                </Grid>
                {/* Marks */}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={1}
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "center", sm: "flex-end" }, // Center on small screens
                  }}
                >
                  <Box
                    sx={{
                      width: { xs: "60px", sm: "80px" }, // Adjust size for smaller screens
                      height: { xs: "60px", sm: "80px" },
                      borderRadius: "6px",
                      overflow: "hidden", // Ensures rounded corners apply to the child boxes
                      display: "flex",
                      flexDirection: "column", // Stacks the two halves vertically
                    }}
                  >
                    {/* Top Half - Orange */}
                    <Box
                      sx={{
                        backgroundColor: "#fb8c00",
                        color: "#fff",
                        height: "50%", // Takes half the parent's height
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "bold",
                        fontSize: {
                          xs: "14px",
                          sm: "16px",
                          md: "16px",
                          lg: "18px",
                        },
                      }}
                    >
                      {getEvaluatedAnswer(question?._id)?.points}
                    </Box>

                    {/* Bottom Half - Black */}
                    <Box
                      sx={{
                        backgroundColor: "#000",
                        color: "#fff",
                        height: "50%", // Takes half the parent's height
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "bold",
                        fontSize: "14px",
                      }}
                    >
                      {question?.points}
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              {/* Divider */}
              {index < assessmentQuestions.length - 1 && (
                <Divider sx={{ margin: "20px 0", borderColor: "#000" }} />
              )}
            </Box>
          ))}
        </Container>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginTop: "20px",
          marginRight: "161px",
        }}
      >
        <Typography variant="h6" sx={{ marginRight: "16px" }}>
          Total Score
        </Typography>
        <Box
          sx={{
            width: "80px",
            height: "80px",
            borderRadius: "6px",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* Top Half - Orange */}
          <Box
            sx={{
              backgroundColor: "#fb8c00",
              color: "#fff",
              height: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
              fontSize: "16px",
            }}
          >
            {evaluatedAssessment?.score}
          </Box>

          {/* Bottom Half - Black */}
          <Box
            sx={{
              backgroundColor: "#000",
              color: "#fff",
              height: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
              fontSize: "16px",
            }}
          >
            {sum1}
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default AssessmentView;
