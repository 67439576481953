import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Snackbar,
  Alert,
  TextField,
  Box,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Autocomplete,
  Grid,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  DialogActions,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate, useParams } from "react-router-dom";
import { getToken } from "../../../utils/AuthUtils";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";

const QuizQuestionsList = () => {
  const [quizes, setQuizes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [success, setSuccess] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [deleteId, setDeleteId] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openAddQuestionDialog, setOpenAddQuestionDialog] = useState(false);
  const [newQuestion, setNewQuestion] = useState({
    question_text: "",
    question_type: "mcq",
    options: ["", "", "", ""], // Four options
    answer: "",
    points: 0,
    question_image: "", // store image URL
  });
  const [imageFiles, setImageFiles] = useState([]);
  const navigate = useNavigate();
  const { quizId } = useParams();

  useEffect(() => {
    fetchQuizzes(quizId);
  }, [quizId, page, rowsPerPage, searchTerm]);

  const fetchQuizzes = async (quizId) => {
    try {
      const token = getToken();
      if (!token) return;
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/quiz/quiz/pagination/${quizId}?page=${page + 1}&limit=${rowsPerPage}&search=${searchTerm}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (data?.data) {
        const quizzesWithSerial = data.data.map((quiz, index) => ({
          ...quiz,
          serial: page * rowsPerPage + index + 1,
        }));
        setQuizes(quizzesWithSerial);
        setTotalItems(data.pagination.totalItems);
      } else {
        setError("No quizzes found");
      }
    } catch (err) {
      setError("Failed to fetch quizzes");
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAddQuiz = () => {
    navigate("/teacher/add-quiz");
  };

  const handleOpenDeleteDialog = (id) => {
    setDeleteId(id);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteId(null);
    setOpenDeleteDialog(false);
  };

  const deleteQuiz = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/quiz/question/delete/${deleteId}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (response.status === 200) {
        setSnackbarMessage("Quiz deleted successfully");
        setOpenSnackbar(true);
        fetchQuizzes(quizId);
        handleCloseDeleteDialog();
      }
    } catch (err) {
      setError("Failed to delete quiz");
    }
  };

  // Open and close add question dialog
  const handleOpenAddQuestionDialog = () => {
    setOpenAddQuestionDialog(true);
  };

  const handleCloseAddQuestionDialog = () => {
    setOpenAddQuestionDialog(false);
    setNewQuestion({
      question_text: "",
      question_type: "mcq",
      options: ["", "", "", ""],
      answer: "",
      points: 0,
      question_image: "", // Reset image when closing
    });
  };

  const handleAddQuestion = async (e) => {
    e.preventDefault();

    // If the question type is "yes/no", set options to an empty array
  if (newQuestion.question_type === "yes/no") {
    newQuestion.options = [];
  }

    // Prepare FormData for submission
    const formData = new FormData();
    formData.append(
      "questions",
      JSON.stringify({
        ...newQuestion, // Spread the newQuestion object
        question_image: newQuestion.question_image && newQuestion.question_image.file ? "" : null, // Properly handle question_image
      })
    );
    formData.append("quiz_id", quizId);

    // Append all image files to FormData
    imageFiles.forEach((file, index) => {
      if (file) {
        formData.append(`question_image_${index}`, file);
      }
    });

    setLoading(true);

    try {
      const token = getToken();
      if (!token) {
        setError("Unauthorized user.");
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND}/api/quiz/addquestion`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 201) {
        setSuccess("Quiz question successfully added!");
        resetForm();
        handleCloseAddQuestionDialog();
        fetchQuizzes(quizId);
        navigate(`/teacher/quizQuestion-list/${quizId}`);
      } else {
        setError("Failed to add quiz question.");
      }
    } catch (err) {
      setError("An error occurred while adding the quiz question.");
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setNewQuestion([]);
    setImageFiles([]);
    setError(null);
    setSuccess(null);
  };

  // Handle changes in the question form
  const handleChangeNewQuestion = (e) => {
    const { name, value } = e.target;
    setNewQuestion((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleOptionChange = (index, e) => {
    const { value } = e.target;
    const newOptions = [...newQuestion.options];
    newOptions[index] = value;
    setNewQuestion((prev) => ({
      ...prev,
      options: newOptions,
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file); // Create image URL for preview
      setNewQuestion((prev) => ({
        ...prev,
        question_image: imageUrl, // Set image URL for preview
      }));
      setImageFiles((prev) => [...prev, file]);
    }
  };

  return (
    <Box>
      <Box
        p={1}
        mb={2}
        sx={{
          backgroundColor: "#e0e0e0cf",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4">Questions for quiz</Typography>
        {/* <TextField
          // label="Search"
          variant="outlined"
          size="small"
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
          sx={{
            width: "300px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#00000030",
              },
              "&:hover fieldset": {
                borderColor: "#00000070",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#00000040",
              },
            },
            "& .MuiInputLabel-root": {
              display: "flex",
              alignItems: "center",
              transition: "all 0.3s ease",
            },
            "& .MuiInputLabel-shrink": {
              display: "flex",
              alignItems: "center",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        /> */}
      </Box>

      <Box
        display="flex"
        // flex="end"
        justifyContent="flex-end"
        mb={2}
        // justifyContent="space-between"
        // alignItems="center"
        // padding={2}
      >
        <Button
          onClick={handleOpenAddQuestionDialog}
          variant="contained"
          color="primary"
          // display="flex"
          // flex="end"
        >
          Add Question
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                No.
              </TableCell>
              <TableCell>
                Question Text
              </TableCell>
              <TableCell>
                Question Type
              </TableCell>
              {/* <TableCell>
                Options
              </TableCell> */}
              <TableCell>
                Answer
              </TableCell>
              <TableCell>
                Points
              </TableCell>
              <TableCell>
                Options
              </TableCell>
              <TableCell>
                Image
              </TableCell>
              <TableCell>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {quizes.length > 0 ? (
              quizes.map((quiz) => (
                <TableRow key={quiz._id}>
                  <TableCell>{quiz.serial}</TableCell>
                  <TableCell>{quiz?.question_text||"question text"}</TableCell>
                  <TableCell>{quiz?.question_type || "question type"}</TableCell>
                  <TableCell>{quiz?.answer||"answer"}</TableCell>
                  <TableCell>{quiz?.points||"points"}</TableCell>
                  <TableCell>
                    {quiz?.options ? (
                      <ul>
                        {quiz.options.map((option, index) => (
                          <li key={index}>{option}</li>
                        ))}
                      </ul>
                    ) : (
                      "No Options"
                    )}
                  </TableCell>
                  <TableCell>
                    {quiz.question_image ? (
                      <img
                        src={quiz.question_image}
                        alt="No Image"
                        style={{
                          width: "100px",
                          height: "auto",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      "No image"
                    )}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      color="secondary"
                      onClick={() => handleOpenDeleteDialog(quiz._id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  No quiz questions available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Add Question Dialog */}
      <Dialog
        open={openAddQuestionDialog}
        onClose={handleCloseAddQuestionDialog}
      >
        <DialogTitle>Add New Question</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                  id="image-upload"
                />
                <label htmlFor="image-upload">
                  <Button variant="outlined" component="span">
                    Upload Image
                  </Button>
                </label>
                {newQuestion.question_image && (
                  <Box sx={{ marginTop: "10px", textAlign: "center" }}>
                    <img
                      src={newQuestion.question_image}
                      alt="Question preview"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "200px",
                        objectFit: "contain",
                        borderRadius: "8px",
                      }}
                    />
                  </Box>
                )}
              </Box>
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Question Text"
                variant="outlined"
                fullWidth
                name="question_text"
                value={newQuestion.question_text}
                onChange={handleChangeNewQuestion}
                required
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Points"
                type="number"
                variant="outlined"
                fullWidth
                name="points"
                value={newQuestion.points}
                onChange={handleChangeNewQuestion}
                required
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Question Type</InputLabel>
                <Select
                  name="question_type"
                  value={newQuestion.question_type}
                  onChange={handleChangeNewQuestion}
                  label="Question Type"
                >
                  <MenuItem value="mcq">MCQ</MenuItem>
                  <MenuItem value="yes/no">Yes/No</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* MCQ Options */}
            {newQuestion.question_type === "mcq" && (
              <Grid item xs={12}>
                {newQuestion.options.map((option, index) => (
                  <Box key={index} sx={{ marginBottom: "10px" }}>
                    <TextField
                      label={`Option ${index + 1}`}
                      variant="outlined"
                      fullWidth
                      name={`option_${index}`}
                      value={option}
                      onChange={(e) => handleOptionChange(index, e)}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={newQuestion.answer === option}
                          onChange={() =>
                            handleChangeNewQuestion({
                              target: { name: "answer", value: option },
                            })
                          }
                          name="answer"
                          value={option}
                        />
                      }
                      label="Correct Answer"
                    />
                  </Box>
                ))}
              </Grid>
            )}

            {/* Yes/No Answer */}
            {newQuestion.question_type === "yes/no" && (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Answer</InputLabel>
                  <Select
                    value={newQuestion.answer || ""}
                    onChange={handleChangeNewQuestion}
                    name="answer"
                    label="Answer"
                  >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddQuestionDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleAddQuestion}
            color="primary"
            variant="contained"
          >
            Add Question
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this quiz question?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={deleteQuiz}
            color="primary"
            sx={{ backgroundColor: "#e0e0e0" }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Pagination */}
      {totalItems > 10 && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding={2}
        >
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handlePageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPageOptions={[10, 25, 50, 100]}
            labelRowsPerPage="Rows per page"
            showFirstButton
            showLastButton
          />
        </Box>
      )}

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={error ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default QuizQuestionsList;

