import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  Container,
  Card,
  CardContent,
  Button,
} from "@mui/material";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import QuizIcon from "@mui/icons-material/Quiz";
import ProjectIcon from "@mui/icons-material/Build";
import CommentIcon from "@mui/icons-material/Comment";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import SlideshowIcon from "@mui/icons-material/Slideshow"; // For Slider
import { fontWeight } from "@mui/system";
import axios from "axios";
import { getToken } from "../utils/AuthUtils";

const circleData = [
  { title: "Score", value: 67 },
  { title: "Teacher Rating", value: 5 },
  { title: "Quiz", value: 73 },
  { title: "Class Average", value: 67 },
  { title: "Engagement", value: 44 },
  { title: "Discussion", value: 56 },
  { title: "Consistency", value: 78 },
  { title: "Critical Thinking", value: 34 },
  { title: "Creative Thinking", value: 52 },
];

const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [schoolRating, setAssessmentRating] = useState([]);
  const [teacherRating, setTeacherRating] = useState([]);
  const [dashboardCounts, setDashboardCounts] = useState({
    projects: 0,
    submittedProjects: 0,
    teachers: 0,
    students: 0,
    assessments: 0,
    evaluatedAssessments: 0,
  });

  const [progressCounts, setProgressCounts] = useState({
    courses: 0,
    chapters: 0,
    units: 0,
    projects: 0,
    comments: 0,
  });

  const scrollRef = useRef(null);

  const handleScrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: -200, behavior: "smooth" });
    }
  };

  const handleScrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const token = getToken();
        if (token) {
          const teacherResponse = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/teacher/dashboardcount`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          // Assuming teacherResponse.data.data contains the count of teachers
          console.log("Teacher data:", teacherResponse.data.data.length); // Check the structure

          const studentResponse = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/student/stcount`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          console.log("studentss count:", studentResponse.data);

          setDashboardCounts({
            ...dashboardCounts,
            teachers: teacherResponse.data.data.length, // Ensure this is the correct way to get the teacher count
            students: studentResponse.data.data.length,
          });

          // total projects count
          const projectResponse = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/project/pcountbyschool`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          console.log("projects count:", projectResponse.data);

          const submittedProjectResponse = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/project/submittedpcountbyschool`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          console.log("projects count:", submittedProjectResponse.data);

          const assessmentResponse = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/assessment/assessmentcountbyschool`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          console.log("assessments count:", assessmentResponse.data.data);

          const evaluatedAssessmentResponse = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/assessment/evaluatedassessmentcountbyschool`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          console.log(
            "evaluated assessments count:",
            evaluatedAssessmentResponse.data.data
          );

          setDashboardCounts({
            ...dashboardCounts,
            teachers: teacherResponse.data.data.length, // Ensure this is the correct way to get the teacher count
            students: studentResponse.data.data.length,
            projects: projectResponse.data.data.length,
            submittedProjects: submittedProjectResponse.data.data.length,
            assessments: assessmentResponse.data.data.length,
            evaluatedAssessments: evaluatedAssessmentResponse.data.data.length,
          });

          const courseResponse = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/courses/coursecount`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          console.log("courses count:", courseResponse.data.data);
          setProgressCounts({
            ...progressCounts,
            courses: courseResponse.data.data.length, // Ensure this is the correct way to get the teacher count
          });

          const chapterResponse = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/chapters/chaptercount`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          console.log("chapters count:", chapterResponse.data.data);
          setProgressCounts({
            ...progressCounts,
            courses: courseResponse.data.data.length, // Ensure this is the correct way to get the teacher count
            chapters: chapterResponse.data.data.length,
          });

          const unitResponse = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/units/unitcount`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          console.log("units count:", unitResponse.data.data);

          const commentsResponse = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/teacherComments/commentscount`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          console.log("units count:", unitResponse.data.data);

          setProgressCounts({
            ...progressCounts,
            courses: courseResponse.data.data.length, // Ensure this is the correct way to get the teacher count
            chapters: chapterResponse.data.data.length,
            units: unitResponse.data.data.length,
            projects: projectResponse.data.data.length,
            comments: commentsResponse.data.data.length,
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchCounts();
  }, []);

  // evaluated assessments count (rating)
    useEffect(() => {
      const fetchAssessmentRating = async () => {
        try {
          const token = getToken();
          if (token) {
            const assessmentRatingResponse = await axios.get(
              `${process.env.REACT_APP_BACKEND}/api/assessment/school-rating`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            // Assuming teacherResponse.data.data contains the count of teachers
            console.log("assessment rating data-------", assessmentRatingResponse.data.data); // Check the structure
  
            setAssessmentRating(assessmentRatingResponse.data.data);
            setLoading(false); // Stop loading when data is fetched
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          setLoading(false); // Stop loading on error
        }
      };
  
      fetchAssessmentRating();
    }, []);

     // teacher rating count (rating)
     useEffect(() => {
      const fetchTeacherRating = async () => {
        try {
          const token = getToken();
          if (token) {
            const teacherRatingResponse = await axios.get(
              `${process.env.REACT_APP_BACKEND}/api/assessment/teacher-rating`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            // Assuming teacherResponse.data.data contains the count of teachers
            console.log("teacher rating data-------", teacherRatingResponse.data.data); // Check the structure
  
            setTeacherRating(teacherRatingResponse.data.data);
            setLoading(false); // Stop loading when data is fetched
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          setLoading(false); // Stop loading on error
        }
      };
  
      fetchTeacherRating();
    }, []);

  console.log("teacher yyyy count ::", dashboardCounts.teachers);

  const dashboardData = [
    // { title: "NO OF SCHOOLS", number1: 40, number2: 36, color1: "#fde0a9 !important", color2: "#e4443f" },
    {
      title: "NO OF TEACHERS",
      number1: 0,
      number2: dashboardCounts.teachers,
      color1: "#fde0a9 !important",
      color2: "#9f937b !important",
    },
    {
      title: "NO OF STUDENTS",
      number1: 0,
      number2: dashboardCounts.students,
      color1: "#fde0a9 !important",
      color2: "#9f937b",
    },
    {
      title: "PROJECTS SUBMITTED",
      number1: dashboardCounts.submittedProjects,
      number2: dashboardCounts.projects,
      color1: "#fde0a9 !important",
      color2: "#9f937b",
    },
    {
      title: "NO OF ASSESSMENTS",
      number1: dashboardCounts.evaluatedAssessments,
      number2: dashboardCounts.assessments,
      color1: "#fde0a9 !important",
      color2: "#9f937b",
    },
    // {
    //   title: "NO OF QUIZZES",
    //   number1: 40,
    //   number2: 32,
    //   color1: "#fde0a9 !important",
    //   color2: "#9f937b",
    // },
    // {
    //   title: "NO OF TICKETS",
    //   number1: 40,
    //   number2: 36,
    //   color1: "#fde0a9 !important",
    //   color2: "#9f937b",
    // },
    // {
    //   title: "TASKS SUBMITTED",
    //   number1: 40,
    //   number2: 36,
    //   color1: "#fde0a9 !important",
    //   color2: "#9f937b",
    // },
  ];

  const progressData = [
    { value: progressCounts.courses, label: "COURSES", icon: MenuBookIcon },
    { value: progressCounts.chapters, label: "CHAPTERS", icon: GroupWorkIcon },
    { value: progressCounts.units, label: "UNITS", icon: SlideshowIcon },
    { value: 0, label: "QUIZZES", icon: QuizIcon },
    { value: progressCounts.projects, label: "PROJECTS", icon: ProjectIcon },
    { value: progressCounts.comments, label: "COMMENTS", icon: CommentIcon },
  ];

  return (
    <Container maxWidth="xl" sx={{ paddingY: 4, overflowX: "hidden" }}>
  <Grid container spacing={2}>
    {/* Dashboard Cards */}
    {dashboardData.map((card, index) => (
      <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
        <DashboardCard {...card} />
      </Grid>
    ))}

    {/* Rating Cards Section */}
    <Grid container item xs={12} spacing={2} flexWrap="wrap">
      {["SCHOOLS", "TEACHERS", "STUDENTS", "PROJECTS"].map((type, index) => (
        <Grid
          item
          xs={12} // Full width on extra small screens
          sm={6} // Two per row on small screens
          md={3} // Four per row on medium+ screens
          key={index}
        >
          <RatingCard
            title={`${type} RATING`}
            leftValues={
              type === "SCHOOLS"
                ? [
                    schoolRating.excellentCount,
                    schoolRating.goodCount,
                    schoolRating.averageCount,
                    schoolRating.moderateCount,
                    schoolRating.badCount,
                  ]
                : type === "TEACHERS"
                ? [
                    teacherRating.excellentCount,
                    teacherRating.goodCount,
                    teacherRating.averageCount,
                    teacherRating.moderateCount,
                    teacherRating.badCount,
                  ]
                : [30, 22, 12, 6, 4]
            }
            rightValues={
              type === "SCHOOLS"
                ? Array(5).fill(schoolRating.totalevaluatedAssessments)
                : type === "TEACHERS"
                ? Array(5).fill(teacherRating.totalevaluatedAssessments)
                : [40, 40, 40, 40, 4]
            }
            barWidths={[100, 100, 100, 100, 100]}
          />
        </Grid>
      ))}
    </Grid>

    {/* Progress Cards Section */}
    <Grid container item xs={12} spacing={2} sx={{ paddingTop: 2 }}>
      {progressData.map((item, index) => (
        <Grid
          item
          xs={12} // Full width on extra small screens
          sm={6} // Two per row on small screens
          md={3} // Four per row on medium+ screens
          key={index}
        >
          <Progress
            score={item.value}
            total={100}
            title={item.label}
            IconComponent={item.icon}
          />
        </Grid>
      ))}
    </Grid>

    {/* Optional Footer Section */}
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        overflow: "hidden",
        paddingX: { xs: 0, sm: 2 },
        mt: 4,
      }}
    >
      {/* Add content here if needed */}
    </Box>
  </Grid>
</Container>

  );
};

const DashboardCard = ({ title, number1, number2, color1, color2 }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
      borderRadius: "5px",
      // boxShadow: 3,
      backgroundColor: "#e1ded9",
      padding: { xs: "12px", sm: "16px", md: "20px" },
    }}>
    {/* Title section with improved handling for long text */}
    <Typography
      variant="h6"
      align="center"
      fontWeight="300"
      sx={{
        marginBottom: { xs: 1, sm: 2 },
        fontSize: { xs: "16px", sm: "18px", md: "20px" },
        whiteSpace: "normal",
        // overflow: "hidden",
        textOverflow: "ellipsis",
        maxHeight: "48px", // Adjust this as needed
        lineHeight: "1.2em",
        display: "-webkit-box",
        WebkitLineClamp: 2, // Ensures the title is limited to two lines
        WebkitBoxOrient: "vertical",
      }}>
      {title}
    </Typography>

    {/* Inner cards, fixed layout to prevent shifting */}
    <Grid container spacing={1} justifyContent="center" alignItems="center">
      {[number1, number2].map((num, idx) => (
        <Grid item xs={5} key={idx}>
          <Box
            sx={{
              backgroundColor: idx === 0 ? color2 : "#f5f1ec",
              color: idx === 0 ? "#fff" : "rgb(157 155 152)",
              textAlign: "center",
              padding: { xs: "10px" },
              borderRadius: "4px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <Typography
              variant="h4"
              sx={{
                fontSize: { sm: "17px", md: "19px", lg: "22px" },
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}>
              {num}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  </Box>
);

const RatingCard = ({ title, leftValues, rightValues, barWidths }) => {
  const ratings = [
    {
      label: "EXCELLENT",
      leftValue: leftValues[0],
      rightValue: rightValues[0],
      color: "#ebe6e2",
      width: barWidths[0],
      textColor: "#919191",
    },
    {
      label: "GOOD",
      leftValue: leftValues[1],
      rightValue: rightValues[1],
      color: "#c9c1b2",
      width: barWidths[1],
      textColor: "#898989",
    },
    {
      label: "AVERAGE",
      leftValue: leftValues[2],
      rightValue: rightValues[2],
      color: "#b4aca1",
      width: barWidths[2],
      textColor: "#e5e5e5",
    },
    {
      label: "Moderate",
      leftValue: leftValues[3],
      rightValue: rightValues[3],
      color: "#b4aca1",
      width: barWidths[3],
      textColor: "#e5e5e5",
    },
    {
      label: "BAD",
      leftValue: leftValues[4],
      rightValue: rightValues[4],
      color: "#948b82",
      width: barWidths[4],
      textColor: "#fff",
    },
  ];

  return (
    <Card
      sx={{
        borderRadius: "6px",
        backgroundColor: "#e1ded9",
        marginTop: { xs: "10px", sm: "20px", md: "60px" },
        boxShadow: "none !important",
      }}>
      <CardContent>
        <Typography
          variant="h6"
          align="center"
          sx={{ fontWeight: "300", marginBottom: "15px" }}>
          {title}
        </Typography>
        <Grid container spacing={0.2}>
          {ratings.map((rating, index) => (
            <Grid item xs={12} key={index}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between">
                {/* Bar (Label) on the Left */}
                <Box
                  sx={{
                    backgroundColor: rating.color,
                    color: rating.textColor,
                    padding: "8px 16px",
                    width: `${rating.width}%`,
                    textAlign: "left",
                  }}>
                  <Typography variant="body2">{rating.label}</Typography>
                </Box>

                {/* Set of Numbers on the Right */}
                <Box display="flex" alignItems="center">
                  {/* Left number set */}
                  <Box
                    sx={{
                      backgroundColor: "#796d57",
                      padding: "8px",
                      width: "55px",
                      color: "#fff",
                      textAlign: "center",
                    }}>
                    <Typography variant="body2">{rating.leftValue}</Typography>
                  </Box>

                  {/* Right number set */}
                  <Box
                    sx={{
                      backgroundColor: "#9f937a",
                      padding: "8px",
                      width: "50px",
                      color: "#fff",
                      textAlign: "center",
                    }}>
                    <Typography variant="body2">{rating.rightValue}</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

const Progress = ({ score, title, IconComponent }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: { xs: "100px", sm: "120px", md: "140px" }, // Responsive circle width
        height: { xs: "100px", sm: "120px", md: "140px" }, // Responsive circle height
        position: "relative",
        marginTop: { xs: "10px", sm: "20px", md: "60px" },
        marginLeft: "20px",
      }}>
      <Box
        sx={{
          width: { xs: "80px", sm: "100px", md: "120px" }, // Responsive inner circle width
          height: { xs: "80px", sm: "100px", md: "120px" }, // Responsive inner circle height
          borderRadius: "50%",
          borderColor: "#e1dcd9 !important",
          backgroundColor: " #93876f",
          border: "8px solid #ac3a38", // Adjusted border size for consistency
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}>
        {IconComponent && (
          <IconComponent
            sx={{
              fontSize: { xs: "20px", sm: "30px", md: "35px" },
              position: "absolute",
              color: "white",
              bottom: { xs: "5px", sm: "10px", md: "15px" }, // Adjust bottom position based on screen size
              fontWeight: "400",
            }}
          />
        )}
        <Typography
          variant="h4"
          sx={{
            color: "#fff !important",
            fontSize: { xs: "18px", sm: "22px", md: "24px" }, // Responsive font size for the score
            zIndex: 1,
            position: "absolute",
            top: { xs: "5px", sm: "8px", md: "10px" }, // Adjust position based on screen size
          }}>
          {score}
        </Typography>
      </Box>

      <Typography
        variant="body2"
        sx={{
          marginTop: "8px",
          fontSize: { xs: "12px", sm: "14px" }, // Responsive title font size
          textAlign: "center",
        }}>
        {title}
      </Typography>
    </Box>
  );
};

const CircleCard = ({ title, value }) => {
  return (
    <Box
      sx={{
        width: { xs: "90px", sm: "110px", md: "130px" }, // Responsive width
        height: { xs: "90px", sm: "110px", md: "130px" }, // Responsive height
        position: "relative",
        marginX: { xs: "4px", sm: "6px", md: "8px" }, // Responsive margin
        marginTop: { xs: "10px", sm: "20px", md: "40px" },
        padding: "7px",
      }}>
      {/* Full circle */}
      <Box
        sx={{
          width: "100%",
          height: "100%",
          borderRadius: "50%",
          border: "10px solid #e1dcd9", // Circle stroke color
          position: "relative",
          marginRight: "10px",
        }}>
        {/* Top half of the circle */}
        <Box
          sx={{
            width: "100%",
            height: "50%",
            backgroundColor: "#5b5441", // Top half background
            borderTopLeftRadius: "60px",
            borderTopRightRadius: "60px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: 0,
          }}>
          <Typography
            variant="h6"
            color="#fff !important"
            sx={{
              fontSize: { xs: "16px", sm: "20px", md: "25px" }, // Responsive font size for the value
              fontWeight: "250",
            }}>
            {value}
          </Typography>
        </Box>

        {/* Bottom half of the circle */}
        <Box
          sx={{
            width: "100%",
            height: "50%",
            backgroundColor: "#938671", // Bottom half background
            borderBottomLeftRadius: "60px",
            borderBottomRightRadius: "60px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            bottom: 0,
          }}>
          <Typography
            variant="h4"
            color="#fff !important"
            sx={{
              fontSize: { xs: "16px", sm: "20px", md: "25px" }, // Responsive font size for bottom number
            }}>
            100
          </Typography>
        </Box>
      </Box>

      {/* Title below the circle */}
      <Typography
        variant="body2"
        align="center"
        sx={{
          // position: "absolute",
          // bottom: "-20px",
          width: "100%",
          marginTop: "10px",
          fontWeight: "400",
          // overflow: "hidden",
          // textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          fontSize: { xs: "12px", sm: "15px" }, // Responsive title font size
        }}>
        {title}
      </Typography>
    </Box>
  );
};

export default Dashboard;
