import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Snackbar,
  Alert,
  TextField,
  Box,
  TablePagination,
  Grid,
  FormControl,
  MenuItem,
  Autocomplete,
  Container,
  Typography,
  InputAdornment,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SearchIcon from "@mui/icons-material/Search";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, useParams } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { width } from "@mui/system";
import { getToken } from "../../../utils/AuthUtils";
import axios from "axios";

const AssessmentReport = () => {
  const [assessments, setAssessments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const [serialNumbers, setSerialNumbers] = useState([]);
  const [totalItems, setTotalItems] = useState([]);
  const [courses, setCourses] = useState([]);
  const [grades, setGrades] = useState([]);
  const [students, setStudents] = useState([]);
  const [forFilter, setForFilter] = useState({
    course: "",
    grade: "",
    gradeDivision: "",
    studentId: "",
  });
  const { id } = useParams();

  const [canCreate, setCanCreate] = useState(false);  
  const [canView, setCanView] = useState(false);  
  const [canUpdate, setCanUpdate] = useState(false);  
  const [canDelete, setCanDelete] = useState(false);  


useEffect(() => {
  const fetchPermissions = async () => {
    const token = localStorage.getItem('token_key');
    
    if (token) {
      try {
        const roleResponse = await axios.get(
          `${process.env.REACT_APP_ADMIN_BACKEND}/api/rolespermission/permissions/21`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        
        const rolePermissions = roleResponse.data.data;
        
        // Check role permissions or set the role
        if (rolePermissions ) {
          // navigate("/unauthorized");
          // console.log("You are not authorized to create a school",rolePermissions);
          setCanCreate(rolePermissions.create);
          setCanView(rolePermissions.view);
          setCanUpdate(rolePermissions.update);
          setCanDelete(rolePermissions.delete);
        }
      } catch (error) {
        console.error("Error fetching role permissions:", error);
        // navigate("/unauthorized"); // Redirect in case of error
      }
    }
  };

  fetchPermissions();
}, []);

  useEffect(() => {
    fetchUnitAssessment();
    // fetchUnitAssessmentbyStudent();
  }, [page, rowsPerPage, searchTerm]);


   const fetchUnitAssessment = async () => {
    try {
      const token = getToken();
      if (!token) {
        console.log("Unauthorized user");
        return;
      }
  
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/assessment/bystudent`,
        {
          params: {
            page: page + 1,
            limit: rowsPerPage,
            search: searchTerm,
            studentId: id,
          },
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
  
      if (response.data?.data) {
        const assessmentWithSerial = response.data.data.map((assessment, index) => ({
          ...assessment,
          serial: page * rowsPerPage + index + 1,
          statusToggle: !assessment.status,
        }));
        setAssessments(assessmentWithSerial);
        setSerialNumbers(assessmentWithSerial.map((t) => t.serial));
        setTotalItems(response.data.pagination.totalItems);
      } else {
        setError("No teachers found");
      }
    } catch (err) {
      console.error("Error fetching assessments:", err);
      setError("Failed to fetch teachers");
    } finally {
      setLoading(false);
    }
  };


  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleSearch = (event) => {
    setForFilter({ studentId: "" });
    setSearchTerm(event.target.value);
    setPage(0); // Reset to first page on search
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page on rows per page change
  };

  return (
    <Container maxWidth="100%">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ backgroundColor: "#e0e0e0" }}
        p={1}
        mt={2}
      >
        <Typography variant="h4">Unit Assessment Report</Typography>
        <TextField
          // label="Search"
          variant="outlined"
          size="small"
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
          sx={{
            width: "300px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#00000030",
              },
              "&:hover fieldset": {
                borderColor: "#00000070",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#00000040",
              },
            },
            "& .MuiInputLabel-root": {
              display: "flex",
              alignItems: "center",
              transition: "all 0.3s ease",
            },
            "& .MuiInputLabel-shrink": {
              display: "flex",
              alignItems: "center",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      {/* <Button variant="contained" color="primary" onClick={() => navigate('/teacher/add-teacher')}>
        Add Teacher
      </Button> */}
      <Grid container spacing={1}>
        <Grid item xs={12} sm={3}>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
  {canView === 1 ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>No.</TableCell>
              <TableCell>Assessment Title</TableCell>
              <TableCell>Chapter</TableCell>
              <TableCell>Unit</TableCell>
              <TableCell>Other Score</TableCell>
              <TableCell>Descriptive Score</TableCell>
              <TableCell>Total Score</TableCell>
              <TableCell>Class Average</TableCell>
              {/* <TableCell>
                Participation
              </TableCell> */}
              {/* <TableCell>
                Comment
              </TableCell> */}
              <TableCell>Date</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {assessments.length > 0 ? (
              assessments.map((assessment) => {
                // Ensure assessment.answers is an array before proceeding
                const descriptiveScore =
                  assessment?.answers && Array.isArray(assessment?.answers)
                    ? assessment?.answers
                        .filter(
                          (answer) =>
                            answer?.question_id &&
                            answer?.question_id?.question_type === "descriptive"
                        )
                        .reduce((acc, answer) => acc + (answer?.points || 0), 0) // Ensure points exist
                    : 0;

                const notdescriptiveScore =
                  assessment?.answers && Array.isArray(assessment?.answers)
                    ? assessment?.answers
                        .filter(
                          (answer) =>
                            answer?.question_id &&
                            answer?.question_id?.question_type != "descriptive"
                        )
                        .reduce((acc, answer) => acc + (answer?.points || 0), 0) // Ensure points exist
                    : 0;

                return (
                  <TableRow key={assessment?._id}>
                    <TableCell>{assessment?.serial}</TableCell>
                    
                    <TableCell>
                      {assessment?.assessmentDetails?.assessment_title}
                    </TableCell>
                    <TableCell>
                      {
                        assessment?.chapterDetails?.chapter_name
                      }
                    </TableCell>
                    <TableCell>
                      {assessment?.unitDetails?.unit_name}
                    </TableCell>
                    <TableCell>{notdescriptiveScore}</TableCell>
                    <TableCell>{descriptiveScore}</TableCell>{" "}
                    {/* Display the descriptive score */}
                    <TableCell>{assessment?.score}</TableCell>
                    <TableCell>
                      {assessment?.score}/{assessment?.averageScore}
                    </TableCell>
                    {/* <TableCell>{assessment.teacher_id}</TableCell> */}
                    {/* <TableCell>{assessment.teacher_id}</TableCell> */}
                    <TableCell>
                      {new Date(assessment?.createdAt).toLocaleDateString(
                        "en-CA"
                      )}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        color="secondary"
                        onClick={() =>
                          navigate(
                            `/teacher/assessment-view1/${assessment.assessment_id.unit._id}/${assessment?._id}`
                          )
                        }
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ height: "200px", textAlign: "center" }}
            >
              <Typography variant="h6" color="textSecondary">
                No permission to view.
              </Typography>
            </Box>
          )}
      </TableContainer>
      {totalItems > 100 && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding={2}
        >
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[100, 150, 200, 250]}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Box>
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}
        >
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AssessmentReport;
