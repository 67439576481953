import React, { useState } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  Typography,
  Container,
  Paper,
  Box,
  Snackbar,
  Alert,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { getToken } from "../utils/AuthUtils";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const ResetPassword = () => {
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const navigate = useNavigate();

  const handleResetPassword = async () => {
    setErrorMessage(""); // Clear error message on each attempt

    // Ensure the new password and confirm password match
    if (newPassword !== confirmPassword) {
      setSnackbarMessage("New password and confirm password do not match.");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return;
    }

    try {
      const token = getToken(); // Get the auth token
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND}/api/password-reset`,
        {
          oldPassword,
          password: newPassword,
          confirmPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Log the response for debugging purposes
      console.log('Password reset response:', response);
      console.log('Status code:', response.status);

      // Check if the response is successful (status code 200)
      if (response.status === 200) {
        setSnackbarMessage("Password reset successful!");
        setSnackbarSeverity("success");
        setOpenSnackbar(true); // Show snackbar on success
      } else {
        // This should not usually happen, but adding a fallback to handle unexpected status codes
        setSnackbarMessage("Unexpected response from server.");
        setSnackbarSeverity("error");
        setOpenSnackbar(true); // Show snackbar on unexpected result
      }
    } catch (error) {
      console.error('Error:', error);

      // Handling error more gracefully
      if (error.response) {
        // If the server responds with an error status
        console.error('Error response:', error.response);
        setSnackbarMessage(
          error.response.data.message || "Failed to reset password."
        );
        setSnackbarSeverity("error");
      } else if (error.request) {
        // If the request was made but no response was received
        setSnackbarMessage("No response from server.");
        setSnackbarSeverity("error");
      } else {
        // If something else went wrong
        setSnackbarMessage("Internal server error.");
        setSnackbarSeverity("error");
      }
      setOpenSnackbar(true); // Show snackbar on error
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false); // Close the snackbar after the user dismisses it
  };

  return (
    <Container component="main" maxWidth="xl" sx={{ padding: "20px", borderRadius: "8px" }}>
      <Paper elevation={3} style={{ padding: "16px", marginTop: "32px" }}>
        <Typography variant="h5" align="center">
          Reset Password
        </Typography>
        <Box component="form" noValidate>
                    <TextField
            margin="normal"
            required
            fullWidth
            type={showOldPassword ? "text" : "password"}
            label="Old Password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle old password visibility"
                    onClick={() => setShowOldPassword(!showOldPassword)}
                    edge="end"
                  >
                    {showOldPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            type={showNewPassword ? "text" : "password"}
            label="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle new password visibility"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                    edge="end"
                  >
                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            type={showConfirmPassword ? "text" : "password"}
            label="Confirm New Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle confirm password visibility"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
         <Box display="flex" justifyContent="center">
            <Button
              onClick={handleResetPassword}
              color="primary"
              variant="contained"
              sx={{ m: 2, backgroundColor: "#e0e0e0" }}
            >
              Reset Password
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => navigate(-1)}
              sx={{
                m: 2,
                backgroundColor: "#e0e0e0",
                color: "#000",
                "&:hover": { backgroundColor: "#d6d6d6" },
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Paper>

      {/* Snackbar to display success or error message */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ResetPassword;
