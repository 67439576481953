import React, { useEffect, useState } from "react";
import {
  Box,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Typography,
  Grid,
  Avatar,
  TextField,
  Autocomplete,
  Button,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { getToken } from "../../../utils/AuthUtils";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Modal from "@mui/material/Modal";
import { borderColor, height } from "@mui/system";

// modal style
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

// Sample messages data
const messages = [
  {
    id: 3,
    user: "Manjusha",
    time: "Sept 03 12:30 PM",
    text: "Aim to assess student engagement, learning outcomes, and interest in robotics, providing valuable feedback for improving the program.",
  },
];

// Top section buttons data
// const topButtons = [
//   { label: "7 Grades", color: "#e4443f" },
//   { label: "450 Students", color: "#e4443f" },
//   { label: "10 Courses", color: "#e4443f" },
// ];

// Message Card Component
const MessageCard = ({ user, time, text, isReply }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        marginBottom: 2,
        width: "100%",
      }}
    >
      {/* {!isReply && (
        <Avatar
          alt={user}
          src="https://randomuser.me/api/portraits/women/65.jpg"
          sx={{ width: 48, height: 48, marginRight: 2 }}
        />
      )} */}

      <Box
        sx={{
          display: "flex",
          flexDirection: isReply ? "row-reverse" : "row",
          gap: 2,
          alignItems: "center",
          padding: 2,
          backgroundColor: "#f4f4f4",
          borderRadius: "8px",
          width: "100%",
          position: "relative",
          boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        {isReply && (
          <Avatar
            alt={user}
            src="https://randomuser.me/api/portraits/women/65.jpg"
            sx={{ width: 48, height: 48 }}
          />
        )}

        <Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography variant="subtitle2" fontWeight="bold">
              {/* {user.toUpperCase()} */}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {time}
            </Typography>
          </Box>
          <Typography variant="body2" sx={{ marginTop: "4px" }}>
            {text}
          </Typography>
        </Box>

        <ChatBubbleOutlineIcon
          sx={{
            position: "absolute",
            top: "-8px",
            left: isReply ? "unset" : "-24px",
            right: isReply ? "-24px" : "unset",
            backgroundColor: "#fff",
            borderRadius: "50%",
            padding: "2px",
            border: "1px solid #ccc",
            fontSize: "24px",
            color: "#666",
          }}
        />
      </Box>
    </Box>
  );
};

// Main TeacherComment Component
const TeacherComment = () => {
  const [grades, setGrades] = useState([]);
  const [students, setStudents] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [allComments, setAllComments] = useState([]);
  const [loading, setLoading] = useState(true);

  const [gradeAssign, setGradeAssign] = useState({
    grade: "",
    gradeDivision: "",
    studentId: "",
  });

  const [comments, setComments] = useState({
    comment: "",
  });

  const { id } = useParams();

  useEffect(() => {
    const fetchgradebyteacher = async () => {
      try {
        const token = getToken();
        if (!token) {
          console.log("Access Denied");
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/grade_assign/teacherData`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const gradeData = response.data.data || []; // Default to an empty array if no data
        setGrades(Array.isArray(gradeData) ? gradeData : []); // Ensure it's an array

        const response1 = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/teacherComments/studentcomments`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const comment1 = response1.data.data || []; // Default to an empty array if no data
        setAllComments(comment1.students ? comment1.students : []); // Ensure it's an array

        console.log("comments: ", comment1);
      } catch (error) {
        console.error("Error fetching teachers:", error);
      }
    };
    fetchgradebyteacher();
  }, []);

  useEffect(() => {
    const fetchStudent = async (id) => {
      try {
        const token = getToken();
        if (!token) {
          console.log("Access Denied");
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/student/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = response.data.data;
        console.log("student dataaaa: ", data);
        if (data) {
          const selectedGrade = grades.find(
            (grade) =>
              `${grade.grade_id?.gradename} ${grade.grade_division_id}` ===
              data.grade_division
          );
          setGradeAssign({
            grade: selectedGrade?._id || "",
            gradeDivision: data.grade_division || "",
            studentId: data._id || "",
          });
        } else {
          setError("Student not found");
        }
      } catch (error) {
        console.error("Error fetching studentData:", error);
      }
    };
    fetchStudent(id);
  }, [id, grades]);

  // useEffect(() => {
  //   console.log("grade Assign", gradeAssign);
  // }, [gradeAssign]);

  useEffect(() => {
    const fetchstudentBygradedivision = async () => {
      if (!gradeAssign.gradeDivision) return;
      try {
        const token = getToken();
        if (!token) {
          console.log("Access Denied");
        }
        setStudents([]);
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/grade_assign/gradedivision/${gradeAssign.gradeDivision}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const students = response.data.data || [];
        setStudents(students);
        console.log("students: ", response.data.data);

        // set comments by selected grade
        const response1 = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/teacherComments/gradedivision/${gradeAssign.gradeDivision}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const comments = response1.data.data.students || [];
        setAllComments(comments);
        console.log(
          "comments by grade DIVISION: ",
          response.data.data.students
        );
      } catch (error) {
        console.error("Error fetching students:", error);
      }
    };
    fetchstudentBygradedivision();
  }, [gradeAssign.gradeDivision]);

  // fetch course teach by the teacher to student
  // useEffect(() => {
  //   const fetchCourse = async () => {
  //     try {
  //       const token = getToken();
  //       if (!token) {
  //         console.log("Access Denied");
  //       }
  //       const response = await axios.get(
  //         `${process.env.REACT_APP_BACKEND}/api/grade_assign/gradedivision/${gradeAssign.gradeDivision}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       );
  //       const students = response.data.data || [];
  //       setStudents(students);
  //       console.log("students: ", response.data.data);
  //     } catch (error) {
  //       console.error("Error fetching students:", error);
  //     }
  //   };
  //   fetchCourse();
  // }, []);

  // fetch comments by teacher
  useEffect(() => {
    fetchComments();
  }, [gradeAssign.studentId]);

  const fetchComments = async () => {
    try {
      const token = getToken();
      if (!token) {
        console.log("Access Denied");
        setLoading(false); // Stop loading if no token
        return;
      }
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/teacherComments/${gradeAssign.studentId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const commentsData = response.data.data || [];
      setAllComments(commentsData);
      setLoading(true);
      console.log("commentsData: ", response.data.data);
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };

  const handleSendClick = async () => {
    try {
      const token = getToken();
      if (!token) {
        console.log("Access Denied");
      }
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND}/api/teacherComments`,
        { commentData: comments, studentId: gradeAssign.studentId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);
      //   setComments({ comment: "" });
      fetchComments();
      handleClose();
    } catch (error) {
      console.error("Error sending grade assign:", error);
    }
  };

  return (
    <Box sx={{ padding: 3 }}>
      {/* Header Section */}
      <Box
        p={1}
        mb={2}
        sx={{
          backgroundColor: "#e0e0e0cf",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4">Comments</Typography>
        {/* <TextField
          // label="Search"
          variant="outlined"
          size="small"
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
          sx={{
            width: "300px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#00000030",
              },
              "&:hover fieldset": {
                borderColor: "#00000070",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#00000040",
              },
            },
            "& .MuiInputLabel-root": {
              display: "flex",
              alignItems: "center",
              transition: "all 0.3s ease",
            },
            "& .MuiInputLabel-shrink": {
              display: "flex",
              alignItems: "center",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        /> */}
      </Box>

      {/* Dropdown Selectors */}
      <Grid
        container
        spacing={2}
        marginBottom={2}
        alignItems="center"
        justifyContent="flex-start"
      >
        {/* Grade Selection */}
        <Grid item xs={12} md={3}>
          <FormControl fullWidth>
            <Autocomplete
              value={
                grades.find((item) => item._id === gradeAssign.grade) || null
              }
              onChange={(event, newValue) => {
                setStudents([]);
                if (newValue) {
                  const selectedGrade = newValue;
                  const gradeDivision = `${selectedGrade.grade_id?.gradename} ${selectedGrade.grade_division_id}`; // Combine grade name and division

                  setGradeAssign({
                    grade: selectedGrade._id,
                    gradeDivision, // Assign the combined grade division
                  });
                }
              }}
              size="small"
              options={grades}
              getOptionLabel={(option) =>
                `${option.grade_id?.gradename} ${option.grade_division_id}`
              }
              isOptionEqualToValue={(option, value) =>
                option._id === value?._id
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Grade"
                  variant="outlined"
                />
              )}
              disableClearable
              renderOption={(props, option) => (
                <li {...props}>
                  {option.grade_id?.gradename} {option.grade_division_id}
                </li>
              )}
            />
          </FormControl>
        </Grid>

        {/* Student Selection */}
        <Grid item xs={12} md={3}>
          <FormControl fullWidth>
            {/* <InputLabel>Select Student</InputLabel> */}
            <TextField
              select
              size="small"
              value={gradeAssign.studentId}
              onChange={(e) =>
                setGradeAssign((prev) => ({
                  ...prev,
                  studentId: gradeAssign.name
                    ? gradeAssign.name
                    : e.target.value,
                }))
              }
              label="Student"
              aria-required="true"
            >
              {students.map((student) => (
                <MenuItem key={student._id} value={student._id}>
                  {student.name} 😊
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>

        {/* Add Comment Button */}
        <Grid
          item
          xs={4}
          display="flex"
          justifyContent="flex-end"
          sx={{ order: 2, marginLeft: "auto" }}
        >
          <Button
            variant="contained"
            color="secondary"
            onClick={handleOpen}
            sx={{ backgroundColor: "#e0e0e0" }}
          >
            Add Comment
          </Button>

          {/* Modal for Adding Comments */}
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Add Comment
              </Typography>
              <TextField
                id="comment-textarea"
                label="Your Comment"
                value={comments.comment}
                onChange={(e) =>
                  setComments({ ...comments, comment: e.target.value })
                }
                multiline
                rows={4}
                variant="outlined"
                fullWidth
                sx={{ mt: 2 }} // Optional margin
              />

              <Button
                variant="contained"
                color="primary"
                onClick={handleSendClick}
                sx={{ mt: 2, backgroundColor: "#e0e0e0" }} // Optional margin for spacing
              >
                Send
              </Button>
            </Box>
          </Modal>
        </Grid>
      </Grid>

      {/* sx={{ backgroundColor: "#f4e3c9" }} */}
      {/* </Grid> */}

      {/* Messages Section */}
      <Box>
        {/* {messages.map((msg, index) => (
          <MessageCard
            key={msg.id}
            user={msg.user}
            time={msg.time}
            text={msg.text}
            // isReply={index % 2 === 1} // Alternating reply and normal message
          />
        ))} */}
        {allComments.length > 0 ? (
          allComments.map((msg, index) => (
            <MessageCard
              key={msg._id}
              user={msg.user}
              time={msg.createdAt}
              text={msg.comment}
              // isReply={index % 2 === 1} // Alternating reply and normal message
            />
          ))
        ) : (
          <p>No comments found</p>
        )}
      </Box>
    </Box>
  );
};

export default TeacherComment;
