import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Snackbar,
  Alert,
  TextField,
  Box,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Autocomplete,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Container,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { getToken } from "../../../utils/AuthUtils";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";

const QuizList = () => {
  const [quizes, setQuizes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const [grades, setGrades] = useState([]);
  const [students, setStudents] = useState([]);
  const [forFilter, setForFilter] = useState({
    course: "",
    grade: "",
    gradeDivision: "",
    studentId: "",
  });
  const [deleteId, setDeleteId] = useState(null);
  const [quizCategory, setQuizCategory] = useState([]);
  const [selectedQuizCategory, setSelectedQuizCategory] = useState(null);
  const [quizSubCategory, setQuizSubCategory] = useState([]);
  const [selectedQuizSubCategory, setSelectedQuizSubCategory] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const navigate = useNavigate();

  const [canCreate, setCanCreate] = useState(false);  
const [canView, setCanView] = useState(false);  
const [canUpdate, setCanUpdate] = useState(false);  
const [canDelete, setCanDelete] = useState(false);  
const [isloading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setIsLoading(false), 5000);
    return () => clearTimeout(timer);
  }, []);

useEffect(() => {
  const fetchPermissions = async () => {
    const token = localStorage.getItem('token_key');
    
    if (token) {
      try {
        const roleResponse = await axios.get(
          `${process.env.REACT_APP_ADMIN_BACKEND}/api/rolespermission/permissions/24`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        
        const rolePermissions = roleResponse.data.data;
        
        // Check role permissions or set the role
        if (rolePermissions ) {
          // navigate("/unauthorized");
          // console.log("You are not authorized to create a school",rolePermissions);
          setCanCreate(rolePermissions.create);
          setCanView(rolePermissions.view);
          setCanUpdate(rolePermissions.update);
          setCanDelete(rolePermissions.delete);
        }
      } catch (error) {
        console.error("Error fetching role permissions:", error);
        // navigate("/unauthorized"); // Redirect in case of error
      }
    }
  };

  fetchPermissions();
}, []);


  // Fetch quiz categories
  useEffect(() => {
    const fetchQuizCategory = async () => {
      try {
        const token = getToken();
        if (!token) {
          console.log("Unauthorized user");
          return;
        }
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND}/api/quiz/category`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        if (response.ok) {
          setQuizCategory(data.data || []);
        }
      } catch (error) {
        console.error("Error fetching quiz categories:", error);
      }
    };
    fetchQuizCategory();
  }, []);

  // Fetch quiz sub-categories based on selected quiz category
  useEffect(() => {
    const fetchQuizSubCategory = async () => {
      if (!selectedQuizCategory) return;
      try {
        const token = getToken();
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND}/api/quiz/subcategory/${selectedQuizCategory._id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        if (response.ok) {
          setQuizSubCategory(data.data || []);
        }

        // fetch table
        const response1 = await fetch(
          `${process.env.REACT_APP_BACKEND}/api/quiz/allquizbycatId/${selectedQuizCategory._id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data1 = await response1.json();
        if (data1?.data) {
          const quizzesWithSerial = data1.data.map((quiz, index) => ({
            ...quiz,
            serial: page * rowsPerPage + index + 1,
          }));
          setQuizes(quizzesWithSerial);
          console.log("quizes list: ",quizzesWithSerial);
          
          setTotalItems(data1.pagination.totalItems);

        } else {
          setError("No quizzes found");
        }
      } catch (error) {
        console.error("Error fetching quiz list:", error);
      }
    };
    fetchQuizSubCategory();
  }, [selectedQuizCategory]);

  useEffect(() => {
    fetchQuizListBySubCatId();
  }, [selectedQuizSubCategory]);

  const fetchQuizListBySubCatId = async () => {
    try {
      const token = getToken();

      // fetch table
      const response1 = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/quiz/allquizbysubcatId/${selectedQuizSubCategory._id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data1 = await response1.json();
      if (data1?.data) {
        const quizzesWithSerial = data1.data.map((quiz, index) => ({
          ...quiz,
          serial: page * rowsPerPage + index + 1,
        }));
        setQuizes(quizzesWithSerial);
        setTotalItems(data1.pagination.totalItems);
      } else {
        setError("No quizzes found");
      }
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  

  useEffect(() => {
    fetchQuizzes();
  }, [page, rowsPerPage, searchTerm, forFilter]);

  const fetchQuizzes = async () => {
    try {
      const token = getToken();
      if (!token) return;
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/quiz/quizlist?page=${page}&limit=${rowsPerPage}&search=${searchTerm}&course=${forFilter.course}&grade=${forFilter.grade}&studentId=${forFilter.studentId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (data?.data) {
        const quizzesWithSerial = data.data.map((quiz, index) => ({
          ...quiz,
          serial: page * rowsPerPage + index + 1,
        }));
        setQuizes(quizzesWithSerial);
        setTotalItems(data.pagination.totalItems);
      } else {
        setError("No quizzes found");
      }
    } catch (err) {
      setError("Failed to fetch quizzes");
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = (field) => (event) => {
    setForFilter((prev) => ({
      ...prev,
      [field]: event.target.value,
    }));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page on rows per page change
  };

  const handleSnackbarClose = () => setOpenSnackbar(false);

  const handleAddQuiz = () => {
    navigate("/teacher/add-quiz");
  };

  
  const deleteQuiz = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/quiz/delete/${deleteId}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (response.status === 200) {
        setSnackbarMessage("Quiz deleted successfully");
        setOpenSnackbar(true);
        fetchQuizzes();
        handleCloseDeleteDialog();
      }
    } catch (err) {
      setError("Failed to delete teacher");
    }
  };

  const handleOpenDeleteDialog = (id) => {
    setDeleteId(id);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteId(null);
    setOpenDeleteDialog(false);
  };

  return (
    <Container maxWidth="100%">
      <Box
        p={1}
        mb={2}
        sx={{
          backgroundColor: "#e0e0e0cf",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4">Quiz List</Typography>
        <TextField
          // label="Search"
          variant="outlined"
          size="small"
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
          sx={{
            width: "300px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#00000030",
              },
              "&:hover fieldset": {
                borderColor: "#00000070",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#00000040",
              },
            },
            "& .MuiInputLabel-root": {
              display: "flex",
              alignItems: "center",
              transition: "all 0.3s ease",
            },
            "& .MuiInputLabel-shrink": {
              display: "flex",
              alignItems: "center",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Grid container spacing={2} mb={2} alignItems="flex-start">
        <Grid item xs={12} sm={4}>
          <Autocomplete
            size="small"
            options={quizCategory}
            getOptionLabel={(option) => option.quiz_category_name || "N/A"}
            value={selectedQuizCategory || null}
            onChange={(e, newValue) => {
              if (newValue) {
              setSelectedQuizCategory(newValue);
              setSelectedQuizSubCategory(""); // Reset the subcategory when category is changed
            } else {
              setSelectedQuizCategory(null); // Clear selected category if no category is selected
              setSelectedQuizSubCategory(""); // Reset subcategory
              fetchQuizzes(); // Fetch quizzes if category is cleared
            }}}
            renderInput={(params) => (
              <TextField {...params} label="Select quiz category" />
            )}
            sx={{ width: "100%" }}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Autocomplete
            size="small"
            options={quizSubCategory}
            getOptionLabel={(option) => option.quiz_sub_category_name || "N/A"}
            value={selectedQuizSubCategory || null}
            onChange={(e, newValue) => setSelectedQuizSubCategory(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Select quiz sub category" />
            )}
            sx={{ width: "100%" }}
          />
        </Grid>

        {/* <Grid item xs={12} sm={4}>
          <Grid item xs={12}>
            <Autocomplete
            size="small"
              options={quizSubCategory}
              getOptionLabel={(option) =>
                option.quiz_sub_category_name || "N/A"
              }
              value={selectedQuizSubCategory || null}
              onChange={(e, newValue) => setSelectedQuizSubCategory(newValue)}
              renderInput={(params) => (
                <TextField {...params} label="Select quiz sub category" />
              )}
              sx={{ width: "100%" }}
            />
          </Grid>
        </Grid> */}

        {/* Button Grid */}
        <Grid
          item
          xs={12}
          sm={4}
          display="flex"
          justifyContent="flex-end"
          sx={{ order: 2, marginLeft: "auto" }}
        >
        {canCreate === 1 && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddQuiz}
            sx={{ backgroundColor: "#e0e0e0" }}
          >
            create quiz
          </Button>
        )}
        </Grid>
      </Grid>

      

      <TableContainer component={Paper}>
  {canView === 1 ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>NO.</TableCell>
              <TableCell>Quiz Title</TableCell>
              <TableCell>Quiz Category</TableCell>
              <TableCell>Quiz Subcategory</TableCell>
              <TableCell>Total Score</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {quizes.length > 0 ? (
              quizes.map((quiz) => (
                <TableRow key={quiz?._id}>
                  <TableCell>{quiz?.serial}</TableCell>
                  <TableCell>{quiz?.quiz_title || "Unknown Title"}</TableCell>
                  <TableCell>
                    {quiz?.quizcategory?.quiz_category_name || "Unknown Title"}
                  </TableCell>
                  <TableCell>
                    {quiz?.quizsubcategory?.quiz_sub_category_name ||
                      "Unknown Course"}
                  </TableCell>
                  <TableCell>{quiz?.quiz_score || "N/A"}</TableCell>
                  <TableCell>
                    {new Date(quiz.createdAt).toLocaleDateString()}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={() =>
                        navigate(`/teacher/quizQuestion-list/${quiz._id}`)
                      }
                    >
                      <VisibilityIcon />
                    </IconButton>
                  {canUpdate === 1 && (
                    <IconButton
                      color="primary"
                      onClick={() => navigate(`/teacher/edit-quiz/${quiz._id}`)}
                    >
                      <EditIcon />
                    </IconButton>
                  )}
                  {canDelete === 1 && (
                    <IconButton
                      color="secondary"
                      onClick={() => handleOpenDeleteDialog(quiz._id)}>
                      <DeleteIcon />
                    </IconButton>
                  )}
                  </TableCell>
                  
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No quizzes available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
         ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ height: "200px", textAlign: "center" }}
          >
            {isloading ? (
              <CircularProgress />
              ) : (
            <Typography variant="h6" color="textSecondary">
              No permission to view.
            </Typography>
            )}
          </Box>
        )}
      </TableContainer>

      {/* <TablePagination
        component="div"
        count={totalItems}
        page={page}
        onPageChange={handlePageChange}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleRowsPerPageChange}
        rowsPerPageOptions={[10, 25, 50, 100]}
      /> */}
      {totalItems > 10 && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding={2}>
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 25, 50, 100]}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Box>
      )}

      

       {/* Delete Confirmation Dialog */}
       <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this quiz?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={deleteQuiz}
            color="primary"
            sx={{ backgroundColor: "#e0e0e0" }}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default QuizList;
