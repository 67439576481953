import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Button,
  Snackbar,
  Alert,
  TextField,
  Box,
  TablePagination,
  Switch,
  Grid,
  Link,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  Container,
  Typography,
  InputAdornment,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SearchIcon from "@mui/icons-material/Search";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { width } from "@mui/system";
import { getToken } from "../../../utils/AuthUtils";
import axios from "axios";

const AssessmentReport = () => {
  const [assessments, setAssessments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const [serialNumbers, setSerialNumbers] = useState([]);
  const [totalItems, setTotalItems] = useState([]);
  const [courses, setCourses] = useState([]);
  const [grades, setGrades] = useState([]);
  const [students, setStudents] = useState([]);
  const [forFilter, setForFilter] = useState({
    course: "",
    grade: "",
    gradeDivision: "",
    studentId: "",
  });

  // New state variable for status toggle
  const [statusToggle, setStatusToggle] = useState(false);

  useEffect(() => {
    fetchUnitAssessment();
    // fetchUnitAssessmentbyStudent();
  }, [page, rowsPerPage, searchTerm]);

  useEffect(() => {
    // Avoid calling the fetch if the studentId is not present
    if (forFilter.studentId) {
      fetchUnitAssessmentbyStudent();
    }
    // Add only the relevant dependencies (in this case, studentId)
  }, [forFilter.studentId]);

  const fetchUnitAssessment = async () => {
    try {
      const token = getToken();
      if (!token) {
        console.log("Unauthorized user");
        return;
      }
      const response = await fetch(
        `${
          process.env.REACT_APP_BACKEND
        }/api/assessment/assessmentreport?page=${
          page + 1
        }&limit=${rowsPerPage}&search=${searchTerm}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (data?.data) {
        const assessmentWithSerial = data.data.map((assessment, index) => ({
          ...assessment,
          serial: page * rowsPerPage + index + 1,
          statusToggle: !assessment.status, // Add this line
        }));
        setAssessments(assessmentWithSerial);
        setSerialNumbers(assessmentWithSerial.map((t) => t.serial));
        setTotalItems(data.pagination.totalItems); // Set totalItems for pagination
      } else {
        setError("No teachers found");
      }
    } catch (err) {
      setError("Failed to fetch teachers");
    } finally {
      setLoading(false);
    }
  };

  // const fetchUnitAssessmentbyStudent = async () => {
  //   try {
  //     const token = getToken();
  //     if(!token){
  //       console.log("Unauthorized user");
  //       return;
  //     }
  //     const response = await fetch(
  //       `${process.env.REACT_APP_BACKEND}/api/assessment/assessmentreport/${forFilter.studentId}`,
  //       {
  //         method: "GET",
  //         headers:{
  //           "Content-Type": "application/json",
  //           "Authorization": `Bearer ${token}`,
  //         }
  //       }
  //     );
  //     const data = await response.json();
  //     if (data?.data) {
  //       const assessmentWithSerial = data.data.map((assessment, index) => ({
  //         ...assessment,
  //         serial: page * rowsPerPage + index + 1,
  //         statusToggle: !assessment.status, // Add this line
  //       }));
  //       setAssessments(assessmentWithSerial);
  //       setSerialNumbers(assessmentWithSerial.map((t) => t.serial));
  //       setTotalItems(data.pagination.totalItems); // Set totalItems for pagination
  //       setLoading(false)
  //     } else {
  //       setAssessments([])
  //       setError("No teachers found");
  //       setLoading(false)
  //     }
  //   } catch (err) {
  //     setError("Failed to fetch teachers");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const fetchUnitAssessmentbyStudent = async () => {
    // No need to call if studentId is empty
    if (!forFilter.studentId) return;

    try {
      const token = getToken();
      if (!token) {
        console.log("Unauthorized user");
        return;
      }

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/assessment/assessmentreport/${forFilter.course.course_id._id}/${forFilter.gradeDivision}/${forFilter.studentId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();

      if (data?.data) {
        const assessmentWithSerial = data.data.map((assessment, index) => ({
          ...assessment,
          serial: page * rowsPerPage + index + 1,
          statusToggle: !assessment.status,
        }));

        setAssessments(assessmentWithSerial);
        setSerialNumbers(assessmentWithSerial.map((t) => t.serial));
        setTotalItems(data.pagination.totalItems);
        setLoading(false); // Explicitly stop loading
      } else {
        setAssessments([]);
        setError("No assessments found for this student.");
        setLoading(false); // Stop loading after receiving the response
      }
    } catch (err) {
      setError("Failed to fetch assessments for the selected student.");
      setLoading(false); // Stop loading in case of an error
    }
  };

  useEffect(() => {
    // if(!forFilter.course){
    //   return;
    // }
    const fetchcourse = async () => {
      try {
        const token = getToken();
        if (!token) {
          console.log("Access Denied");
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/assessment/course`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const coursesData = response.data.data;
        setCourses(coursesData);
        console.log("coursesss: ", coursesData);

        // setAssessments(coursesData);

        console.log("coursesData: ", response.data.data);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    fetchcourse();
  }, []);

  useEffect(() => {
    if (!forFilter.course) {
      return;
    }
    const fetchgrade = async () => {
      try {
        const token = getToken();
        if (!token) {
          console.log("Access Denied");
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/assessment/grade/${forFilter.course.course_id._id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const gardesData = response.data.data;
        setGrades(gardesData);
        console.log("gardesData: ", response.data.data);
      } catch (error) {
        console.error("Error fetching gardesData:", error);
      }
    };

    // fot table data
    const fetchUnitAssessmentbycourse = async () => {
      try {
        const token = getToken();
        if (!token) {
          console.log("Unauthorized user");
          return;
        }
        const response = await fetch(
          `${
            process.env.REACT_APP_BACKEND
          }/api/assessment/assessmentreport/${forFilter.course.course_id._id}?page=${
            page + 1
          }&limit=${rowsPerPage}&search=${searchTerm}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        if (data?.data) {
          const assessmentWithSerial = data.data.map((assessment, index) => ({
            ...assessment,
            serial: page * rowsPerPage + index + 1,
            statusToggle: !assessment.status, // Add this line
          }));
          setAssessments(assessmentWithSerial);
          setSerialNumbers(assessmentWithSerial.map((t) => t.serial));
          setTotalItems(data.pagination.totalItems); // Set totalItems for pagination
        } else {
          setError("No teachers found");
        }
      } catch (err) {
        setError("Failed to fetch teachers");
      } finally {
        setLoading(false);
      }
    };

    fetchUnitAssessmentbycourse();
    fetchgrade();
  }, [forFilter.course]);

  // fetch students by gradeDivision
  useEffect(() => {
    const fetchstudentBygradedivision = async () => {
      if (forFilter.grade === "") {
        // If "Select All" is chosen, fetch all students
        fetchUnitAssessment();
        return;
      }
      if (!forFilter.gradeDivision) return;
      try {
        const token = getToken();
        if (!token) {
          console.log("Access Denied");
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/assessment/students/${forFilter.gradeDivision}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const students = response.data.data;
        setStudents(students);
        console.log("students: ", response.data.data);

        // const filteredTeachers = resourcePersonData.filter(
        //   (item) => item.teacher_subCategoryId === newProduct.teacherSubCategory
        // );
        // setTeacherFilter(filteredTeachers);
      } catch (error) {
        console.error("Error fetching students:", error);
      }
    };
    
    // fot table data
    const fetchUnitAssessmentbycoursegrade = async () => {
      try {
        const token = getToken();
        if (!token) {
          console.log("Unauthorized user");
          return;
        }
        const response = await fetch(
          `${
            process.env.REACT_APP_BACKEND
          }/api/assessment/assessmentreport/${forFilter.course.course_id._id}/${forFilter.gradeDivision}?page=${
            page + 1
          }&limit=${rowsPerPage}&search=${searchTerm}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        if (data?.data) {
          const assessmentWithSerial = data.data.map((assessment, index) => ({
            ...assessment,
            serial: page * rowsPerPage + index + 1,
            statusToggle: !assessment.status, // Add this line
          }));
          setAssessments(assessmentWithSerial);
          setSerialNumbers(assessmentWithSerial.map((t) => t.serial));
          setTotalItems(data.pagination.totalItems); // Set totalItems for pagination
        } else {
          setError("No teachers found");
        }
      } catch (err) {
        setError("Failed to fetch teachers");
      } finally {
        setLoading(false);
      }
    };

    fetchUnitAssessmentbycoursegrade()
    fetchstudentBygradedivision();


  }, [forFilter.gradeDivision]);

  const deleteTeacher = async (id) => {
    try {
      await fetch(`${process.env.REACT_APP_BACKEND}/api/teacher/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      setSnackbarMessage("Teacher deleted successfully");
      setOpenSnackbar(true);
      fetchUnitAssessment();
    } catch (err) {
      setError("Failed to delete teacher");
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleSearch = (event) => {
    setForFilter({ studentId: "" });
    setSearchTerm(event.target.value);
    setPage(0); // Reset to first page on search
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page on rows per page change
  };

  const handleStatusToggle = (id, currentStatus) => {
    const newStatus = !currentStatus;
    updateTeacherStatus(id, newStatus); // Update parent status in the backend
  };
  const updateTeacherStatus = async (id, status) => {
    try {
      await fetch(
        `${process.env.REACT_APP_BACKEND}/api/teacher/${id}/status_teacher`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify({ status }),
        }
      );
      setSnackbarMessage("Teacher status updated successfully");
      setOpenSnackbar(true);
      fetchUnitAssessment(); // Refresh the table after status change
    } catch (err) {
      setError("Failed to update Teacher status");
    }
  };

  const moveToAddTeacher = () => {
    navigate("/add-teacher");
  };

  return (
    <Container maxWidth="100%">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ backgroundColor: "#e0e0e0" }}
        p={1}
        mt={2}
      >
        <Typography variant="h4">Unit Assessment Report</Typography>
        <TextField
          // label="Search"
          variant="outlined"
          size="small"
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
          sx={{
            width: "300px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#00000030",
              },
              "&:hover fieldset": {
                borderColor: "#00000070",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#00000040",
              },
            },
            "& .MuiInputLabel-root": {
              display: "flex",
              alignItems: "center",
              transition: "all 0.3s ease",
            },
            "& .MuiInputLabel-shrink": {
              display: "flex",
              alignItems: "center",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      {/* <Button variant="contained" color="primary" onClick={() => navigate('/teacher/add-teacher')}>
        Add Teacher
      </Button> */}
      <Grid container spacing={1}>
        <Grid item xs={12} md={3}>
          <FormControl margin="normal" fullWidth>
            <Autocomplete
              // Filter out duplicates based on courses_name
              size="small"
              options={courses.filter(
                (value, index, self) =>
                  index ===
                  self.findIndex(
                    (t) =>
                      t.course_id &&
                      t.course_id?.courses_name === value.course_id?.courses_name
                  )
              )}
              getOptionLabel={(option) =>
                option.course_id ? option.course_id?.courses_name : "N/A"
              }
              value={
                courses.find((item) => item?.course_id?._id === forFilter?.course?.course_id?._id) || null
              }
              onChange={(event, newValue) => {
                // setStudents([]); // Clear students list
                if (newValue) {
                  setForFilter({
                    course: newValue, // Set the selected course ID
                  });
                  
                } else {
                  setForFilter({
                    course: null, // Clear course if nothing is selected
                  });
                  fetchUnitAssessment();
                }
              }}
              renderInput={(params) => (
                <TextField {...params} label="Course" variant="outlined" />
              )}
              // isOptionEqualToValue={(option, value) => option?._id === value?._id}
              // disableClearable={false} // Allow clearing the selected value (optional)
              // disablePortal // Optional, can help with styling issues
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          {forFilter.course &&
          <FormControl
            margin="normal" fullWidth
          >
            <Autocomplete
              options={grades.filter(
                (value, index, self) =>
                  index ===
                  self.findIndex((t) => `${t.grade_id?.gradename || ""} ${
                      t.grade_division_id || ""
                    }`.trim() === `${value.grade_id?.gradename || ""} ${
                      value.grade_division_id || ""
                    }`.trim())
              )}
              getOptionLabel={(option) =>
                `${option?.grade_id?.gradename || "Unknown Grade"} ${
                  option?.grade_division_id || "Unknown Division"
                }`
              }
              value={grades.find((item) => item._id === forFilter?.grade) || null}
              onChange={(event, selectedGrade1) => {
                if (selectedGrade1 && selectedGrade1.grade_id) {
                  const gradeDivision = `${
                    selectedGrade1?.grade_id?.gradename || ""
                  } ${selectedGrade1?.grade_division_id || ""}`;
                  setForFilter({
                    ...forFilter,
                    grade: selectedGrade1._id,
                    gradeDivision,
                  });
                } else {
                  setForFilter({
                    ...forFilter,
                    grade: "",
                    gradeDivision: "",
                  });
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Grade"
                  size="small"
                  aria-required="true"
                  // placeholder="Select a Grade"
                />
              )}
              isOptionEqualToValue={(option, value) => option._id === value._id}
            />
          </FormControl>}
        </Grid>
        <Grid item xs={12} md={3}>
          {forFilter.gradeDivision&&
          <FormControl
            margin="normal"
            fullWidth
          >
            <TextField
              select
              value={forFilter.studentId}
              size="small"
              onChange={(e) =>
                setForFilter(() => ({
                  ...forFilter,
                  studentId: e.target.value,
                }))
              }
              label="Student"
              aria-required="true"
            >
              {students.map((student) => (
                <MenuItem key={student?._id} value={student?._id}>
                  {student?.name}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>}
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>No.</TableCell>
              <TableCell>Student Name</TableCell>
              <TableCell>Grade</TableCell>
              <TableCell>Chapter</TableCell>
              <TableCell>Unit</TableCell>
              <TableCell>Assessment Title</TableCell>
              <TableCell>Other Score</TableCell>
              <TableCell>Descriptive Score</TableCell>
              <TableCell>Total Score</TableCell>
              <TableCell>Class Average</TableCell>
              {/* <TableCell>
                Participation
              </TableCell> */}
              {/* <TableCell>
                Comment
              </TableCell> */}
              <TableCell>Date</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {assessments.length > 0 ? (
              assessments.map((assessment) => {
                // Ensure assessment.answers is an array before proceeding
                const descriptiveScore =
                  assessment?.answers && Array.isArray(assessment?.answers)
                    ? assessment?.answers
                        .filter(
                          (answer) =>
                            answer?.question_id &&
                            answer?.question_id?.question_type === "descriptive"
                        )
                        .reduce((acc, answer) => acc + (answer?.points || 0), 0) // Ensure points exist
                    : 0;

                const notdescriptiveScore =
                  assessment?.answers && Array.isArray(assessment?.answers)
                    ? assessment?.answers
                        .filter(
                          (answer) =>
                            answer?.question_id &&
                            answer?.question_id?.question_type != "descriptive"
                        )
                        .reduce((acc, answer) => acc + (answer?.points || 0), 0) // Ensure points exist
                    : 0;

                return (
                  <TableRow key={assessment?._id}>
                    <TableCell>{assessment?.serial}</TableCell>
                    <TableCell>{assessment?.student_id?.name}</TableCell>
                    <TableCell>
                      {assessment?.student_id?.grade_division}
                    </TableCell>
                    <TableCell>
                      {
                        assessment?.assessment_id?.unit?.chapter_id
                          ?.chapter_name
                      }
                    </TableCell>
                    <TableCell>
                      {assessment?.assessment_id?.unit?.unit_name}
                    </TableCell>
                    <TableCell>
                      {assessment?.assessment_id?.assessment_title}
                    </TableCell>
                    <TableCell>{notdescriptiveScore}</TableCell>
                    <TableCell>{descriptiveScore}</TableCell>{" "}
                    {/* Display the descriptive score */}
                    <TableCell>{assessment?.score}</TableCell>
                    <TableCell>
                      {assessment?.score}/{assessment?.averageScore}
                    </TableCell>
                    {/* <TableCell>{assessment.teacher_id}</TableCell> */}
                    {/* <TableCell>{assessment.teacher_id}</TableCell> */}
                    <TableCell>
                      {new Date(assessment?.createdAt).toLocaleDateString(
                        "en-CA"
                      )}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        color="secondary"
                        onClick={() =>
                          navigate(
                            `/teacher/assessment-view1/${assessment.assessment_id.unit._id}/${assessment?._id}`
                          )
                        }
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  No Assessments available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {totalItems > 10 && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding={2}
        >
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 25, 50, 100]}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Box>
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}
        >
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AssessmentReport;
