

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { useParams } from "react-router-dom";
// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   Typography,
// } from "@mui/material";

// const QuestionsList = () => {
//   const { assessmentId } = useParams(); // Get the assessmentId from the URL
//   const [questions, setQuestions] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     fetchQuestions();
//   }, []);

//   const fetchQuestions = async () => {
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_BACKEND}/api/questions?assessment_id=${assessmentId}`
//       );
//       setQuestions(response.data.data);
//     } catch (error) {
//       setError("Failed to fetch questions");
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <div>
//       <Typography variant="h4" gutterBottom>
//         Questions 
//       </Typography>
//       <TableContainer component={Paper}>
//         <Table>
//           <TableHead>
//             <TableRow>
//               <TableCell>Question No</TableCell>
//               <TableCell>Question</TableCell>
//               <TableCell>Option 1</TableCell>
//               <TableCell>Option 2</TableCell>
//               <TableCell>Option 3</TableCell>
//               <TableCell>Option 4</TableCell>
//               <TableCell>Answer</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {questions.length > 0 ? (
//               questions.map((question, index) => (
//                 <TableRow key={question._id}>
//                   <TableCell>{index + 1}</TableCell>
//                   <TableCell>{question.question_text}</TableCell>
//                   <TableCell>
//                     {question.option_text_1
//                       ? question.option_text_1
//                       : "This question has no options"}
//                   </TableCell>
//                   <TableCell>
//                     {question.option_text_2
//                       ? question.option_text_2
//                       : "This question has no options"}
//                   </TableCell>
//                   <TableCell>
//                     {question.option_text_3
//                       ? question.option_text_3
//                       : "This question has no options"}
//                   </TableCell>
//                   <TableCell>
//                     {question.option_text_4
//                       ? question.option_text_4
//                       : "This question has no options"}
//                   </TableCell>
//                   <TableCell>{question.answer}</TableCell>
//                 </TableRow>
//               ))
//             ) : (
//               <TableRow>
//                 <TableCell colSpan={3}>No questions available</TableCell>
//               </TableRow>
//             )}
//           </TableBody>
//         </Table>
//       </TableContainer>
//     </div>
//   );
// };

// export default QuestionsList;


import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate,Link} from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
} from "@mui/material";
import { getToken } from "../../../utils/AuthUtils";

const QuestionsList = () => {
  const { assessmentId } = useParams(); // Get the assessmentId from the URL
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // For navigation to the update page

  useEffect(() => {
    fetchQuestions();
  }, []);

  const fetchQuestions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/questions?assessment_id=${assessmentId}`,
        {
          headers:{
            Authorization: `Bearer ${getToken()}`
          }
        }
      );
      setQuestions(response.data.data);
    } catch (error) {
      setError("Failed to fetch questions");
    } finally {
      setLoading(false);
    }
  };

  // Delete question by ID
  const handleDelete = async (questionId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND}/api/questions/${questionId}`,
        {
          headers:{
            Authorization: `Bearer ${getToken()}`
          }
        }
      );
      setQuestions(questions.filter((q) => q._id !== questionId)); // Remove the deleted question from the list
    } catch (error) {
      setError("Failed to delete question");
    }
  };

  const handleUpdate = (id) => {
    // Navigate to the update page with the question ID
    navigate(`/update-question/${id}`);
  };
    return (
    <div>
      <Typography variant="h4">
        Questions 
      </Typography>
      <Button
        component={Link}
        to={`/questions/add/${assessmentId}`}
        variant="contained"
        color="primary"
        style={{ marginBottom: "20px" }}
      >
        Add New Question
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Question No</TableCell>
              <TableCell>Question</TableCell>
              <TableCell>Option 1</TableCell>
              <TableCell>Option 2</TableCell>
              <TableCell>Option 3</TableCell>
              <TableCell>Option 4</TableCell>
              <TableCell>Answer</TableCell>
              <TableCell>Actions</TableCell> {/* New column for buttons */}
            </TableRow>
          </TableHead>
          <TableBody>
            {questions.length > 0 ? (
              questions.map((question, index) => (
                <TableRow key={question._id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{question.question_text}</TableCell>
                  <TableCell>
                    {question.option_text_1
                      ? question.option_text_1
                      : "This question has no options"}
                  </TableCell>
                  <TableCell>
                    {question.option_text_2
                      ? question.option_text_2
                      : "This question has no options"}
                  </TableCell>
                  <TableCell>
                    {question.option_text_3
                      ? question.option_text_3
                      : "This question has no options"}
                  </TableCell>
                  <TableCell>
                    {question.option_text_4
                      ? question.option_text_4
                      : "This question has no options"}
                  </TableCell>
                  <TableCell>{question.answer}</TableCell>
                  <TableCell>
                    {/* Update button */}
                    {/* <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleUpdate(question._id)}
                    > */}

                      {/* Update
                    </Button> */}
 
        <button key={question._id} onClick={() => handleUpdate(question._id)}>
          Update
        </button>
      
                    {/* Delete button */}
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleDelete(question._id)}
                      style={{ marginLeft: "10px" }}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={8}>No questions available</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default QuestionsList;
