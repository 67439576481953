import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  TextField,
  Button,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Box,
  Container,
  IconButton,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../../utils/AuthUtils";
import axios from "axios";

const eventsData = [
  {
    date: "2024-11-14",
    time: "19:00",
    title: "Visita Teatralizada: Una tarde con Roberto Arlt en Flores",
    location: "Roxy Live, Downtown District",
    description:
      "Guns'n'Roses, The Rolling Stones, Radiohead, and more. This is a very long description to demonstrate truncation.",
    image: "https://via.placeholder.com/100x60",
  },
  {
    date: "2024-11-14",
    time: "20:00",
    title: "Another Event Title",
    location: "Downtown Hall",
    description:
      "Some description about the event. A wonderful evening awaits.",
    image: "https://via.placeholder.com/100x60",
  },
  {
    date: "2024-11-15",
    time: "18:00",
    title: "A Music Night",
    location: "City Hall",
    description: "Enjoy a great evening of music with famous bands.",
    image: "https://via.placeholder.com/100x60",
  },
];

const formatDate = (dateString) => {
  const date = new Date(dateString);

  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" }); // Get the first 3 letters of the month
  const year = date.getFullYear();

  // Function to add ordinal suffix to a number
  const getOrdinal = (num) => {
    if (num > 3 && num < 21) return "th"; // Handles 11th to 20th
    switch (num % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  return `${day}${getOrdinal(day)} ${month}  `;
};

const formatTime = (dateString) => {
  const date = new Date(dateString);
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const period = hours >= 12 ? "PM" : "AM";
  const adjustedHours = hours % 12 || 12;

  return `${adjustedHours}:${minutes} ${period}`;
};

const NewsListing = () => {
  const [selectedDate, setSelectedDate] = useState(null); // Initial state for no date filter
  const [filteredEvents, setFilteredEvents] = useState(eventsData);
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const token = getToken();
        if (token) {
          const eventResponse = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/companynews`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          // Assuming teacherResponse.data.data contains the count of teachers
          console.log("Events-------", eventResponse.data.data); // Check the structure

          setNews(eventResponse.data.data);
          setLoading(false); // Stop loading when data is fetched
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false); // Stop loading on error
      }
    };

    fetchEvents();
  }, []);

  // Handle date change for filtering
  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (date) {
      const filtered = eventsData.filter(
        (event) => event.date === date.toISOString().split("T")[0]
      );
      setFilteredEvents(filtered);
    } else {
      setFilteredEvents(eventsData);
    }
  };

  // Reset filter to show all events
  const handleReset = () => {
    setSelectedDate(null);
    setFilteredEvents(eventsData);
  };

  return (
    <Container maxWidth="100%" sx={{ padding: 2 }}>
      {/* Date Filter Section */}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            gap: 2,
            mb: 4,
            justifyContent: "space-between",
          }}
        >
          <DatePicker
            label="Filter by Date"
            value={selectedDate}
            onChange={handleDateChange}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                sx={{ maxWidth: { xs: "100%", sm: "300px" } }}
              />
            )}
          />
          <Button variant="contained" color="primary" onClick={handleReset}>
            Clear
          </Button>
        </Box>
      </LocalizationProvider>

      {/* Events List */}
      <Grid container spacing={3}>
        {news.map((item, index) => (
          <Grid item xs={12} key={index}>
            <Card
              sx={{
                display: "flex",
                alignItems: "center",
                padding: 2,
                backgroundColor: "#e6e6e694",
                boxShadow: "none",
              }}
            >
              {/* Date */}
              <Grid item xs={12} md={1}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    minWidth: "70px",
                    marginRight: 2,
                  }}
                >
                  <Typography variant="h6" color="primary">
                    {/* {new Date(item.date).getDate()} */}
                    {formatDate(item.publish_date)}
                  </Typography>
                  {/* <Typography variant="subtitle2">
                    {new Date(item.date).toLocaleString("default", {
                      month: "short",
                    })}
                  </Typography> */}
                </Box>
              </Grid>

              {/* Thumbnail */}
              <Grid item xs={12} md={1}>
                <CardMedia
                  component="img"
                  sx={{ width: 100, height: 80, borderRadius: 1 }}
                  image={item.company_news_image}
                  alt={item.company_news_title}
                />
              </Grid>

              {/* Event Details */}
             <Grid item xs={12} md={10}>
                <CardContent sx={{ flex: 1, paddingLeft: 2 }}>
                  <Grid>
                    <Typography variant="h6" noWrap>
                      {item.company_news_title}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={10}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                    >
                      {item.company_news_description}
                    </Typography>
                  </Grid>
  
                  {/* Location and Time */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      mt: 1,
                      gap: 5,
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <AccessTimeIcon fontSize="small" color="action" />
                      <Typography variant="body2" color="text.secondary">
                        {formatTime(item.publish_date)}
                      </Typography>
                    </Box>
                    {/* <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <LocationOnIcon fontSize="small" color="action" />
                      <Typography variant="body2" color="text.secondary">
                        {item.location}
                      </Typography>
                    </Box> */}
                  </Box>
                </CardContent>
             </Grid>

              {/* View Button (Arrow Icon) */}
              <CardActions>
                {/* <IconButton
                  color="primary"
                  onClick={() => navigate("/student/event-detail")}
                >
                  <ArrowForwardIosIcon />
                </IconButton> */}
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default NewsListing;
