import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  RadioGroup,
  Radio,
  TextField,
  Container,
} from "@mui/material";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { getToken } from "../../../utils/AuthUtils";

const AssignDepartment = () => {
  const navigate = useNavigate();
  const { departmentId } = useParams(); // To get the departmentId from the URL
  const [form, setForm] = useState({
    department_id: "",
    teacher_id: "",
    labname_id: "",
    lab_location: "",
  });
  const [error, setError] = useState("");
  const [teachers, setTeachers] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [labnames, setLabNames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [alreadyAssignedTeachers, setAlrteadyAssignedTeachers] = useState([]);
  const [departmentName, setDepartmentName] = useState(""); // New state to store department name

  // Fetch department details on page load for editing
  useEffect(() => {
    if (departmentId) {
      fetchAssignDepartment(departmentId);
    }
    fetchDepartments(); // Always load the departments
  }, [departmentId]);

  // Fetch the details of the assigned department for editing
  const fetchAssignDepartment = async (departmentId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/assign_department/${departmentId}`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      const data = await response.json();
      console.log("assign department data:", data);
      
      if (data?.data) {
        setForm({
          department_id: data?.data?.department_id?._id || '',
          teacher_id: data?.data?.teacher_id.name || '',
          labname_id: data?.data.labname_id?._id || '',
          lab_location: data?.data?.lab_location || '',
        });
        setDepartmentName(data?.data.department_id.department_name); // Set the department name
      } else {
        setError('Department assignment not found.');
      }
    } catch (err) {
      console.log('error: ', err);
      
      // setError('Failed to fetch department assignment details.');
    } finally {
      setLoading(false);
    }
  };

  // Fetch available departments
  const fetchDepartments = async () => {
    setLoading(true);
    try {
      const token = getToken();
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/department/getassigneddepartmentstosc`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("getassign department to school: ", response.data.data);
      
      setDepartments(response.data.data || []);
    } catch (error) {
      setError('Failed to fetch departments.');
    } finally {
      setLoading(false);
    }
  };

  // Fetch teachers by department
  useEffect(() => {
    const fetchTeachersByDept = async () => {
      if (!form.department_id) return;
      try {
        const token = getToken();
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND}/api/assign_department/teachersbydepartment/${form.department_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        if (response.ok) {
          setTeachers(data.data || []);
          console.log("teachers related department: ", data.data);
          // setNewDepartment.department_id = "";
        }

        const response1 = await fetch(
          `${process.env.REACT_APP_BACKEND}/api/assign_department/alreadyAssignedTeachersbydepartment/${form.department_id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data1 = await response1.json();
        if (response.ok) {
          setAlrteadyAssignedTeachers(data1.data || []);
          console.log("teachers related department: ", data1.data);
          // setNewDepartment.department_id = "";
        }
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    fetchTeachersByDept();
  }, [form.department_id]);

  // Fetch lab names by department
  useEffect(() => {
    const fetchLabnamesByDept = async () => {
      if (!form.department_id) return;
      try {
        const token = getToken();
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND}/api/assign_department/labnames/${form.department_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        setLabNames(data.data || []);
      } catch (error) {
        console.error("Error fetching lab names:", error);
      }
    };
    fetchLabnamesByDept();
  }, [form.department_id]);

  // Handle form submission
  const handleSubmit = async () => {
    const token = getToken();
    if (!token) {
      console.log("Unauthorized User");
      return;
    }

    if (!form.department_id || !form.teacher_id) {
      setError("All fields are required.");
      return;
    }

    try {
      console.log("dataaaaa",form);
      
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND}/api/assign_department/edit/${departmentId}`,
        form,
        {
          headers: {
            // "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${token}`,
          },
        }
      );
  
      if (response.status === 200) {
        setForm({
          department_id: '',
          teacher_id: '',
          labname_id: '',
          lab_location: '',
        });
        navigate("/school/assign-department/list");
      }
    } catch (err) {
      console.error("Error submitting form", err);
      // setError("Failed to submit form.");
    }
  };

  const handleCancel = () => {
    navigate("/school/assign-department/list"); // Navigate to list page on cancel
  };



  return (
    <Container maxWidth="100%">
      <Box
        padding={1}
        display="flex"
        justifyContent="space-between"
        backgroundColor="#e0e0e0"
      >
        <Typography variant="h4">Edit Assigned Department</Typography>
      </Box>
      <Box>
        <Grid container spacing={2} sx={{ marginTop: "20px", width: "80%" }}>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="#000">
              Select Department
            </Typography>

            <FormControl fullWidth margin="normal" variant="outlined" required>
              <InputLabel>Departments</InputLabel>
              <Select
                value={form.department_id}
                // onChange={(e) =>
                //   setNewDepartment({ ...newDepartment, department_id: e.target.value })
                // }
 
                onChange={(e) => {
                  setForm({
                    ...form,
                    department_id: e.target.value,
                  });
                  setTeachers([]); // Uncomment if needed
                }}
                label="Department"
                aria-required="true">
                {/* {departments.map((department) => (
                  <MenuItem key={department.department_id} value={department._id}>
                    {department.department_name
                      ? department.department_name
                      : null}
                  </MenuItem>
                ))} */}
                  {departments.map((department, index) => (
                  <MenuItem key={index} value={department.department_id}>
                    {department.department_name} {/* Display the department name */}
                   </MenuItem>
                  ))}
 
              </Select>
            </FormControl>

            <Box display={"flex"} flexDirection="column">
              <FormControl component="fieldset" margin="normal" required>
                <Typography>Select Teacher</Typography>
                <RadioGroup
                  value={form.teacher_id}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      teacher_id: e.target.value,
                    })
                  }
                >
                  {teachers.map((teacher) => (
                    <FormControlLabel
                      key={teacher._id}
                      control={<Radio />}
                      value={teacher._id}
                      label={teacher.name}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              <FormControl component="fieldset" margin="normal" required>
                <Typography>Already Assigned Teacher</Typography>

                <ul>
                  {alreadyAssignedTeachers.map((teacher) => (
                    <li key={teacher._id}>
                      {teacher && teacher.teacher_id?.name
                        ? teacher.teacher_id.name
                        : "N/A"}
                    </li>
                  ))}
                </ul>
              </FormControl>
            </Box>
           

            {labnames.length > 0 && (
              <>
                <FormControl fullWidth margin="normal" variant="outlined" required>
                  <InputLabel>Lab Names</InputLabel>
                  <Select
                    value={form.labname_id}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        labname_id: e.target.value,
                      })
                    }
                    label="Lab Name"
                  >
                    {labnames.map((labname) => (
                      <MenuItem key={labname._id} value={labname._id}>
                        {labname.lab_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <TextField
                  label="Lab Location"
                  value={form.lab_location}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      lab_location: e.target.value,
                    })
                  }
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  required
                />
              </>
            )}
          </Grid>

          <Grid item xs={12} md={6}></Grid>
        </Grid>

        <Box mt={3}>
          {error && <Typography color="error">{error}</Typography>}
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            {departmentId ? "UPDATE" : "ADD"}
          </Button>
          <Button type="button" onClick={handleCancel} color="primary" sx={{mx:2}}>
                Cancel
              </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default AssignDepartment;
